import { Inject, Injectable } from '@angular/core';

import { STORAGE_KEYS } from '../../shared/constants/storage-keys.constants';
import { IDictionary } from '../../shared/interfaces/dictionary.interface';
import { WINDOW } from '../injection-tokens/window.injection-token';
import { IWindow } from '../interfaces/window.interface';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(@Inject(WINDOW) private _window: IWindow) {}

    tagRecording(tags: string[]): void {
        this._window.hj('tagRecording', tags);
    }

    startRecording(triggerName: string): void {
        this._window.hj('trigger', triggerName);
    }

    trackVirtualPageView(path: string, pageCategory: string): void {
        this.hjVirtualPageView(path);
        this.triggerHeatmap(path);
        this.gaVirtualPageView(path, pageCategory);
    }

    trackLinkClick(action: string, page: string, campaign: string): void {
        const data: IDictionary<string> = {
            event: 'visitor interaction',
            interaction_name: 'button click',
            button_action: action,
            button_page: page,
            button_campaign: campaign,
        };

        this._window.dataLayer.push(data);
    }

    hjVirtualPageView(path: string): void {
        this._window.hj('vpv', path);
    }

    triggerPoll(pollName: string): void {
        this._window.hj('trigger', pollName);
    }

    triggerHeatmap(heatmapName: string): void {
        this._window.hj('trigger', heatmapName);
    }

    normaliseString(url: string = ''): string {
        return (url || '').replace(' ', '-');
    }

    gaVirtualPageView(path: string, pageCategory: string): void {
        const data: IDictionary<string> = {
            client_id: this._getClientId(),
            user_id: this._getClientId(),
            event: 'virtual page view',
            original_location: this._getOriginalLocation(),
            page_name: `/ga-virtual/${path}`,
            page_type: pageCategory,
            page_view_method: 'virtual page',
            visitor_id: this._getVisitorId(),
        };

        this._window.dataLayer.push(data);
    }

    private _getVisitorId(): string | null {
        return localStorage.getItem(STORAGE_KEYS.VISITOR_ID);
    }

    private _getClientId(): string | null {
        return localStorage.getItem('userRef');
    }

    private _getOriginalLocation(): string {
        return [
            document.location.protocol,
            '//',
            document.location.hostname,
            document.location.pathname,
            document.location.search,
        ].join('');
    }
}
