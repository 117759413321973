/* eslint-disable:no-any */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { BlockUiModule } from '../feature-modules/block-ui/block-ui.module';
import { CmButtonDirectiveModule } from '../feature-modules/design-system/components/button/cm-button-directive.module';
import { CmChartsModule } from '../feature-modules/design-system/components/charts/cm-charts.module';
import { CmFormsModule } from '../feature-modules/design-system/components/forms/cm-forms.module';
import { CmTypographyModule } from '../feature-modules/design-system/components/typography/cm-typography.module';
import { KnowledgeBaseModule } from '../feature-modules/knowledge-base/knowledge-base.module';
import { PopUpDetails } from '../feature-modules/popups/classes/pop-up-details.class';
import { PopupService } from '../feature-modules/popups/services/popup.service';
import { CmAlertModule } from '../new-shared/components/cm-alert/cm-alert.module';
import { CardModule } from '../new-shared/components/cm-card/card.module';
import { FileInputModule } from '../new-shared/components/cm-file-input/cm-file-input.module';
import { CmModalModule } from '../new-shared/components/cm-modal/modal.module';
import { CmTableModule } from '../new-shared/components/cm-table/cm-table.module';
import { CoterminateFormControlModule } from '../new-shared/components/forms/form-controls/coterminate-form-control/coterminate-form-control.module';
import { DatetimePickerControlModule } from '../new-shared/components/forms/form-controls/datetime-picker-control/datetime-picker-control.module';
import { SelectInputModule } from '../new-shared/components/forms/select-input/cm-select-input.module';
import { CloudMarketPopupModule } from '../new-shared/components/modals/cm-popup/cm-popup.module';
import { ConfirmationModalModule } from '../new-shared/components/modals/confirmation/confirmation-modal.module';
import { EmailSecurityDisasterRecoveryModalModule } from '../new-shared/components/modals/email-security-disaster-recovery/email-security-disaster-recovery-modal.module';
import { SelectServiceModalModule } from '../new-shared/components/modals/select-service/select-service-modal.module';
import { PasswordModule } from '../new-shared/components/password/password.module';
import { WaitSpinnerModule } from '../new-shared/components/wait-spinner/wait-spinner.module';
import { TogglePasswordVisibilityModule } from '../new-shared/directives/toggle-password-visibility/toggle-password-visibility.module';
import { FilterHiddenPipeModule } from '../new-shared/pipes/filter-hidden/filter-hidden-pipe.module';
import { FilterOutDisabledServicesPipeModule } from '../new-shared/pipes/filter-out-disabled-services/filter-out-disabled-services-pipe.module';
import { DropdownActionsFilterPipeModule } from '../new-shared/pipes/pipes/dropdown-actions-filter.module';
import { SafeHtmlPipeModule } from '../new-shared/pipes/safe-html/safe-html-pipe.module';
import { EndUserOnPremiseAliasComponent } from '../pages/customer/manage-service/email-security/mailbox-setting-end-user/end-user-alias/end-user-on-premise-alias.component';
import { HostedExchangeAddressBoxComponent } from '../pages/customer/manage-service/hosted-exchange/address-book/address-box.component';
import { EndUserAliasComponent } from '../pages/customer/manage-service/hosted-exchange/mailbox-setting-end-user/end-user-alias/end-user-alias.component';

import { AzureUsageComponent } from './components/azure-usage/azure-usage.component';
import { CmButtonModule } from './components/cm-button/cm-button.module';
import { CmDynamicFormComponent } from './components/cm-dynamic-form/cm-dynamic-form.component';
import { ToolTipComponent } from './components/cm-tooltip/cm-tooltip.component';
import { CustUserArchiveLoginModule } from './components/cust-user-archive-login/cust-user-archive-login.module';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { EndUserActivityModule } from './components/end-user-activity/end-user-activity.module';
import { EndUserActivityViewEmailModule } from './components/end-user-activity-view-email/end-user-activity-view-email.module';
import { ErrorPopupComponent } from './components/error-popup/error-popup.component';
import { GButtonComponent } from './components/g-button/g-button.component';
import { GFormComponent } from './components/g-form/g-form.component';
import { GHeaderComponent } from './components/g-header/g-header.component';
import { GridComponent } from './components/grid/grid.component';
import { IntegrationGuideComponent } from './components/integration-guide/integration-guide.component';
import { NotificationComponent } from './components/notification/notification.component';
import { HostedVoiceOrderSummaryPopupComponent } from './components/popups/hosted-voice-order-summary/hosted-voice-order-summary-popup.component';
import { UserFullAccessPopupComponent } from './components/popups/user-full-access/user-full-access-popup.component';
import { RouterDropdownComponent } from './components/router-dropdown/router-dropdown.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SearchBoxFilterComponent } from './components/search-box-filter/search-box-filter.component';
import { SortingButtonComponent } from './components/sorting-button/sorting-button.component';
import { StatusDotComponent } from './components/status-dot/status-dot.component';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { TabDataFiltersComponent } from './components/tab-data-filters/tab-data-filters.component';
import { TableComponent } from './components/table/table.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { UserFeedbackComponent } from './components/user-feedback/user-feedback.component';
import { CustomKeyValuePipe } from './custom-key-value.pipe';
import { CopyToClipboardDirective } from './directives/copytoclipboard.directive';
import { FeatureFlagModule } from './directives/feature-flag/feature-flag.module';
import { GAFormModule } from './directives/ga-form/ga-form.module';
import { GcDismissDirective } from './directives/gc-dismiss.directive';
import { GcExpandDirective } from './directives/gc-expand.directive';
import { FocusableDirective } from './directives/gc-focus.directive';
import { HighlightTextDirective } from './directives/highlight-text.directive';
import { IfHasAccessDirective } from './directives/if-has-access.directive';
import { InputFocusDirective } from './directives/input-focus.directive';
import { PermissionVerificationModule } from './directives/permission-verification/permission-verification.module';
import { TableRowDirective } from './directives/table-row.directive';
import { AbsolutePathPipe } from './pipes/absolute-path.pipe';
import { EllipsisModule } from './pipes/ellipsis/ellipsis.module';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FilterNotificationsByStringPipe } from './pipes/filter-by-string.pipe';
import { FriendlyDateTimePipe } from './pipes/friendly-date-time.pipe';
import { GiacomDateModule } from './pipes/giacom-date/giacom-date.module';
import { GiacomDateTimeModule } from './pipes/giacom-date-time/giacom-date-time.module';
import { GiacomMoneyModule } from './pipes/giacom-money/giacom-money.module';
import { GiacomMonthNamePipe } from './pipes/giacom-month-name.pipe';
import { NewLineHtmlPipe } from './pipes/newline-html.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { QuantityChangeModule } from './pipes/quantity-change/quantity-change.module';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TrustHtmlModule } from './pipes/trust-html/trust-html.module';

const bootstrapModules: any[] = [
    AccordionModule,
    BsDatepickerModule,
    BsDropdownModule,
    CollapseModule,
    ModalModule,
    PaginationModule,
    PopoverModule,
    TabsModule,
    TimepickerModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule,
];

const modalModules: any[] = [SelectServiceModalModule, ConfirmationModalModule];

const modules: any[] = [
    AngularSvgIconModule,
    BlockUiModule,
    CloudMarketPopupModule,
    CmChartsModule,
    CmFormsModule,
    CmTableModule,
    CmTypographyModule,
    CommonModule,
    CoterminateFormControlModule,
    EditorModule,
    FileInputModule,
    FilterHiddenPipeModule,
    FilterOutDisabledServicesPipeModule,
    FormsModule,
    KnowledgeBaseModule,
    NgScrollbarModule,
    PasswordModule,
    ReactiveFormsModule,
    RouterModule,
    SelectInputModule,
    TogglePasswordVisibilityModule,
    TranslateModule,
    WaitSpinnerModule,
    SafeHtmlPipeModule,
    DatetimePickerControlModule,
    EmailSecurityDisasterRecoveryModalModule,
    CustUserArchiveLoginModule,
    EndUserActivityModule,
    EndUserActivityViewEmailModule,
];

const modulesForShared: any[] = [
    CardModule,
    CmAlertModule,
    CmButtonDirectiveModule,
    CmButtonModule,
    CardModule,
    CmModalModule,
];

const directiveModules: any[] = [FeatureFlagModule, GAFormModule, PermissionVerificationModule];

const pipeModules: any[] = [
    GiacomDateModule,
    GiacomDateTimeModule,
    GiacomMoneyModule,
    EllipsisModule,
    QuantityChangeModule,
    TrustHtmlModule,
    DropdownActionsFilterPipeModule
];

const components: any[] = [
    AzureUsageComponent,
    CmDynamicFormComponent,
    DropdownMenuComponent,
    EndUserAliasComponent,
    EndUserOnPremiseAliasComponent,
    ErrorPopupComponent,
    GButtonComponent,
    GFormComponent,
    GHeaderComponent,
    GridComponent,
    HostedExchangeAddressBoxComponent,
    IntegrationGuideComponent,
    NotificationComponent,
    RouterDropdownComponent,
    SearchBoxComponent,
    SearchBoxFilterComponent,
    SortingButtonComponent,
    StatusDotComponent,
    SubMenuComponent,
    TabDataFiltersComponent,
    TableComponent,
    TabsComponent,
    ToolTipComponent,
    UserFeedbackComponent,
];

const popupComponents: any[] = [
    HostedVoiceOrderSummaryPopupComponent,
    UserFullAccessPopupComponent,
];

const directives: any[] = [
    CopyToClipboardDirective,
    FocusableDirective,
    GcDismissDirective,
    GcExpandDirective,
    HighlightTextDirective,
    IfHasAccessDirective,
    InputFocusDirective,
    TableRowDirective,
];

const pipes: any[] = [
    AbsolutePathPipe,
    CustomKeyValuePipe,
    FileSizePipe,
    FilterNotificationsByStringPipe,
    FriendlyDateTimePipe,
    GiacomMonthNamePipe,
    NewLineHtmlPipe,
    OrderByPipe,
    SafeUrlPipe,
];

@NgModule({
    declarations: [...pipes, ...directives, ...components, ...popupComponents],
    imports: [
        ...bootstrapModules,
        ...modules,
        ...modulesForShared,
        ...directiveModules,
        ...pipeModules,
        ...modalModules,
    ],
    exports: [
        ...bootstrapModules,
        ...modules,
        ...pipes,
        ...directives,
        ...components,
        ...popupComponents,
    ],
})
export class SharedModule {
    static popupsRegistered: boolean = false;

    constructor(private _popupService: PopupService) {
        if (SharedModule.popupsRegistered) {
            return;
        }

        SharedModule.popupsRegistered = true;
        this._popupService.registerPopups({
            'user-full-access-permission': new PopUpDetails(
                UserFullAccessPopupComponent,
                'customers',
                'miscellaneous'
            ),
            'hosted-voice-order-summary-popup': new PopUpDetails(
                HostedVoiceOrderSummaryPopupComponent,
                'customers',
                'miscellaneous'
            ),
        });
    }
}
