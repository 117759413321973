import { STORAGE_KEYS } from '../../constants/storage-keys.constants';

import { AbstractPageLoadBase, ResellerData } from './shared-classes';

// eslint-disable:naming-convention
export class GAPageLoad {
    event: string;
    original_location: string;
    page_view_method: string;
    page_type: string;
    page_name: string;
    visitor_id: string;
    client_id: string;
    customer_id: string;
    user_id: string;
    service_name: string;
    ticket_id: string;
    invoice_id: string;
    sso_user_id: string;
    sso_organisation_id: string;
    userProperties: { user_id: string; visitor_id: string } = { user_id: null, visitor_id: null };

    constructor(
        pageLoad: AbstractPageLoadBase,
        resellerData: ResellerData,
        rootPageView: string,
        additionalData?: any
    ) {
        this.page_type = pageLoad.pageType;
        this.page_view_method = this.getPageViewMethodText(pageLoad.pageViewMethod);

        if (pageLoad.pageViewMethod === PageViewMethod.tabbedPage) {
            this.page_name = `${rootPageView}/${pageLoad.pageName}/${additionalData.tab}`;
        } else if (pageLoad.pageViewMethod === PageViewMethod.virtualPage) {
            this.page_name = `${rootPageView}/${pageLoad.pageName}/${additionalData.path}`;
        } else {
            this.page_name = `${rootPageView}/${pageLoad.pageName}`;
        }

        this.event = 'virtual page view';
        this.original_location = this.getOriginalLocation();

        if (additionalData) {
            const additionalDataKeys: string[] = Object.keys(additionalData);

            for (const key of additionalDataKeys) {
                switch (key) {
                    case 'customer_id':
                    case 'user_id':
                    case 'service_name':
                    case 'ticket_id':
                    case 'invoice_id':
                        this[key] = additionalData[key];
                        break;
                    default:
                        break;
                }
            }
        }

        this.visitor_id = resellerData.visitorId
            ? resellerData.visitorId
            : localStorage.getItem(STORAGE_KEYS.VISITOR_ID);
        this.client_id = resellerData.userReference
            ? resellerData.userReference
            : localStorage.getItem(STORAGE_KEYS.USER_REF);
        this.user_id = this.user_id === undefined ? this.client_id : this.user_id;

        this.userProperties = {
            user_id: this.client_id,
            visitor_id: this.visitor_id,
        };

        if (this.visitor_id === null) {
            this.changeEventForNotLoggedUser();
        }
    }

    changeEventForNotLoggedUser(): void {
        this.event = 'unlogged virtual page view';
    }

    getPageViewMethodText(method: PageViewMethod): string {
        switch (method) {
            case PageViewMethod.page:
                return 'page';
            case PageViewMethod.virtualPage:
                return 'virtual page';
            case PageViewMethod.tabbedPage:
                return 'tabbed page';
            case PageViewMethod.popUp:
                return 'pop-up';
            case PageViewMethod.overlay:
                return 'overlay';
            default:
                throw new Error('Method not implemented');
        }
    }

    getOriginalLocation(): string {
        return [
            document.location.protocol,
            '//',
            document.location.hostname,
            document.location.pathname,
            document.location.search,
        ].join('');
    }
}

export enum PageViewMethod {
    page = 1,
    virtualPage = 2,
    tabbedPage = 3,
    popUp = 4,
    overlay = 5,
}
