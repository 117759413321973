import { Injectable } from '@angular/core';
import { PaymentMethodStatus } from 'app/core/enums/payment-method-status-type.enum';
import { PaymentType } from 'app/core/enums/payment-type.enum';
import { ICreateCardPaymentResponse } from 'app/core/interfaces/card-create-payment-response.interface';
import { IPaymentCardModel } from 'app/models/billing/payment-card.interface';
import { STORAGE_KEYS } from 'app/shared/constants/storage-keys.constants';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { BaseUrl } from '../../../shared/urls/base.url';
import { IDirectDebitAuthHostedPageResponse } from '../../interfaces/directdebit-auth-hosted-page-response';
import { IDirectDebitCancellationResponse } from '../../interfaces/directdebit-cancellation-response';
import { IDirectDebitStatusResponse } from '../../interfaces/directdebit-status-response';
import { IExecutionResult } from '../../interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';


@Injectable({
    providedIn: 'root',
})
export class BillingService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    getCurrentPaymentMethodForReseller$(): Observable<IExecutionResult<any> | any> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.COMPANY_INFO.MY_BUSINESS_DATA).pipe(
            map((data: IExecutionResult<any>) => {
                if (data && data.Result && data.Result.BillingDetails) {
                    return data.Result.BillingDetails.PreferredPaymentMethod;
                }
            })
        );
    }

    saveDirectDebitPayerAuthorisation$(
        payerAuthorisationId: string | number
    ): Observable<IExecutionResult<any>> {
        // Post the payerAuthorisationId
        return this._authorizedHttpService.post$(
            API_ENDPOINTS.DIRECT_DEBIT.SAVE_PAYER_AUTHORISATION(payerAuthorisationId),
            null
        );
    }

    getDirectDebitStatus$(): Observable<IExecutionResult<IDirectDebitStatusResponse>> {
        return this._authorizedHttpService.get$(
            `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.V1_BASE}${API_ENDPOINTS.BILLING_SERVICE.DIRECT_DEBIT_STATUS}`
        );
    }

    getPaymentHostedPageAuthorization$(
        paymentType: PaymentType
    ): Observable<IExecutionResult<IDirectDebitAuthHostedPageResponse>> {
        return this._authorizedHttpService.get$(
            `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.V1_BASE}${API_ENDPOINTS.BILLING_SERVICE.AUTH_HOSTED_PAGE[paymentType]}`
        );
    }

    getPaymentHostedPageWithCurrencyAuthorization$(
        paymentType: PaymentType,
        currency: string
    ): Observable<IExecutionResult<IDirectDebitAuthHostedPageResponse>> {
        return this._authorizedHttpService.get$(
            `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.V1_BASE}${API_ENDPOINTS.BILLING_SERVICE.AUTH_HOSTED_PAGE[paymentType]}/${currency}`
        );
    }


    cancelDirectDebit$(): Observable<IExecutionResult<IDirectDebitCancellationResponse>> {
        return this._authorizedHttpService.post$(
            `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.V1_BASE}${API_ENDPOINTS.BILLING_SERVICE.DIRECT_DEBIT_CANCEL}`,
            null
        );
    }

    /**
     * Update resellers payment settings in Zuora based on provided values. Cancels all active card payment methods
     * @param paymentMethodId - this payment method will be setup as default
     * @param paymentTerm - reseller payment term will be updated to provided value
     * @param autoPay - if set to true reseller payment setting auto-pay property will be set to true and future invoices will be auto-payed
     * @returns - updated payment method id
     */
    setSingleDefaultDirectDebitPaymentMethod$(
        paymentMethodId: string,
        paymentTerm: string,
        autoPay: boolean
    ): Observable<string> {
        return this.getPaymentCards$(PaymentMethodStatus.active).pipe(
            switchMap((cards: IPaymentCardModel[]) => {
                const paymentMethodIds: string[] = cards.map((card: IPaymentCardModel) => card.Id);

                return this.setSingleDefaultPaymentMethod$(
                    paymentMethodId,
                    paymentTerm,
                    autoPay,
                    paymentMethodIds,
                    'BankTransfer'
                );
            }),
            catchError((error: unknown) => throwError(error))
        );
    }

    /**
     * Update resellers payment settings in Zuora based on provided values. Cancels all payment methods marked for cancellation.
     * @param paymentMethodId - this payment method will be setup as default
     * @param paymentTerm - reseller payment term will be updated to provided value
     * @param autoPay - if set to true reseller payment setting auto-pay property will be set to true and future invoices will be auto-payed
     * @param paymentMethodsToCancelIds - all payment methods with these id's will be canceled
     * @returns - updated payment method id
     */
    setSingleDefaultPaymentMethod$(
        paymentMethodId: string,
        paymentTerm: string,
        autoPay: boolean,
        paymentMethodsToCancelIds: string[] = [],
        paymentMethodType: string
    ): Observable<string> {
        return this.updateResellerPaymentSetting$(
            autoPay,
            paymentMethodId,
            paymentTerm,
            paymentMethodType
        ).pipe(
            switchMap((result: IExecutionResult<unknown>) => {
                if (!result.Succeed) {
                    return throwError('Update payment methods failed.');
                }

                return this.cancelPaymentMethods$(paymentMethodsToCancelIds);
            }),
            map((result: IExecutionResult<unknown>) => {
                if (!result.Succeed) {
                    throw new Error('Update payment methods failed.');
                }

                return paymentMethodId;
            }),
            catchError((error: unknown) => throwError(error))
        );
    }

    updateResellerPaymentSetting$(
        autoPay: boolean,
        defaultPaymentMethod: string,
        paymentTerm: string,
        paymentMethodType: string
    ): Observable<IExecutionResult<unknown>> {
        return this._authorizedHttpService.put$(
            `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.PAYMENT_UPDATE_PAYMENT_METHODS}`,
            {
                autoPay,
                paymentTerm,
                paymentMethodType,
                defaultPaymentMethodId: defaultPaymentMethod,
            }
        );
    }

    cancelPaymentMethods$(paymentMethodsIds: string[]): Observable<IExecutionResult<unknown>> {
        if (paymentMethodsIds === null || paymentMethodsIds.length <= 0) {
            return of({
                Succeed: true,
                Error: null,
                Errors: null,
                Messages: [],
                Labels: null,
                Resources: null,
                Result: null,
            });
        }

        return this._authorizedHttpService.post$(
            `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.CARD_CANCEL_PAYMENT_METHODS}`,
            paymentMethodsIds
        );
    }

    getPaymentCards$(status: PaymentMethodStatus): Observable<IPaymentCardModel[]> {
        return this._authorizedHttpService
            .get$(`${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.CARD_LIST_CARDS}`)
            .pipe(
                tap((result: IExecutionResult<IPaymentCardModel[]>) => {
                    if (!result.Succeed) {
                        return throwError(
                            'Loading of payment cards failed. Result contains failed succeed status flag.'
                        );
                    }
                }),
                catchError((error: unknown) => throwError(error)),
                map((result: IExecutionResult<IPaymentCardModel[]>) =>
                    result.Result.filter(
                        (card: IPaymentCardModel) => card.PaymentMethodStatus === status
                    ).sort(
                        (a: IPaymentCardModel, b: IPaymentCardModel) =>
                            Number(b.CreatedDate) - Number(a.CreatedDate)
                    )
                )
            );
    }

    payByStoredCard$(
        invoiceId: string,
        paymentMethodId: string,
        balanceToPay: number
    ): Observable<ICreateCardPaymentResponse> {
        return this._authorizedHttpService
            .post$(
                `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.BILLING_SERVICE.CARD_CREATE_PAYMENT}`,
                {
                    PaymentMethodId: paymentMethodId,
                    Amount: balanceToPay,
                    PaymentDate: new Date().toISOString(),
                    Currency: localStorage.getItem(STORAGE_KEYS.CURRENCY_CODE),
                    InvoiceId: invoiceId,
                }
            )
            .pipe(
                tap((result: IExecutionResult<ICreateCardPaymentResponse>) => {
                    if (!result.Succeed) {
                        return throwError(
                            'Loading of payment cards failed. Result contains failed succeed status flag.'
                        );
                    }
                }),
                catchError((error: unknown) => throwError(error)),
                map((result: IExecutionResult<ICreateCardPaymentResponse>) => result.Result)
            );
    }
}
