export enum MarketplaceServiceType {
    all = 'all',
    modernWorkplace = 'modernWorkplace',
    cloudInfrastructure = 'cloudInfrastructure',
    security = 'security',
    backupContinuity = 'backupContinuity'
}

export enum MarketplaceOrderBy {
    name = 'name',
    relevance = 'relevance',
}

export interface IMarketplaceQueryParams {
    serviceType: MarketplaceServiceType;
    orderBy: MarketplaceOrderBy;
}
