// eslint-disable-next-line max-classes-per-file
import { PageViewMethod } from './ga-page-load';
import { GAService } from './ga-service';

export abstract class AbstractPageLoadBase {
    abstract pageName: string;
    abstract pageType: string;
    abstract pageViewMethod: PageViewMethod;
}

export class PageLoadData extends AbstractPageLoadBase {
    pageName: string;
    pageType: string;
    pageViewMethod: PageViewMethod;
}

export abstract class AbstractPageLoad extends AbstractPageLoadBase {
    protected _gaService: GAService;

    constructor(gaService: GAService) {
        super();
        this._gaService = gaService;
    }

    recordPageLoad(additionalData?: any): void {
        this._gaService.pageLoad(this, additionalData);
    }

    recordTabbedPageLoad(additionalData?: ITabbedPageData): void {
        const pageLoadData: PageLoadData = new PageLoadData();

        pageLoadData.pageViewMethod = PageViewMethod.tabbedPage;
        pageLoadData.pageName = this.pageName;
        pageLoadData.pageType = this.pageType;
        this._gaService.pageLoad(pageLoadData, additionalData);
    }

    recordVirtualPageLoad(additionalData?: IVirtualPageData): void {
        const pageLoadData: PageLoadData = new PageLoadData();

        pageLoadData.pageViewMethod = PageViewMethod.virtualPage;
        pageLoadData.pageName = this.pageName;
        pageLoadData.pageType = this.pageType;
        this._gaService.pageLoad(pageLoadData, additionalData);
    }
}

export class PageLoadSimple extends AbstractPageLoad {
    pageName: string;
    pageType: string;
    pageViewMethod: PageViewMethod;

    constructor(
        gaService: GAService,
        pageName: string,
        pageType: string,
        pageViewMethod: PageViewMethod
    ) {
        super(gaService);
        this.pageName = pageName;
        this.pageType = pageType;
        this.pageViewMethod = pageViewMethod;
    }
}

export class ResellerData {
    visitorId: string;
    userReference: string;
}

export interface ITabbedPageData {
    tab: string;
}

export interface IVirtualPageData {
    path: string;

    [key: string]: string;
}
