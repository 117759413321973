// TODO: move all local storage keys here
// eslint-disable-next-line
export const STORAGE_KEYS = {
    USER: '__user__',
    COMPARISON_REPORT: 'comparisonReport',
    VISITOR_ID: 'userMex',
    USER_REF: 'userRef',
    USER_NAME: 'username',
    DISPLAY_NAME: 'displayname',
    EMAIL_ADDRESS: 'emailAddress',
    PREVIOUS_TYPE: 'previousType',
    SERVICE: 'service',
    USERTYPE: 'userType',
    USER_TYPE: 'user_type',
    CUSTOMER_ADMIN_CLOUD_ORGANISATION_ID: 'enduseradminId',
    END_USER_ADMIN: 'enduseradmin',
    IS_ADMIN_JUMP: 'isJump',
    ARTICLE_PATH: 'article_path',
    CLOUD_ORGANISATION_ID: 'cloud_org_id',
    FALLBACK_URL: 'fallback_url',
    CURRENCY_CODE: 'currency_code',
    DEBUG_ZUORA_HOSTED_PAGE: 'debug.zuora-hosted-page',
    LOGIN_STRATEGY: 'loginStrategy',
    O365_AUTH_ID_TOKEN: 'o365AuthIdToken',
    REFRESH_TOKEN: 'refresh_token',
    RESELLER_CLOUD_MARKET_ID: 'resellerId',
    RESELLER_SOURCE: 'reseller_source'
};

export const COOKIE_KEYS = {
    CLOUD_ACCESS_TOKEN: 'cloud_access_token',
    ORGANISATION_ID: 'org_id',
    USE_SE: 'use_se',
    HARDWARE_SESSION_ID: 'PHPSESSID'
};
