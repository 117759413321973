import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { SsoAuthService } from '../../../core/services/sso-auth/sso-auth.service';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { HttpResponseCode } from '../../../shared/enum/http-response-code.enum';
import { NotificationsUrl } from '../../../shared/urls/notifications.url';
import { NotificationStatus } from '../components/notification-status.enum';
import { INotification } from '../interfaces/notification.interface';
import { ISignalRConnectionInfo } from '../interfaces/signal-r-connection-info.interface';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    notificationsStatusFilter$: BehaviorSubject<NotificationStatus> =
        new BehaviorSubject<NotificationStatus>(NotificationStatus.new);
    refreshNotificationTrigger$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

    private _notificationsFetch$: Observable<INotification[]> =
        this.refreshNotificationTrigger$.pipe(
            switchMap(() =>
                this._authorizedHttpService
                    .get$(API_ENDPOINTS.NOTIFICATIONS.GET_NOTIFICATIONS)
                    .pipe(map((response: IExecutionResult<INotification[]>) => response.Result))
            ),
            shareReplay()
        );

    private _hubConnection: HubConnection;

    constructor(
        private _router: Router,
        private _authorizedHttpService: AuthorizedHttpService,
        private _ssoAuthService: SsoAuthService
    ) {}

    connectSignalR(): void {
        if (this._hubConnection) {
            return;
        }
        const accessTokens: Promise<string> = this._getAccessToken();
        const url: string = `${NotificationsUrl.getHubUrl}/client/?hub=notifications`;

        this._hubConnection = new HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => accessTokens,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();

        this._hubConnection
            .start()
            .then(() => this)
            .catch((err: string) => console.log(`Error while establishing connection: ${err}`));

        this._hubConnection.on('SendMessage', () => {
            this.refreshNotifications();
        });
    }

    getNewNotificationsAmount$(): Observable<number> {
        return this._fetchNotifications$().pipe(
            map(
                (notifications: INotification[]) =>
                    notifications.filter(
                        (notification: INotification) =>
                            notification.Status === NotificationStatus.new
                    ).length
            )
        );
    }

    setNotificationsStatusFilter(newFilter: NotificationStatus): void {
        this.notificationsStatusFilter$.next(newFilter);
    }

    refreshNotifications(): void {
        this.refreshNotificationTrigger$.next();
    }

    getFilteredNotifications$(): Observable<INotification[]> {
        return combineLatest([this.notificationsStatusFilter$, this._fetchNotifications$()]).pipe(
            map(([statusToFilter, notifications]: [NotificationStatus, INotification[]]) =>
                notifications.filter((notification: INotification) => {
                    if (statusToFilter === NotificationStatus.all) {
                        return true;
                    }

                    return notification.Status === statusToFilter;
                })
            )
        );
    }

    markAsRead$(notificationId: string): Observable<unknown> {
        return this._updateNotification$(notificationId, NotificationStatus.read);
    }

    private _updateNotification$(notificationId: string, status: string): Observable<unknown> {
        // i don't know what backend should return here. When i testing this method, i got null
        return this._authorizedHttpService
            .patch$(API_ENDPOINTS.NOTIFICATIONS.UPDATE_NOTIFICATION, {
                Id: notificationId,
                Status: status,
            })
            .pipe(tap(() => this.refreshNotifications()));
    }

    private _fetchNotifications$(): Observable<INotification[]> {
        return this._notificationsFetch$;
    }

    private _getAccessToken(): Promise<string> {
        return new Promise<string>((resolve: any, reject: any) => {
            this._authorizedHttpService
                .get$<ISignalRConnectionInfo>(`${NotificationsUrl.getNegotiateUrl}/api/negotiate`)
                .pipe(
                    catchError((error: HttpErrorResponse & { _body: string }) => {
                        if (
                            error.status === HttpResponseCode.unauthorized &&
                            error.statusText === 'Unauthorized'
                        ) {
                            return throwError('no error');
                        }
                        if (error.status === HttpResponseCode.forbidden) {
                            if (error._body === '"PermissionNotFound"') {
                                this._router.navigate(['/permission-error']);
                            } else {
                                this._ssoAuthService.logoutWithDefaultOptions();
                            }
                        }

                        return throwError(error);
                    })
                )
                .toPromise()
                .then(
                    (res: ISignalRConnectionInfo) => {
                        resolve(res.accessToken);
                    },
                    (msg: string) => {
                        reject(msg);
                    }
                );
        });
    }
}
