import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import {
    Auth0Client,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    IdToken,
    LogoutOptions,
    PopupConfigOptions,
    PopupLoginOptions,
    RedirectLoginOptions,
    RedirectLoginResult,
    User,
} from '@auth0/auth0-spa-js';
import dayjs from 'dayjs';
import JwtDecode from 'jwt-decode';
import {
    BehaviorSubject,
    defer,
    forkJoin,
    from,
    iif,
    merge,
    Observable,
    of,
    ReplaySubject,
    Subject,
    throwError,
} from 'rxjs';
import {
    catchError,
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    scan,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import { SharedSessionCookieStorageService } from '../../../feature-modules/auth/services/cookie/shared-session-cookie-storage.service';
import { LogoutService } from '../../../feature-modules/auth/services/logout/logout.service';
import { ROUTES_PATHS } from '../../../shared/constants/routes.constants';
import { SSO } from '../../../shared/constants/sso.constants';
import { STORAGE_KEYS } from '../../../shared/constants/storage-keys.constants';
import { WINDOW } from '../../injection-tokens/window.injection-token';
import { DefaultPageRouteService } from '../default-page-route.service';
import { SingleExperienceUserService } from '../single-experience/single-experience-user.service';

import { CommsSessionManagementService } from './comms-session-management.service';
import { HardwareSessionManagementService } from './hardware-session-management.service';
import { Auth0ClientService } from './sso-auth-client.factory';
import { AuthClientConfig } from './types/sso-auth-client.config';
import { IAuthConfig } from './types/sso-auth-config.interface';

/**
 * The class was made based on code from https://github.com/auth0/auth0-angular
 * to support "auth0-spa-js" https://github.com/auth0/auth0-spa-js.
 */

@Injectable({
    providedIn: 'root',
})
export class SsoAuthService implements OnDestroy {

    private _isLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _errorSubject$: ReplaySubject<Error> = new ReplaySubject<Error>(1);
    private _refreshState$: Subject<void> = new Subject<void>();
    private _accessToken$: ReplaySubject<string> = new ReplaySubject<string>(1);
    // https://stackoverflow.com/a/41177163
    private _ngUnsubscribe$: Subject<void> = new Subject<void>();
    private _accessTokenTrigger$: Observable<{ previous: string; current: string }> =
        this._getAccessTokenTrigger$();
    private _isAuthenticatedTrigger$: Observable<boolean> = this._getAuthenticatedTrigger$();
    private _jwtDecode: (token: string) => IdToken = JwtDecode;
    private _isSsoLoginFlow: boolean = false;
    private _isAdminJump: boolean = false;

    constructor(
        @Inject(Auth0ClientService) private _auth0Client: Auth0Client,
        @Inject(WINDOW) private _window: Window,
        private _configFactory: AuthClientConfig,
        private _logoutService: LogoutService,
        private _router: Router,
        private _defaultPageRouteService: DefaultPageRouteService,
        private _commsSessionManagementService: CommsSessionManagementService,
        private _hardwareSessionManagementService: HardwareSessionManagementService,
        private _sharedSessionCookieStorageService: SharedSessionCookieStorageService,
        private _singleExperienceUserService: SingleExperienceUserService
    ) {
        this._setOrganisationIdRelatedToCurrentCloudSessionOnFirstRedirectToCloud();
        this._detectOrganisationChangeAndClearLocalSession();
        this._setStateLoginFlow();
        this._checkSessionOrCallback();
        this._checkClaimsToEnableSingleExperienceAndSetSsoFlow();
    }

    get isSsoLoginFlow(): boolean {
        return this._isSsoLoginFlow;
    }

    get isAdminJump(): boolean {
        return this._isAdminJump;
    }

    isLoading$(): Observable<boolean> {
        return this._isLoadingSubject$.asObservable();
}

    getSsoLoginError$(): Observable<Error> {
        return this._errorSubject$.asObservable();
    }

    idTokenClaims$(): Observable<IdToken | null> {
        return this._isAuthenticatedTrigger$.pipe(
            concatMap((authenticated: boolean) =>
                authenticated ? from(this._auth0Client.getIdTokenClaims()) : of(null)
            ),
            tap((idTokenClaims: IdToken) => {
                if (idTokenClaims && this._checkSingleExperienceFlag(idTokenClaims.__raw)) {
                    this._singleExperienceUserService.enable();
                } else {
                    this._singleExperienceUserService.disable();
                }
            })
        );
    }

    getUser$(): Observable<User> {
        return this._isAuthenticatedTrigger$.pipe(
            concatMap((authenticated: boolean) =>
                authenticated ? this._auth0Client.getUser() : of(null)
            )
        );
    }

    // Token validity check added, because depending on the SDK version and environment,
    // isAuthenticated$ does not check the validity of the token, which may cause problems in the future.
    isSsoUserAuthenticatedAndHasValidToken$(): Observable<boolean> {
        return this.isAuthenticated$().pipe(
            switchMap((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    return this.idTokenClaims$().pipe(
                        switchMap((idTokenClaims: IdToken) => {
                            if (idTokenClaims) {
                                return of(this._checkTokenExpirationStatus(idTokenClaims.__raw));
                            }

                            return of(false);
                        }),
                        catchError(() => of(false))
                    );
                }

                return of(false);
            }),
            catchError(() => of(false))
        );
    }

    ngOnDestroy(): void {
        // https://stackoverflow.com/a/41177163
        this._ngUnsubscribe$.next();
        this._ngUnsubscribe$.complete();
    }

    loginWithRedirect$(options?: RedirectLoginOptions): Observable<void> {
        return from(this._auth0Client.loginWithRedirect(options));
    }

    loginWithRedirectWithDefaultOptions$({
        userName,
        returnQueryParams,
    }: {
        userName?: string;
        returnQueryParams?: Params;
    }): Observable<void> {
        const options: RedirectLoginOptions = {
            authorizationParams: {
                redirect_uri: `${this._window.location.origin}/${ROUTES_PATHS.LOGIN}`,
                login_hint: userName,
                prompt: 'login',
            },
            appState: { target: this._getReturnUrl(ROUTES_PATHS.LOGIN, returnQueryParams) },
        };

        return this.loginWithRedirect$(options);
    }

    loginWithPopup$(options?: PopupLoginOptions, config?: PopupConfigOptions): Observable<void> {
        return from(
            this._auth0Client.loginWithPopup(options, config).then(() => {
                this._refreshState$.next();
            })
        );
    }

    logoutFromTradingPortals(): void {
        this._logoutFromTradingPortals$().subscribe();
    }

    clearLocalSession(): void {
        this._clearCloudLocalSession();
        this._clearAuth0LocalSession();
    }

    logoutWithDefaultOptions(returnQueryParams?: Params): void {
        if (!this.isSsoLoginFlow || this._isAdminJump) {
            this._clearCloudLocalSession();
            this._router.navigateByUrl(ROUTES_PATHS.LOGIN);

            return;
        }

        const logoutOptions: LogoutOptions = {
            logoutParams: {
                returnTo: `${this._window.location.origin}${this._getReturnUrl(
                     ROUTES_PATHS.LOGIN,
                    returnQueryParams
                )}`
            }
        };

        this._logout(logoutOptions);
    }

    redirectToLoginPageAfterClearAuth0LocalAndCloudSession(stateUrl?: string, singleExperienceFullUrl?: string): void {
        this._clearCloudLocalSession();
        this._clearAuth0LocalSession();

        this._defaultPageRouteService.goToDefaultLoginPage(stateUrl, singleExperienceFullUrl);
    }

    getAccessTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string | null> {
        return of(this._auth0Client).pipe(
            concatMap((client: Auth0Client) => client.getTokenSilently(options)),
            tap((token: string | null) => {
                this._accessToken$.next(token);
            }),
            catchError(() => {
                this._refreshState$.next();

                return of(null);
            })
        );
    }

    getAccessTokenWithPopup$(options?: GetTokenWithPopupOptions): Observable<string> {
        return of(this._auth0Client).pipe(
            concatMap((client: Auth0Client) => client.getTokenWithPopup(options)),
            tap((token: string) => this._accessToken$.next(token)),
            catchError((error: Error) => {
                this._errorSubject$.next(error);
                this._refreshState$.next();

                return throwError(error);
            })
        );
    }

    setSsoFlowData(idToken: IdToken): void {
        this._setSsoLoginFlow();

        if (idToken.org_id) {
            this._setOrganisationIdInCookies(idToken.org_id);
            this._setOrganisationIdInLocalStorage(idToken.org_id);
        }
    }

    setSsoLoginFlowFromJump(): void {
        this._setSsoLoginFlow();

        localStorage.setItem(STORAGE_KEYS.IS_ADMIN_JUMP, 'true');
        this._isAdminJump = true;
    }

    setLegacyLoginFlow(): void {
        localStorage.removeItem(SSO.LOGIN_FLOW.KEY);
        this._isSsoLoginFlow = false;

        this._clearAuth0LocalSession();
        this._removeStateAdminJump();
    }

    isAuthenticated$(): Observable<boolean> {
        return this._isAuthenticatedTrigger$.pipe(distinctUntilChanged());
    }

    private _clearAuth0LocalSession(): void {
        this._auth0Client.logout({
            openUrl: false
        }).then(() => {
            this._refreshState$.next();
        });
    }

    private _removeStateAdminJump(): void {
        localStorage.removeItem(STORAGE_KEYS.IS_ADMIN_JUMP);
        this._isAdminJump = false;
    }

    private _setSsoLoginFlow(): void {
        localStorage.setItem(SSO.LOGIN_FLOW.KEY, SSO.LOGIN_FLOW.SSO);
        this._isSsoLoginFlow = true;

        this._removeStateAdminJump();
    }

    private _terminateLocalCloudSession(): void {
        this._clearCloudLocalSession();
        this._clearCookieStorage();
    }

    private _logout(options?: LogoutOptions): void {
        this._logoutFromTradingPortals$().subscribe(() => {
            this._logoutFromAuth0$(options);
        });
    }

    private _logoutFromAuth0$(options?: LogoutOptions): Observable<void> {
        return from(
            this._auth0Client.logout(options).then(() => {
                if (options?.openUrl === false || options?.openUrl) {
                    this._refreshState$.next();
                }
            })
        );
    }

    private _logoutFromTradingPortals$(): Observable<boolean> {
        return forkJoin([
            this._commsSessionManagementService.terminateCommsSession$(),
            this._hardwareSessionManagementService.terminateHardwareSession$()
        ])
        .pipe(
            switchMap(() => {
                this._terminateLocalCloudSession();

                return of(true);
            })
        );
    }

    private _clearCloudLocalSession(): void {
        localStorage.clear();
        this._logoutService.logout();
    }

    private _clearCookieStorage(): void {
        this._sharedSessionCookieStorageService.removeOrganisationId();
        this._sharedSessionCookieStorageService.removeUseSingleExperience();
    }

    private _detectOrganisationChangeAndClearLocalSession(): void {
        const orgIdFromCookies: string = this._sharedSessionCookieStorageService.getOrganisationId();
        const orgIdRelatedToCurrentCloudSession: string = localStorage.getItem(STORAGE_KEYS.CLOUD_ORGANISATION_ID);

        if (orgIdFromCookies !== null && orgIdRelatedToCurrentCloudSession !== null && orgIdFromCookies !== orgIdRelatedToCurrentCloudSession) {
            this.clearLocalSession();

            this._setSsoLoginFlow();
            this._setOrganisationIdInLocalStorage(orgIdFromCookies);
        }
    }

    private _setOrganisationIdRelatedToCurrentCloudSessionOnFirstRedirectToCloud(): void {
        const orgIdFromCookies: string = this._sharedSessionCookieStorageService.getOrganisationId();
        const orgIdRelatedToCurrentCloudSession: string = localStorage.getItem(STORAGE_KEYS.CLOUD_ORGANISATION_ID);

        if (orgIdFromCookies !== null && orgIdRelatedToCurrentCloudSession === null) {
            this._clearCloudLocalSession();
            this._setSsoLoginFlow();
            this._setOrganisationIdInLocalStorage(orgIdFromCookies);
        }
    }


    private _setStateLoginFlow(): void {
        this._isSsoLoginFlow = localStorage.getItem(SSO.LOGIN_FLOW.KEY) === SSO.LOGIN_FLOW.SSO;
        this._isAdminJump = localStorage.getItem(STORAGE_KEYS.IS_ADMIN_JUMP) === 'true';
    }

    private _handleRedirectCallback$(url?: string): Observable<RedirectLoginResult> {
        return defer(() => this._auth0Client.handleRedirectCallback(url)).pipe(
            withLatestFrom(this._isLoadingSubject$),
            tap(([result, isLoading]: [RedirectLoginResult, boolean]) => {
                if (!isLoading) {
                    this._refreshState$.next();
                }
                const target: string = result?.appState?.target ?? '/';

                this._setSsoLoginFlow();
                this._router.navigateByUrl(target);
            }),
            map(([result]: [RedirectLoginResult, boolean]) => result)
        );
    }

    private _handleError$(error: Error): Observable<undefined> {
        const config: IAuthConfig = this._configFactory.get();
        this._errorSubject$.next(error);
        this._router.navigateByUrl(config.redirectUri || `${ROUTES_PATHS.LOGIN}`);

        return of(undefined);
    }

    private _checkSessionOrCallback(): void {
        const checkSessionOrCallback$: (
            isCallback: boolean
        ) => Observable<void | RedirectLoginResult> = (isCallback: boolean) =>
            iif(() => isCallback, this._handleRedirectCallback$(), this._checkSession$());

        this._shouldHandleCallback$()
            .pipe(
                switchMap((isCallback: boolean) =>
                    checkSessionOrCallback$(isCallback).pipe(
                        catchError((error: Error) => this._handleError$(error))
                    )
                ),
                tap(() => {
                    this._isLoadingSubject$.next(false);
                }),
                takeUntil(this._ngUnsubscribe$)
            )
            .subscribe();
    }

    private _checkSession$(): Observable<void> {
        return defer(() => this._auth0Client.checkSession());
    }

    private _checkClaimsToEnableSingleExperienceAndSetSsoFlow(): void {
        this._accessToken$
            .pipe(concatMap(() => this._auth0Client.getIdTokenClaims()))
            .subscribe((idTokenClaims: IdToken) => {
                if (idTokenClaims) {
                    this._setSsoLoginFlow();
                }
            })
    }

    private _shouldHandleCallback$(): Observable<boolean> {
        return of(this._window.location.search).pipe(
            map((search: string) => {
                const searchParams: URLSearchParams = new URLSearchParams(search);

                return (
                    (searchParams.has('code') || searchParams.has('error')) &&
                    searchParams.has('state') &&
                    !this._configFactory.get().skipRedirectCallback
                );
            })
        );
    }

    private _getAccessTokenTrigger$(): Observable<{ previous: string; current: string }> {
        return this._accessToken$.pipe(
            scan(
                (
                    acc: { current: string | null; previous: string | null },
                    current: string | null
                ) => ({
                    current,
                    previous: acc.current,
                }),
                { current: null, previous: null }
            ),
            filter(
                ({ previous, current }: { previous: string | null; current: string | null }) =>
                    previous !== current
            )
        );
    }

    private _getAuthenticatedTrigger$(): Observable<boolean> {
        return this.isLoading$().pipe(
            filter((loading: boolean) => !loading),
            distinctUntilChanged(),
            switchMap(() =>
                merge(
                    defer(() => this._auth0Client.isAuthenticated()),
                    this._accessTokenTrigger$.pipe(
                        mergeMap(() => this._auth0Client.isAuthenticated())
                    ),
                    this._refreshState$.pipe(mergeMap(() => this._auth0Client.isAuthenticated()))
                )
            )
        );
    }

    private _getReturnUrl(url: string, returnQueryParams?: Params): string {
        const returnUrlTree: UrlTree = this._router.createUrlTree([url], {
            queryParams: returnQueryParams || {},
        });

        return this._router.serializeUrl(returnUrlTree);
    }

    private _checkSingleExperienceFlag(token: string): boolean {
        return this._jwtDecode(token)['https://cloudmarket.com/use_se'];
    }

    private _checkTokenExpirationStatus(token: string): boolean {
        return new Date().getTime() / 1000 < this._jwtDecode(token).exp;
    }


    private _setOrganisationIdInCookies(organisationId: string): void {
        const oneYearFromNow: Date = dayjs().add(1, 'year').toDate();
        this._sharedSessionCookieStorageService.setOrganisationId(
            organisationId,
            oneYearFromNow
        );
    }

    private _setOrganisationIdInLocalStorage(organisationId: string): void {
        localStorage.setItem(STORAGE_KEYS.CLOUD_ORGANISATION_ID, organisationId);
    }
}
