import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartType } from 'chart.js';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { ITextValueResponse } from '../../../core/interfaces/text-value-response.interface';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { AzureManagement } from '../../../pages/customer/manage-service/azure/azure.management';
import { API_ENDPOINTS } from '../../constants/api/api-endpoints.constants';

import {
    AZURE_DROPDOWN_BY_INITIAL_VALUES,
    AZURE_DROPDOWN_DATES_INITIAL_VALUES,
    AZURE_USAGE_DROPDOWN_DATES,
} from './azure-usage.constants';
import { AzureUsageBy, AzureUsagePeriod } from './azure-usage.enum';

@Component({
    selector: 'cm-azure-usage',
    templateUrl: './azure-usage.component.html',
    styleUrls: ['./azure-usage.component.scss'],
})
export class AzureUsageComponent implements OnInit {
    @Input() management: AzureManagement;
    dropDownBy: typeof AZURE_DROPDOWN_BY_INITIAL_VALUES = AZURE_DROPDOWN_BY_INITIAL_VALUES;
    dropDownDate: typeof AZURE_DROPDOWN_DATES_INITIAL_VALUES = AZURE_DROPDOWN_DATES_INITIAL_VALUES;
    dropDownSubscription: ITextValueResponse[];
    dropDownGroup: any[];
    searchBy: string = this.dropDownBy[0].text;
    searchByValue: string = this.dropDownBy[0].value;
    searchDate: string = this.dropDownDate[0].text;
    searchDateValue: string = this.dropDownDate[0].value;
    searchGroup: string;
    searchGroupValue: string;
    searchSubscription: string;
    searchSubscriptionValue: string;

    isLineChart: boolean;
    isNoData: boolean;
    barChartOptions: any = {
        scaleShowVerticalLines: true,
        responsive: true,
        scales: {
            xAxes: {
                stacked: true,
                grid: { display: false },
            },
            yAxes: {
                stacked: true,
                beginAtZero: true,
            },
        },
    };

    barChartLabels: string[] = [];
    barChartType: ChartType = 'bar';
    barChartLegend: boolean = true;
    barChartData: { data: any[]; label: string }[];
    // Line Chart
    lineChartData: any[] = [];
    lineChartLabels: any[] = [];
    lineChartOptions: any = {
        responsive: true,
        tension: 0.4
    };
    lineChartLegend: boolean = true;
    lineChartType: ChartType = 'line';

    constructor(
        private _authorizedHttpService: AuthorizedHttpService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.barChartData = [
            {
                data: [],
                label: this._translateService.instant('MANAGE_SERVICE.AZURE_BILLING.DAILY_SPEND'),
            },
        ];
        this.searchDateValue = AZURE_USAGE_DROPDOWN_DATES[AzureUsagePeriod.last7Days];
        this.isNoData = true;
        this.isLineChart = true;
        this.getSubscriptions();
    }

    onChangeSelectedDropdownSubscription(item: any): void {
        this.searchSubscription = item.Text;
        this.searchSubscriptionValue = item.Value;
        if (this.searchByValue === AzureUsageBy.byResource.toString()) {
            this.bingDataForGroup();
        }
    }

    onChangeSelectedDropdownBy(item: any): void {
        this.searchBy = item.text;
        this.searchByValue = item.value;
        if (item.value === AzureUsageBy.byResource.toString()) {
            this.bingDataForGroup();
        }
    }

    onChangeSelectedDropdownDate(item: any): void {
        this.searchDate = item.text;
        this.searchDateValue = item.value;
    }

    onChangeSelectedDropdownGroup(item: any): void {
        this.searchGroup = item.Text;
        this.searchGroupValue = item.Value;
    }

    getSubscriptions(): void {
        this._authorizedHttpService
            .get$<IExecutionResult<ITextValueResponse[]>>(
                API_ENDPOINTS.AZURE_MANAGEMENT.SET_SUBSCRIPTION_CHART_DROPDOWN(this.management.organisationId)
            )
            .subscribe((resp: IExecutionResult<ITextValueResponse[]>) => {
                if (!resp.Succeed) {
                    return;
                }

                this.dropDownSubscription = resp.Result;
                this.searchSubscription = resp.Result[0]?.Text;
                this.searchSubscriptionValue = resp.Result[0]?.Value;

                this.runReportTotalSpend();
            });
    }

    bingDataForGroup(): void {
        this._authorizedHttpService
            .get$(
                API_ENDPOINTS.AZURE_MANAGEMENT.SET_RESOURCE_CHART_DROPDOWN(this.management.organisationId, this.searchSubscriptionValue)
            )
            .subscribe((result: IExecutionResult<any>) => {
                if (!result.Succeed) {
                    return;
                }

                this.dropDownGroup = result.Result;
                this.searchGroup = result.Result[0]?.Text;
                this.searchGroupValue = result.Result[0]?.Value;
            });
    }

    runReportTotalSpend(): void {
        this.isLineChart = true;
        this.isNoData = true;
        this._authorizedHttpService
            .get$(
                API_ENDPOINTS.AZURE_MANAGEMENT.GET_TOTAL_SPEND_JSON_DATA(
                    this.management.organisationId,
                    this.searchSubscriptionValue,
                    this.searchDateValue
                )
            )
            .subscribe((result: IExecutionResult<any>) => {
                if (!result.Succeed) {
                    return;
                }

                this.buildBarChart(result.Result);
                this.isNoData = false;
            });
    }

    runReportByGroup(): void {
        this.isLineChart = true;
        this.isNoData = true;
        this._authorizedHttpService
            .get$(
                API_ENDPOINTS.AZURE_MANAGEMENT.GET_SPEND_BY_GROUP_CHART_DATA(
                    this.management.organisationId,
                    this.searchSubscriptionValue,
                    this.searchDateValue
                )
            )
            .subscribe((result: IExecutionResult<any>) => {
                if (!result.Succeed) {
                    return;
                }

                this.buildMeterChart(result.Result);
                this.isNoData = false;
            });
    }

    runReportByResource(): void {
        this.isLineChart = false;
        this.isNoData = true;
        this._authorizedHttpService
            .get$(
                API_ENDPOINTS.AZURE_MANAGEMENT.GET_SPEND_BY_RESOURCE_CHART_DATA(
                    this.management.organisationId,
                    this.searchSubscriptionValue,
                    this.searchGroupValue,
                    this.searchDateValue
                )
            )
            .subscribe((result: IExecutionResult<any>) => {
                if (!result.Succeed) {
                    return;
                }

                this.buildResourceLineChart(result.Result);
                this.isNoData = false;
            });
    }

    runReportByMeter(): void {
        this.isLineChart = true;
        this.isNoData = true;
        this._authorizedHttpService
            .get$(
                API_ENDPOINTS.AZURE_MANAGEMENT.GET_SPEND_BY_METER_CHART_DATA(
                    this.management.organisationId,
                    this.searchSubscriptionValue,
                    this.searchDateValue
                )
            )
            .subscribe((result: IExecutionResult<any>) => {
                if (!result.Succeed) {
                    return;
                }

                this.buildMeterChart(result.Result);
                this.isNoData = false;
            });
    }

    showReport(): void {
        switch (this.searchByValue) {
            case AzureUsageBy.inTotal.toString():
                this.runReportTotalSpend();
                break;
            case AzureUsageBy.byGroup.toString():
                this.runReportByGroup();
                break;
            case AzureUsageBy.byResource.toString():
                this.runReportByResource();
                break;
            case AzureUsageBy.byMeter.toString():
                this.runReportByMeter();
                break;
            default:
                break;
        }
    }

    buildBarChart(lstData: any): void {
        const chartdata: any = [
            {
                data: [],
                label: this._translateService.instant('MANAGE_SERVICE.AZURE_BILLING.DAILY_SPEND'),
            },
        ];
        const labels: string[] = [];

        for (const item of lstData) {
            labels.push(item.Date);
            chartdata[0].data.push(item.Spend);
        }

        this.barChartLabels = labels;
        this.barChartData = chartdata;
    }

    buildMeterChart(lstData: any): void {
        this.barChartLabels = lstData.Label;
        const data: any[] = [lstData.Values.length];

        for (let j: number = 0; j < lstData.Values.length; j++) {
            const dataItem: any = {
                data: lstData.Values[j].Values,
                label: lstData.Values[j].Symbol,
            };

            data[j] = dataItem;
        }
        this.barChartData = data;
    }

    buildResourceLineChart(lstItem: any): void {
        this.lineChartLabels = lstItem.Label;
        const data: any = [lstItem.Values.length];

        for (let j: number = 0; j < lstItem.Values.length; j++) {
            const dataItem: any = {
                data: lstItem.Values[j].Values,
                label: lstItem.Values[j].Symbol,
            };

            data[j] = dataItem;
        }
        this.lineChartData = data;
    }
}
