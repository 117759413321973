import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { IReportingSetting } from '../../../new-shared/components/modals/reporting/reporting-settings.types';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiDistributionGroupService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    list(
        organisationId: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.GET_DISTRIBUTION_GROUPS(organisationId), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    getAccessInfo(organisationId: string, callback: (responseData: any) => void): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.GET_ACCESS_INFO(organisationId))
            .subscribe((responseData: any) => callback(responseData));
    }

    getAddInfo(organisationId: string, callback: (responseData: any) => void): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.GET_ADD_INFO(organisationId))
            .subscribe((responseData: any) => callback(responseData));
    }

    getUpdateInfo(
        organisationId: string,
        emailAddress: string,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.GET_UPDATE_INFO(organisationId, emailAddress))
            .subscribe((responseData: any) => callback(responseData));
    }

    addDistributionGroup(
        organisationId: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.ADD_DISTRIBUTION_GROUP(organisationId), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    deleteDistributionGroup(
        organisationId: string,
        emailAddress: string,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .delete$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.DELETE_DISTRIBUTION_GROUP(organisationId, emailAddress))
            .subscribe((responseData: any) => callback(responseData));
    }

    updateSpamLevel(
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.UPDATE_SPAM_LEVEL(emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    updateArchiveLevel(
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.UPDATE_ARCHIVE_LEVEL(emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    getWhitelistRules(emailAddress: string, callback: (responseData: any) => void): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.GET_WHITELIST_RULES(emailAddress))
            .subscribe((responseData: any) => callback(responseData));
    }

    getBlacklistRules(emailAddress: string, callback: (responseData: any) => void): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.GET_BLACKLIST_RULES(emailAddress))
            .subscribe((responseData: any) => callback(responseData));
    }

    getArchiveRules(emailAddress: string, callback: (responseData: any) => void): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.GET_ARCHIVE_RULES(emailAddress))
            .subscribe((responseData: any) => callback(responseData));
    }

    addWhitelistRules(
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.ADD_WHITELIST_RULES(emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    addBlacklistRules(
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.ADD_BLACKLIST_RULES(emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    addArchiveRule(
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.ADD_ARCHIVE_RULES(emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    removeWhitelistRule(
        emailAddress: string,
        deletingAddress: string,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .deleteWithBody$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.REMOVE_WHITELIST_RULE(emailAddress), {
                Address: deletingAddress,
            })
            .subscribe((responseData: any) => callback(responseData));
    }

    removeBlacklistRule(
        emailAddress: string,
        deletingAddress: string,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .deleteWithBody$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.REMOVE_BLACKLIST_RULE(emailAddress), {
                Address: deletingAddress,
            })
            .subscribe((responseData: any) => callback(responseData));
    }

    removeArchiveRule(
        emailAddress: string,
        deletingAddress: string,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .deleteWithBody$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.REMOVE_ARCHIVE_RULE(emailAddress), {
                Address: deletingAddress,
            })
            .subscribe((responseData: any) => callback(responseData));
    }

    updateReporting(
        organisationId: string,
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.UPDATE_REPORTING(organisationId, emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    updateEndUserReporting$(
        primaryMailboxAddress: string,
        payload: { Settings: IReportingSetting[]; ApplyToAliases: boolean }
    ): Observable<any> {
        return this._authorizedHttpService
            .post$(
                API_ENDPOINTS.END_USER.UPDATE_ENDUSER_REPORTING(primaryMailboxAddress),
                payload
            )
            .pipe(
                this._authorizedHttpService.handleApiErrorOperator$,
                this._authorizedHttpService.mapResponseOperator$<unknown>()
            );
    }

    updateGroupManager(
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.UPDATE_GROUP_MANAGER(emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    updatePermission(
        emailAddress: string,
        payload: any,
        callback: (responseData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.UPDATE_PERMISSION(emailAddress), payload)
            .subscribe((responseData: any) => callback(responseData));
    }

    uploadWhitelistRuleFile(
        payload: any,
        success: (calbackData: any) => void,
        fail: () => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.UPLOAD_WHITELIST_RULES_FILE, payload)
            .subscribe(
                (successResponse: any) => success(successResponse),
                () => fail()
            );
    }

    uploadBlacklistRuleFile(
        payload: any,
        success: (callbackData: any) => void,
        fail: () => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DISTRIBUTION_GROUP.UPLOAD_BLACKLIST_RULES_FILE, payload)
            .subscribe(
                (successResponse: any) => success(successResponse),
                () => fail()
            );
    }
}
