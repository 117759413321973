import { Injectable } from '@angular/core';
import { Permission } from '@cloudmarket/permissions-contract';
import { AddButtonExcludedServices } from 'app/new-shared/config/add-button-excluded-services';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { PermissionsService } from '../../core/services/permissions.service';
import { IServiceCustomerSchema } from '../../pages/services/interfaces/service-customer-schema.interface';
import { IServiceCustomer } from '../../pages/services/interfaces/service-customer.interface';
import { IServiceCustomers } from '../../pages/services/interfaces/service-customers.interface';
import { ServicesListFacadeService } from '../../pages/services/services/services-list-facade.service';
import {
    ButtonAction,
    ButtonStyle,
    ICloudMarketButton,
} from '../../shared/interfaces/cm-button.types';
import { IExecutionResult } from '../interfaces/execution-result.interface';
import { IService } from '../interfaces/service.interface';

import { ApiMyServicesService } from './api/api-my-services.service';

@Injectable()
export class ServicesCustomersListFacadeService {
    private _fetchRequestsCount: number = 0;
    private readonly _fetchRequestsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readonly _isFetchInProgress$: Observable<boolean> =
        this._generateFetchInProgressGetter$();
    private readonly _selectedCustomerSchema$: Observable<IServiceCustomerSchema | null> =
        this._generateSelectedCustomerSchemaGetter$();
    private readonly _addCustomerButton$: Observable<ICloudMarketButton | null> =
        this._generateAddCustomerButtonGetter$();
    private readonly _serviceCustomers$: Observable<IServiceCustomers | null> =
        this._generateServiceCustomersGetter$();
    private readonly _selectedCustomerId$: BehaviorSubject<string | null> = new BehaviorSubject<
        string | null
    >(null);
    private readonly _selectedCustomer$: Observable<IServiceCustomer | null> =
        this._generateCustomerByIdGetter$();

    constructor(
        private _servicesListFacadeService: ServicesListFacadeService,
        private _apiMyServicesService: ApiMyServicesService,
        private _permissionsService: PermissionsService
    ) {}

    getServiceCustomers$(): Observable<IServiceCustomers | null> {
        return this._serviceCustomers$;
    }

    getSelectedCustomer$(): Observable<IServiceCustomer | null> {
        return this._selectedCustomer$;
    }

    getSelectedCustomerSchema$(): Observable<IServiceCustomerSchema | null> {
        return this._selectedCustomerSchema$;
    }

    getAddCustomerButton$(): Observable<ICloudMarketButton | null> {
        return this._addCustomerButton$;
    }

    getIsFetchInProgress$(): Observable<boolean> {
        return this._isFetchInProgress$;
    }

    // this should be called only from services.service.ts, will work if called from other places
    // but wont take care of clearing following up selections
    selectCustomerViaId(customerId: string | null): void {
        this._selectedCustomerId$.next(customerId);
    }

    private _generateServiceCustomersGetter$(): Observable<IServiceCustomers | null> {
        return this._servicesListFacadeService.getSelectedService$().pipe(
            switchMap((selectedService: IService | null) => {
                if (!selectedService) {
                    return of(null);
                }

                return this._fetchServiceCustomers$(selectedService.Id);
            }),
            shareReplay()
        );
    }

    private _fetchServiceCustomers$(serviceId: number): Observable<IServiceCustomers | null> {
        return of(true).pipe(
            switchMap(() =>
                this._applyCounterToHttpRequest$(
                    this._apiMyServicesService.getServiceCustomers$(serviceId)
                ).pipe(
                    map((res: IExecutionResult<IServiceCustomers>) => {
                        if (!res.Succeed) {
                            return null;
                        }

                        return res.Result;
                    })
                )
            )
        );
    }

    private _generateCustomerByIdGetter$(): Observable<IServiceCustomer | null> {
        return combineLatest([this._serviceCustomers$, this._selectedCustomerId$]).pipe(
            map(
                ([customersResponse, selectedCustomerId]: [
                    IServiceCustomers | null,
                    string | null
                ]) =>
                    (customersResponse?.Customers || []).find(
                        (customer: IServiceCustomer) =>
                            customer.OrganisationId === selectedCustomerId
                    ) || null
            )
        );
    }

    private _generateAddCustomerButtonGetter$(): Observable<ICloudMarketButton | null> {
        const permissionValidate$: Observable<boolean> = of(
            this._permissionsService.hasPermission(Permission.addOrEditCustomers)
        );

        return permissionValidate$.pipe(
            filter(Boolean),
            switchMap(() =>
                this._servicesListFacadeService.getSelectedService$().pipe(
                    map((selectedService: IService | null) => {
                        if (!selectedService || AddButtonExcludedServices.includes(selectedService.Name)) {
                            return null;
                        }

                        return {
                            Action: ButtonAction.popUp,
                            Style: ButtonStyle.add,
                            Text: '',
                            DataTestId: 'add_selected_product',
                            Data: {
                                PopUp: 'list-customer',
                                Payload: {
                                    id: selectedService.Id,
                                    serviceId: selectedService.ServiceId,
                                    subId: selectedService.GroupId,
                                    newJourney: selectedService.NewJourney,
                                    journeyType: selectedService.JourneyType,
                                    listingId: selectedService.ListingId,
                                },
                            },
                        };
                    })
                )
            )
        );
    }

    private _generateSelectedCustomerSchemaGetter$(): Observable<IServiceCustomerSchema | null> {
        return this._servicesListFacadeService.getSelectedService$().pipe(
            switchMap((selectedService: IService | null) => {
                if (!selectedService) {
                    return of(null);
                }

                return this._applyCounterToHttpRequest$(
                    this._apiMyServicesService.getServiceCustomerSchema$(
                        selectedService.CloudMarketId
                    )
                ).pipe(
                    map((customersSchemaResponse: IExecutionResult<IServiceCustomerSchema>) => {
                        if (!customersSchemaResponse.Succeed) {
                            return of(null);
                        }

                        return customersSchemaResponse.Result;
                    })
                );
            }),
            shareReplay()
        );
    }

    private _applyCounterToHttpRequest$<T>(httpRequest$: Observable<T>): Observable<T> {
        return of(true).pipe(
            tap(() => this._increaseFetchCounter()),
            switchMap(() => httpRequest$),
            finalize(() => this._decreaseFetchCounter())
        );
    }

    private _generateFetchInProgressGetter$(): Observable<boolean> {
        return combineLatest([
            this._servicesListFacadeService.getIsFetchInProgress$(),
            this._servicesListFacadeService.getSelectedService$(),
            this._fetchRequestsCount$.pipe(map((requestsCount: number) => requestsCount > 0)),
        ]).pipe(
            map(
                ([isServiceFetchInProgress, selectedService, isCustomersFetchRequestInProgress]: [
                    boolean,
                    IService,
                    boolean
                ]) => {
                    if (!selectedService) {
                        return isServiceFetchInProgress;
                    }

                    return isServiceFetchInProgress || isCustomersFetchRequestInProgress;
                }
            )
        );
    }

    private _increaseFetchCounter(): void {
        this._fetchRequestsCount += 1;
        this._fetchRequestsCount$.next(this._fetchRequestsCount);
    }

    private _decreaseFetchCounter(): void {
        this._fetchRequestsCount -= 1;
        this._fetchRequestsCount$.next(this._fetchRequestsCount);
    }
}
