import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { merge, Observable, Subject } from 'rxjs';
import { filter, pairwise, takeUntil, tap } from 'rxjs/operators';

import { WINDOW } from './core/injection-tokens/window.injection-token';
import { IWindow } from './core/interfaces/window.interface';
import { BrowserService } from './core/services/browser/browser.service';
import { ProductFruitsService } from './core/services/external tools/product-fruits.service';
import { GlobalDataService } from './core/services/global-data.service';
import { GlobalSpinnerService } from './core/services/global-spinner/global-spinner.service';
import { MonitoringService } from './core/services/monitoring.service';
import { WindowScrollService } from './core/services/window-scroll.service';
import { ZuoraHostedPageService } from './core/services/zuora-hosted-page.service';
import { AuthService } from './feature-modules/auth/services/auth/auth.service';
import { KnowledgeBaseService } from './feature-modules/knowledge-base/services/knowledge-base/knowledge-base.service';
import { WaitSpinnerVariant } from './new-shared/components/wait-spinner/wait-spinner-variant';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    showGlobalSpinner: boolean = false;
    readonly spinnerVariant: typeof WaitSpinnerVariant = WaitSpinnerVariant;
    private readonly _destroyTrigger$: Subject<void> = new Subject();

    get showKB(): boolean {
        return this._knowledgeBaseService.displayKnowledgeBase;
    }

    constructor(
        @Inject(WINDOW) private _window: IWindow,
        private _authService: AuthService,
        private _globalSpinnerService: GlobalSpinnerService,
        private _browserService: BrowserService,
        private _elementRef: ElementRef,
        private _globalDataService: GlobalDataService,
        private _knowledgeBaseService: KnowledgeBaseService,
        private _monitoringService: MonitoringService,
        private _productFruitsService: ProductFruitsService,
        private _renderer2: Renderer2,
        private _router: Router,
        private _translateService: TranslateService,
        private _windowScrolling: WindowScrollService,
        private _zuoraHostedPageService: ZuoraHostedPageService
    ) {
        this._translateService.addLangs(['en']);
        this._translateService.setDefaultLang('en');
        this._translateService.use('en');
        setTheme('bs4');
    }

    ngOnInit(): void {
        this._initGlobalSpinner();
        const navigationStart$: Observable<NavigationStart> = this._router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationStart),
            tap((event: NavigationStart) => this._handleNavigationStart(event))
        );
        const navigationEnd$: Observable<NavigationEnd> = this._router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationEnd),
            tap((event: NavigationEnd) => this._handleNavigationEnd(event))
        );

        this._productFruitsService.initPF(localStorage.getItem('username'));

        this._renderer2.addClass(
            this._elementRef.nativeElement,
            this._browserService.getCurrentBrowser()
        );

        merge(navigationStart$, navigationEnd$)
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationStart),
                pairwise(),
                takeUntil(this._destroyTrigger$)
            )
            .subscribe((navigationStartEvents: [NavigationStart, NavigationStart]) => {
                if (navigationStartEvents.length <= 0) {
                    return;
                }

                this._globalDataService.setData('navigateUrl', navigationStartEvents[0].url);
                this._windowScrolling.enable();
            });

        this._zuoraHostedPageService.addZuoraScript();
    }

    ngOnDestroy(): void {
        this._destroyTrigger$.next();
        this._destroyTrigger$.complete();
    }

    isAuthenticated(): boolean {
        return !!this._authService.getToken();
    }

    private _initGlobalSpinner(): void{
        this._globalSpinnerService.getShowSpinnerValue$()
            .pipe(takeUntil(this._destroyTrigger$))
            .subscribe((showGlobalSpinner: boolean) => {
                this.showGlobalSpinner = showGlobalSpinner
            })
    }

    private _handleNavigationStart(event: NavigationStart): void {
        this._monitoringService.startNavigationEvent(event.url);
    }

    private _handleNavigationEnd(event: NavigationEnd): void {
        this._monitoringService.endNavigationEvent(event.url);
        this._monitoringService.logPageView();
        this._window.hj('stateChange', event.urlAfterRedirects);
    }
}
