import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import JwtDecode from 'jwt-decode';

import { Resource, UserInfo } from '../../../../../core/services/resource.service';
import { STORAGE_KEYS } from '../../../../../shared/constants/storage-keys.constants';
import { UserType } from '../../../../../shared/enum/user-type.enum';
import { IAuthorizeResponse } from '../../../interfaces/authorize-response.interface';
import { ILoginUserResponse } from '../../../interfaces/login-user-response.interface';
import { SharedSessionCookieStorageService } from '../../cookie/shared-session-cookie-storage.service';


@Injectable({
    providedIn: 'root',
})
export class LoginStorageService {
    private _jwtDecode: (token: string) => { exp: number, RESELLER_CURRENCY_CODE: string, RESELLER_SOURCE: string  } = JwtDecode;
    constructor(
        protected _router: Router,
        protected _resource: Resource,

        private _sharedSessionCookieStorageService: SharedSessionCookieStorageService
    ) {}

    resetLocalStorage(): void {
        localStorage.clear();
        this._sharedSessionCookieStorageService.removeCloudToken();
    }

    setupAuthorizeLocalStorage(authorizeResponse: IAuthorizeResponse): void {
        const claims : any = this._jwtDecode(authorizeResponse.access_token);
        localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, authorizeResponse.refresh_token);
        localStorage.setItem(STORAGE_KEYS.CURRENCY_CODE, claims.RESELLER_CURRENCY_CODE);
        localStorage.setItem(STORAGE_KEYS.RESELLER_SOURCE, claims.RESELLER_SOURCE);
        this._sharedSessionCookieStorageService.setCloudToken(
            authorizeResponse.access_token,
            authorizeResponse['.expires']
        );
    }

    setUserLoginDataInLocalStorage(loginUserResponse: ILoginUserResponse): void {
        this._setUserResourceInLocalStorage(loginUserResponse);
        this._setUserLocalStorage(loginUserResponse);
    }

    setOrganisationIdUserResourceInLocalStorage(organisationId?: string): void {
        this._resource.beginUpdateUser((storage: UserInfo) => {
            storage.endUserAdminId = organisationId;
        });
        localStorage.setItem(STORAGE_KEYS.CUSTOMER_ADMIN_CLOUD_ORGANISATION_ID, organisationId);
    }

    private _setUserLocalStorage(loginUserResponse: ILoginUserResponse): void {
        localStorage.setItem(STORAGE_KEYS.VISITOR_ID, loginUserResponse.mex);
        localStorage.setItem(STORAGE_KEYS.USER_REF, loginUserResponse.gaUserReference);
        localStorage.setItem(STORAGE_KEYS.USER_NAME, loginUserResponse.userName);
        localStorage.setItem(STORAGE_KEYS.EMAIL_ADDRESS, loginUserResponse.emailAddress);
        localStorage.setItem(STORAGE_KEYS.RESELLER_CLOUD_MARKET_ID, loginUserResponse.resellerCloudMarketId);
        localStorage.setItem(STORAGE_KEYS.DISPLAY_NAME, loginUserResponse.displayName);
        localStorage.setItem(STORAGE_KEYS.END_USER_ADMIN, loginUserResponse.endUserAdmin.toString());
        localStorage.setItem(STORAGE_KEYS.USERTYPE, loginUserResponse.userType);
        this._setAdminType(loginUserResponse.userType);
    }

    private _setUserResourceInLocalStorage(loginUserResponse: ILoginUserResponse): void {
        this._resource.beginUpdateUser((storage: UserInfo) => {
            storage.isEndUserAdmin = loginUserResponse.endUserAdmin;
            storage.userName = loginUserResponse.userName;
            storage.userType = loginUserResponse.userType;
            storage.isEndUser = loginUserResponse.isEndUser;
            storage.emailAddress = loginUserResponse.emailAddress;
            storage.cloudMarketId = loginUserResponse.userCloudMarketId;
        });
    }

    private _setAdminType(userType: string): void {
        if (userType === UserType.reseller || userType === UserType.engineer) {
            localStorage.setItem(STORAGE_KEYS.USER_TYPE, UserType.admin);
        } else if (userType === UserType.endUserAdmin) {
            localStorage.setItem(STORAGE_KEYS.USER_TYPE, UserType.endUserAdmin.toLowerCase());
        } else {
            localStorage.setItem(STORAGE_KEYS.USER_TYPE, UserType.endUser.toLowerCase());
        }
    }
}
