import { ComponentFactoryResolver, Directive, ViewContainerRef } from '@angular/core';

import { PopupService } from '../services/popup.service';

@Directive({
    selector: '[popupContainer]',
})
export class PopupContainerDirective {
    constructor(
        private _viewContainerRef: ViewContainerRef,
        private _componentFactoryResolver: ComponentFactoryResolver
    ) {
        PopupService.container = this._viewContainerRef;
        PopupService.componentFactoryResolver = this._componentFactoryResolver;
    }
}
