import { Component, OnDestroy, OnInit } from '@angular/core';

import { IExecutionResult } from '../../../core/interfaces/execution-result.interface';
import { AuthorizedHttpService } from '../../../core/services/http/authorized-http.service';
import { API_ENDPOINTS } from '../../constants/api/api-endpoints.constants';
import { HttpResponseCode } from '../../enum/http-response-code.enum';

export interface IDropzoneNotificationModel {
    Id: any;
    Added: any;
    UserReference: string;
    ServiceId: any;
    ServiceName: string;
    Message: string;
    Success: boolean;
    Read: boolean;
}

@Component({
    selector: 'giacom-notification',
    templateUrl: './notification.component.html',
})
export class NotificationComponent implements OnInit, OnDestroy {
    notificationItems: IDropzoneNotificationModel[];
    refreshSub: any;
    loadHandler: any;
    isClosingNotification: boolean = false;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    limitNumber: number = 3;

    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    ngOnInit(): void {
        this._authorizedHttpService
            .get$(API_ENDPOINTS.NOTIFICATIONS.GET_CONFIG_RELOAD_NOTIFICATION_TIME)
            .subscribe((result: IExecutionResult<number>) => {
                this.loadHandler = setInterval(() => {
                    this.loadData();
                }, result.Result);
            });
    }

    loadData(): void {
        this._authorizedHttpService
            .get$(API_ENDPOINTS.NOTIFICATIONS.GET_DROPZONE_NOTIFICATIONS)
            .subscribe(
                (result: IExecutionResult<IDropzoneNotificationModel[]>) => {
                    this.notificationItems = result.Result;
                },
                (err: any) => {
                    if (err.status === HttpResponseCode.forbidden) {
                        clearInterval(this.loadHandler);
                    }
                }
            );
    }

    ngOnDestroy(): void {
        if (this.loadHandler) {
            clearInterval(this.loadHandler);
        }
    }

    closeNotification(item: any): void {
        this.isClosingNotification = true;
        this._authorizedHttpService
            .post$(API_ENDPOINTS.NOTIFICATIONS.CLOSE_NOTIFICATION, item)
            .subscribe((data: IExecutionResult<boolean>) => {
                if (data.Result) {
                    this.isClosingNotification = false;
                    this.loadData();
                }
            });
    }

    showMore(): void {
        const defaultLimit: number = 3;

        if (this.limitNumber === defaultLimit) {
            this.limitNumber = this.notificationItems.length;
        } else {
            this.limitNumber = defaultLimit;
        }
    }

    getText(): string {
        const defaultLimit: number = 3;

        if (this.limitNumber === defaultLimit) {
            return 'Show more';
        }

        return 'Show less';
    }
}
