import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'newlineHtml',
})
export class NewLineHtmlPipe implements PipeTransform {
    transform(html: string): string {
        return html.replace(/(?:\r\n|\r|\n)/g, '<br><br>');
    }
}
