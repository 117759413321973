import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { CmInputValidationModule } from './components/input-validation/cm-input-validation.module';
import { MultiselectChipsContainerComponent } from './components/multiselect-chips-container/multiselect-chips-container.component';
import { CmMultiselectInputComponent } from './components/multiselect-input/cm-multiselect-input.component';
import { CmQtyCounterInputComponent } from './components/qty-counter-input/cm-qty-counter-input.component';
import { CmRadioInputComponent } from './components/radio-input/cm-radio-input.component';
import { CmRadiosInputComponent } from './components/radios-input/cm-radios-input.component';
import { CmRatingRadiosInputComponent } from './components/rating-radios-input/cm-rating-radios-input.component';
import { CmTextInputComponent } from './components/text-input/cm-text-input.component';
import { DecimalPrecisionDirective } from './directives/decimal-precision.directive';
import { DigitsOnlyDirective } from './directives/digits-only.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TypeaheadModule,
        AngularSvgIconModule,
        TranslateModule,
        PopoverModule,
        CmInputValidationModule,
    ],
    declarations: [
        CmMultiselectInputComponent,
        CmQtyCounterInputComponent,
        CmRadioInputComponent,
        CmRadiosInputComponent,
        CmRatingRadiosInputComponent,
        CmTextInputComponent,
        DigitsOnlyDirective,
        DecimalPrecisionDirective,
        MultiselectChipsContainerComponent,
    ],
    exports: [
        CmMultiselectInputComponent,
        CmQtyCounterInputComponent,
        CmRadioInputComponent,
        CmRadiosInputComponent,
        CmRatingRadiosInputComponent,
        CmTextInputComponent,
        DigitsOnlyDirective,
        DecimalPrecisionDirective,
        MultiselectChipsContainerComponent,
    ],
})
export class CmFormsModule {}
