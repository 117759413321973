import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { REGEX_CONTENT_DISPOSITION_HEADER } from '../../../shared/constants/regexp/regex.constants';
import { IDictionary } from '../../../shared/interfaces/dictionary.interface';
import { IExecutionResult } from '../../interfaces/execution-result.interface';

export abstract class AbstractHttpService {
    protected readonly _shouldSendCredentials: boolean = true;

    protected constructor(protected _httpClient: HttpClient) {}

    get$<T>(
        url: string,
        params: IDictionary<string | string[]> = {},
        headers: IDictionary<string> = {}
    ): Observable<T> {
        return this._normalizeErrorResponse$(
            this._httpClient.get<T>(url, {
                params,
                headers: {
                    ...this._getDefaultHeaders(),
                    ...headers,
                },
                withCredentials: this._shouldSendCredentials,
            })
        );
    }

    post$<T>(url: string, body: any, headers: IDictionary<string> = {}): Observable<T> {
        return this._normalizeErrorResponse$(
            this._httpClient.post<T>(url, body, {
                headers: {
                    ...this._getDefaultHeaders(),
                    ...headers,
                },
                withCredentials: this._shouldSendCredentials,
            })
        );
    }

    patch$<T>(url: string, body: any): Observable<T> {
        return this._normalizeErrorResponse$(
            this._httpClient.patch<T>(url, body, {
                headers: this._getDefaultHeaders(),
                withCredentials: this._shouldSendCredentials,
            })
        );
    }

    put$<T>(url: string, body: any): Observable<T> {
        return this._normalizeErrorResponse$(
            this._httpClient.put<T>(url, body, {
                headers: this._getDefaultHeaders(),
                withCredentials: this._shouldSendCredentials,
            })
        );
    }

    head$<T>(url: string): Observable<T> {
        return this._normalizeErrorResponse$(
            this._httpClient.head<T>(url, {
                headers: this._getDefaultHeaders(),
                withCredentials: this._shouldSendCredentials,
            })
        );
    }

    delete$<T>(url: string, params: any = {}): Observable<T> {
        return this._normalizeErrorResponse$(
            this._httpClient.delete<T>(url, {
                params,
                headers: {
                    ...this._getDefaultHeaders(),
                    'Content-Type': 'application/json',
                },
                withCredentials: this._shouldSendCredentials,
            })
        );
    }

    /**
     * @deprecated
     * @param url Api url to remove the resource
     * @param body Data needed for the resource to be deleted,
     * passed as a request body which isnt done according to RESTful api docs (should use query
     *     params instead)
     * @returns Observable which after subscription will provide deletion result
     */
    deleteWithBody$<T>(url: string, body: any = {}): Observable<T> {
        return this._normalizeErrorResponse$(
            this._httpClient.request<T>('delete', url, {
                body,
                headers: {
                    ...this._getDefaultHeaders(),
                    'Content-Type': 'application/json',
                },
                withCredentials: this._shouldSendCredentials,
            })
        );
    }

    downloadBlobAsFile$(
        url: string,
        fileName: string = '',
        params: IDictionary<string | string[]> = {}
    ): Observable<HttpResponse<Blob>> {
        return this._httpClient
            .get<Blob>(url, {
                params,
                headers: this._getDefaultHeaders(),
                observe: 'response' as any,
                responseType: 'blob' as any,
                withCredentials: this._shouldSendCredentials,
            })
            .pipe(
                tap((httpResponse: HttpResponse<Blob>) => {
                    const fileNameFromHeaders: string = this._getFileNameFromHeaders(
                        httpResponse.headers
                    );
                    const finalFileName: string = fileName || fileNameFromHeaders || 'file';

                    this.downloadFromBlob(httpResponse.body, finalFileName);
                })
            );
    }

    downloadFromBlob(blob: Blob, fileName: string): void {
        const delayBeforeRemoveAnchor: number = 1000;
        const fileURL: string = window.URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a');
        const isSafariBrowser: boolean =
            navigator.userAgent.indexOf('Safari') !== -1 &&
            navigator.userAgent.indexOf('Chrome') === -1;

        // if Safari open in new window to save file with random filename.
        if (isSafariBrowser) {
            a.setAttribute('target', '_blank');
        }
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);

            return;
        }
        if (window.navigator.userAgent.match('CriOS')) {
            // Chrome iOS
            const reader: FileReader = new FileReader();

            reader.onloadend = () => {
                window.open(reader.result.toString());
            };
            reader.readAsDataURL(blob);

            return;
        }
        if (
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/iPhone/i)
        ) {
            // Safari & Opera iOS
            window.location.href = fileURL;

            return;
        }

        document.body.appendChild(a);

        a.setAttribute('href', fileURL);
        a.setAttribute('target', '__blank');
        a.setAttribute('download', fileName);
        a.click();

        // need this timeout because without it files have names based on url
        setTimeout(() => {
            window.URL.revokeObjectURL(fileURL);
            a.remove();
        }, delayBeforeRemoveAnchor);
    }

    handleApiErrorOperator$(
        source$: Observable<IExecutionResult<unknown>>
    ): Observable<IExecutionResult<unknown>> {
        return source$.pipe(
            switchMap((response: IExecutionResult<unknown>) => {
                if (response.Succeed) {
                    return of(response);
                }

                return throwError(response);
            })
        );
    }

    mapResponseOperator$<T = unknown>(): (
        source$: Observable<IExecutionResult<T>>
    ) => Observable<T> {
        return (source$: Observable<IExecutionResult<T>>): Observable<T> =>
            source$.pipe(map((response: IExecutionResult<T>) => response.Result));
    }

    protected abstract _getDefaultHeaders(): IDictionary<string | string[]>;

    private _normalizeErrorResponse$<T>(response$: Observable<T>): Observable<T> {
        return response$.pipe(
            catchError((errorResponse: HttpErrorResponse) => throwError(errorResponse.error))
        );
    }

    private _getFileNameFromHeaders(headers: HttpHeaders): string {
        const xFileName: string = headers.get('x-filename');
        const exportFileName: string = headers.get('export-file-name');
        const exportFileExtension: string = headers.get('export-file-extension');
        const exportFileNameWithExtension: string = exportFileName
            ? exportFileExtension
                ? `${exportFileName}.${exportFileExtension}`
                : exportFileName
            : '';
        const contentDispositionFileName: string = this._getFileNameFromContentDisposition(headers);

        // Order of items is important due to backward compatibility
        return xFileName || exportFileNameWithExtension || contentDispositionFileName || '';
    }

    private _getFileNameFromContentDisposition(headers: HttpHeaders): string {
        const contentDispositionHeader: string = headers.get('content-disposition');

        if (!contentDispositionHeader) {
            return '';
        }

        // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
        // Standard for specifying attachment names
        // Expected header value with format similar to:
        // attachment; filename=myfile.pdf; filename*=UTF-8''myfile.pdf
        // filename value might be enclosed in double quotes, filename* has priority over filename
        let fileNameHigherPriority: string = '';
        let fileNameLowerPriority: string = '';

        const parts: string[] = contentDispositionHeader.split(';');

        parts.forEach((part: string) => {
            let match: RegExpMatchArray = part.match(
                REGEX_CONTENT_DISPOSITION_HEADER.fileNameNoStarPart
            );

            if (match) {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                fileNameLowerPriority = match[2];

                return;
            }

            match = part.match(REGEX_CONTENT_DISPOSITION_HEADER.fileNameWithStarPart);
            if (match) {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                fileNameHigherPriority = match[2];

                return;
            }
        });

        return fileNameHigherPriority || fileNameLowerPriority || '';
    }
}
