import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import {
    IBannedExtensionModel
} from '../../../new-shared/components/modals/edit-domain-email-security/interfaces/banned-extension-model.types';
import {
    IArchiveLevel,
    IBackscatterFilteringSystem,
    IBlacklistRule,
    IDeliveryDetails,
    IJournalingSettings,
    IVirusNotification,
    IWhitelistRule,
} from '../../../new-shared/components/modals/edit-domain-email-security/interfaces/domain-email-security.types';
import { API_ENDPOINTS } from '../../../shared/constants/api/api-endpoints.constants';
import { IBannedExtensionItem } from '../../interfaces/banned-extension-item.interface';
import { IBannedExtensionsSettings } from '../../interfaces/banned-extensions-setting.interface';
import { IEmailSecurityDomain } from '../../interfaces/email-security-domain.interface';
import { IEmailsEntryRule } from '../../interfaces/emails-entry-rule.interface';
import { IExecutionResult } from '../../interfaces/execution-result.interface';
import { ISpamLevelModel } from '../../interfaces/spam-level-model.interface';
import { AuthorizedHttpService } from '../http/authorized-http.service';

@Injectable({
    providedIn: 'root',
})
export class ApiDomainService {
    constructor(private _authorizedHttpService: AuthorizedHttpService) {}

    // TODO: check if used, might be removable
    list(organisationId: string, data: any, callback: (callbackData: any) => void): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DOMAIN.GET_DOMAINS(organisationId), data)
            .subscribe((response: any) => callback(response));
    }

    getAccessInfo(organisationId: string, callback: (callbackData: any) => void): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DOMAIN.GET_ACCESS_INFO(organisationId))
            .subscribe((responseData: any) => callback(responseData));
    }

    getUpdateInfo(
        organisationId: string,
        domainRef: string,
        callback: (callbackData: any) => void
    ): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DOMAIN.GET_UPDATE_INFO(organisationId, domainRef))
            .subscribe((responseData: any) => callback(responseData));
    }

    addDomain(
        organisationId: string,
        data: IEmailSecurityDomain,
        callback: (callbackData: IExecutionResult<void>) => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DOMAIN.ADD_DOMAIN(organisationId), data)
            .subscribe((responseData: IExecutionResult<void>) => callback(responseData));
    }

    updateSpamLevel(
        domainRef: string,
        data: ISpamLevelModel,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_SPAM_LEVEL(domainRef), data)
            .subscribe((responseData: IExecutionResult<null>) => callback(responseData));
    }

    updateArchiveLevel(
        domainRef: string,
        data: IArchiveLevel,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_ARCHIVE_LEVEL(domainRef), data)
            .subscribe((responseData: IExecutionResult<null>) => callback(responseData));
    }

    updateVirusNotificaion(
        domainRef: string,
        data: IVirusNotification,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_VIRUS_NOTIFICATION(domainRef), data)
            .subscribe((responseData: null) => callback(responseData));
    }

    getWhitelistRules(
        domainRef: string,
        callback: (callbackData: IExecutionResult<IEmailsEntryRule[]>) => void
    ): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DOMAIN.GET_WHITELIST_RULES(domainRef))
            .subscribe((responseData: IExecutionResult<IEmailsEntryRule[]>) =>
                callback(responseData)
            );
    }

    getBlacklistRules(
        domainRef: string,
        callback: (callbackData: IExecutionResult<IEmailsEntryRule[]>) => void
    ): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DOMAIN.GET_BLACKLIST_RULES(domainRef))
            .subscribe((responseData: IExecutionResult<IEmailsEntryRule[]>) =>
                callback(responseData)
            );
    }

    addWhitelistRules(
        domainRef: string,
        data: IWhitelistRule,
        callback: (callbackData: IExecutionResult<IEmailsEntryRule>) => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DOMAIN.ADD_WHITELIST_RULES(domainRef), data)
            .subscribe((responseData: IExecutionResult<IEmailsEntryRule>) =>
                callback(responseData)
            );
    }

    addBlacklistRules(
        domainRef: string,
        data: IBlacklistRule,
        callback: (callbackData: IExecutionResult<IEmailsEntryRule>) => void
    ): Subscription {
        return this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DOMAIN.ADD_BLACKLIST_RULES(domainRef), data)
            .subscribe((responseData: IExecutionResult<IEmailsEntryRule>) =>
                callback(responseData)
            );
    }

    removeWhitelistRule(
        domainRef: string,
        deletingAddress: string,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .deleteWithBody$(API_ENDPOINTS.ES_DOMAIN.REMOVE_WHITELIST_RULE(domainRef), {
                Address: deletingAddress,
            })
            .subscribe((responseData: null) => callback(responseData));
    }

    removeBlacklistRule(
        domainRef: string,
        deletingAddress: string,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .deleteWithBody$(API_ENDPOINTS.ES_DOMAIN.REMOVE_BLACKLIST_RULE(domainRef), {
                Address: deletingAddress,
            })
            .subscribe((responseData: null) => callback(responseData));
    }

    getBannedExtensions(
        organisationId: string,
        domainRef: string,
        callback: (callbackData: IExecutionResult<IBannedExtensionModel>) => void
    ): Subscription {
        return this._authorizedHttpService
            .get$(API_ENDPOINTS.ES_DOMAIN.GET_BANNED_EXTENSIONS(organisationId, domainRef))
            .subscribe((responseData: IExecutionResult<IBannedExtensionModel>) =>
                callback(responseData)
            );
    }

    updateBannedExtensions(
        data: IBannedExtensionModel,
        callback: (callbackData: IExecutionResult<IBannedExtensionsSettings>) => void
    ): void {
        this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_BANNED_EXTENSIONS, data)
            .subscribe((responseData: IExecutionResult<IBannedExtensionsSettings>) =>
                callback(responseData)
            );
    }

    addExtension(
        data: IBannedExtensionModel,
        callback: (callbackData: IExecutionResult<IBannedExtensionItem[]>) => void
    ): void {
        this._authorizedHttpService
            .post$(API_ENDPOINTS.ES_DOMAIN.ADD_EXTENSION, data)
            .subscribe((responseData: IExecutionResult<null>) => callback(responseData));
    }

    updateInboundDeliveryDetails(
        domainRef: string,
        data: IDeliveryDetails,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_INBOUND_DELIVERY(domainRef), data)
            .subscribe((responseData: IExecutionResult<null>) => callback(responseData));
    }

    updateOutboundSMTPPassword(
        domainRef: string,
        data: IDeliveryDetails,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_OUTBOUND_SMTP(domainRef), data)
            .subscribe((responseData: IExecutionResult<null>) => callback(responseData));
    }

    updateExchangeJournalingFeed(
        domainRef: string,
        data: IJournalingSettings,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_EXCHANGE_JOURNALING_FEED(domainRef), data)
            .subscribe((responseData: IExecutionResult<null>) => callback(responseData));
    }

    updateBackscatterFilteringSystem(
        domainRef: string,
        data: IBackscatterFilteringSystem,
        callback: (callbackData: IExecutionResult<null>) => void
    ): Subscription {
        return this._authorizedHttpService
            .put$(API_ENDPOINTS.ES_DOMAIN.UPDATE_BACKSCATTER_FILTERING_SYSTEM(domainRef), data)
            .subscribe((responseData: IExecutionResult<null>) => callback(responseData));
    }

    uploadWhitelistRuleFile(
        data: FormData,
        success: (data: IExecutionResult<null>) => void,
        fail: () => void
    ): Subscription {
        return this._authorizedHttpService.post$(API_ENDPOINTS.ES_DOMAIN.UPLOAD_WHITELIST_RULES_FILE, data).subscribe(
            (result: IExecutionResult<null>) => success(result),
            () => fail()
        );
    }

    uploadBlacklistRuleFile(
        data: FormData,
        success: (data: IExecutionResult<null>) => void,
        fail: () => void
    ): Subscription {
        return this._authorizedHttpService.post$(API_ENDPOINTS.ES_DOMAIN.UPLOAD_BLACKLIST_RULES_FILE, data).subscribe(
            (result: IExecutionResult<null>) => success(result),
            () => fail()
        );
    }
}
