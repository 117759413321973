import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IChangePasswordModel, ResetPasswordStateModel } from 'app/models/user/password.model';

import { IValidationRule } from '../../../../new-shared/components/password/validation-rule.interface';
import { HttpResponseCode } from '../../../../shared/enum/http-response-code.enum';
import { ButtonVariant } from '../../../../shared/interfaces/cm-button.types';
import { PasswordService } from '../../../../shared/services/password.service';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    // eslint-disable-next-linerelative-url-prefix
    styleUrls: ['../auth.scss'],
})
export class ChangePasswordComponent implements OnInit {
    resetPasswordKey: string;
    resetPasswordFormGroup: UntypedFormGroup;
    passwordFormControl: UntypedFormControl;
    currentPasswordVisible: boolean = false;
    passwordValidationRules: IValidationRule[];
    passwordState: ResetPasswordStateModel;
    saving: boolean = false;
    recentError: string | null = null;
    readonly buttonVariant: typeof ButtonVariant = ButtonVariant;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _passwordService: PasswordService
    ) {}

    ngOnInit(): void {
        this.passwordState = this._passwordService.getPasswordState();

        this._activatedRoute.params.subscribe((params: Params) => {
            if (params.username && params.GUID && params.usertype) {
                this.resetPasswordKey = params.GUID;
                this.passwordState = new ResetPasswordStateModel(
                    params.GUID,
                    params.username,
                    params.usertype
                );
            }

            if (!this.passwordState) {
                this._router.navigate(['login']);
            }

            this.passwordFormControl = new UntypedFormControl('');
            this.resetPasswordFormGroup = new UntypedFormGroup({
                existingPassword: new UntypedFormControl(
                    '',
                    this.resetPasswordKey ? [] : [Validators.required]
                ),
                newPassword: this.passwordFormControl,
            });
        });

        this._passwordService.getValidationRules$().subscribe((rules: IValidationRule[]) => {
            this.passwordValidationRules = rules;
            this.setPasswordValidationRules(this.passwordValidationRules);
        });
    }

    setPasswordValidationRules(validationRules: IValidationRule[]): void {
        const ngRules: ValidatorFn[] = this._passwordService.buildValidationRules(validationRules);

        this.passwordFormControl.setValidators(ngRules);
    }

    showPassword(): void {
        this.currentPasswordVisible = !this.currentPasswordVisible;
    }

    onSubmit(): void {
        this.recentError = null;
        this.saving = true;
        const model: IChangePasswordModel = {
            key: this.resetPasswordKey,
            userName: this.passwordState.userName,
            userType: this.passwordState.userType,
            existingPassword: this.resetPasswordKey
                ? null
                : this.resetPasswordFormGroup.get('existingPassword').value,
            newPassword: this.resetPasswordFormGroup.get('newPassword').value,
        };

        this._passwordService.changePassword$(model).subscribe(
            () => {
                this._passwordService.clearState();
                this._router.navigate(['login']);
            },
            (error: HttpErrorResponse) => {
                this.saving = false;

                if (
                    error.status === HttpResponseCode.unauthorized &&
                    error.statusText === 'Unauthorized'
                ) {
                    this.recentError = 'Current password was incorrect.';
                } else if (error.status === HttpResponseCode.badRequest) {
                    this.recentError = 'Password does not meet requirements';
                } else {
                    throw error;
                }
            }
        );
    }
}
