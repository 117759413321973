import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SynchronizationStatus } from 'app/pages/billing/psa/enums/synchronization-status.enum';
import { BehaviorSubject, ConnectableObservable, Observable, Subject } from 'rxjs';
import { map, publish, tap } from 'rxjs/operators';

import { IExecutionResult } from '../../core/interfaces/execution-result.interface';
import { ApiFeatureFlagsService } from '../../core/services/api/api-feature-flags.service';
import { ApiPsaService } from '../../core/services/api/api-psa.service';
import { AuthorizedHttpService } from '../../core/services/http/authorized-http.service';
import { MatchSelectorPipe } from '../../pages/billing/pipes/match-selector.pipe';
import { FilterCustomerOption } from '../../pages/billing/psa/enums/filter-customer-option.enum';
import { ReportTypeEnum } from '../../pages/billing/psa/enums/report-type.enum';
import { IIntegrationAzureChargeUpdateItem } from '../../pages/billing/psa/interfaces/azure/integration-azure-charge-update.interface';
import {
    IntegrationProductMovementResponse,
    IntegrationProductMovementResponseItem,
    IntegrationReconciliationResponseItem,
} from '../../pages/billing/psa/interfaces/integration-report.models';
import {
    IPsaItem,
    IPsaOption,
} from '../../pages/billing/psa/interfaces/match-selector.types';
import { ISynchronizationStatus } from '../../pages/billing/psa/interfaces/sync/synchronization-status.interface';
import { ISynchronizedData } from '../../pages/billing/psa/interfaces/sync/synchronized-data.interface';
import {
    IPSADataCacheInfo,
    IUpdateIgnoreSalesOrdersModel,
    PSADataSyncStatus,
    PSADataSyncTriggerType,
    PsaIntegration,
    PsaIntegrationSettings,
} from '../../pages/your-account/integration/integration.models';
import { API_ENDPOINTS } from '../constants/api/api-endpoints.constants';
import { IPsaUpdateNotification } from '../interfaces/psa-update-notification.interface';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';

@Injectable()
export class IntegrationResellerPsaService {
    private set _setFilteredMatchCustomers(value: IPsaItem[]) {
        this.filteredMatchCustomers$.next(value);
    }

    private get _getMatchCustomers(): IPsaItem[] {
        return this._matchCustomers;
    }

    private set _setMatchCustomers(value: IPsaItem[]) {
        this._matchCustomers = value;
        this.matchCustomers$.next(value);
    }

    private get _getMatchPsaCustomers(): IPsaOption[] {
        return this._matchPsaCustomers;
    }

    private set _setMatchPsaCustomers(value: IPsaOption[]) {
        this._matchPsaCustomers = value;
        this.matchPsaCustomers$.next(value);
    }

    private get _getSelectedPsaCustomerOptions(): IPsaOption[] {
        return this._selectedPsaCustomerOptions;
    }

    private set _setFilteredMatchPsaServices(value: IPsaItem[]) {
        this.filteredMatchPsaServices$.next(value);
    }

    private get _getMatchServices(): IPsaOption[] {
        return this._matchServices;
    }

    private set _setMatchServices(value: IPsaOption[]) {
        this._matchServices = value;
        this.matchServices$.next(value);
    }

    private get _getMatchPsaServices(): IPsaItem[] {
        return this._matchPsaServices;
    }

    private set _setMatchPsaServices(value: IPsaItem[]) {
        this._matchPsaServices = value;
        this.matchPsaServices$.next(value);
    }

    private get _getSelectedCmServiceOptions(): IPsaOption[] {
        return this._selectedCmServiceOptions;
    }

    private get _getProductMovementData(): IntegrationProductMovementResponseItem[] {
        return this._productMovementData;
    }

    private set _setProductMovementData(value: IntegrationProductMovementResponseItem[]) {
        this._productMovementData = value;
        this.productMovementData$.next(value);
    }

    private set _setFilteredProductMovementData(value: IntegrationProductMovementResponseItem[]) {
        this.filteredProductMovementData$.next(value);
    }

    private get _getReconciliationData(): IntegrationReconciliationResponseItem[] {
        return this._reconciliationData;
    }

    private set _setReconciliationData(value: IntegrationReconciliationResponseItem[]) {
        this._reconciliationData = value;
        this.reconciliationData$.next(value);
    }

    private set _setFilteredReconciliationData(value: IntegrationReconciliationResponseItem[]) {
        this.filteredReconciliationData$.next(value);
    }

    get integrationSettings(): PsaIntegrationSettings {
        return this._integrationSettings;
    }

    set integrationSettings(integrationSettings: PsaIntegrationSettings) {
        this._integrationSettings = integrationSettings;
        this.integrationSettings$.next(integrationSettings);
    }

    psaDataSyncEvent$: Observable<string>;
    currentReportEvent$: Observable<ReportTypeEnum>;
    integrationSettings$: BehaviorSubject<PsaIntegrationSettings> =
        new BehaviorSubject<PsaIntegrationSettings>(null);
    originalMatchCustomers: IPsaItem[];
    filteredMatchCustomers$: BehaviorSubject<IPsaItem[]> = new BehaviorSubject<IPsaItem[]>([]);
    matchCustomers$: BehaviorSubject<IPsaItem[]> = new BehaviorSubject<IPsaItem[]>([]);
    matchPsaCustomers$: BehaviorSubject<IPsaOption[]> = new BehaviorSubject<IPsaOption[]>([]);
    matchCustomersSynchronizationStatus$: BehaviorSubject<ISynchronizationStatus> =
        new BehaviorSubject<ISynchronizationStatus>(null);
    isCustomerSaveEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    originalMatchPsaServices: IPsaItem[];
    filteredMatchPsaServices$: BehaviorSubject<IPsaItem[]> = new BehaviorSubject<IPsaItem[]>([]);
    matchServices$: BehaviorSubject<IPsaOption[]> = new BehaviorSubject<IPsaOption[]>([]);
    matchPsaServices$: BehaviorSubject<IPsaItem[]> = new BehaviorSubject<IPsaItem[]>([]);
    matchServicesSynchronizationStatus$: BehaviorSubject<ISynchronizationStatus> =
        new BehaviorSubject<ISynchronizationStatus>(null);
    isServiceSaveEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    originalMovement: IntegrationProductMovementResponseItem[];
    productMovementData$: BehaviorSubject<IntegrationProductMovementResponseItem[]> =
        new BehaviorSubject<IntegrationProductMovementResponseItem[]>([]);
    filteredProductMovementData$: BehaviorSubject<IntegrationProductMovementResponseItem[]> =
        new BehaviorSubject<IntegrationProductMovementResponseItem[]>([]);
    productMovementSynchronizationStatus$: BehaviorSubject<ISynchronizationStatus> =
        new BehaviorSubject<ISynchronizationStatus>(null);
    productMovementStartDate: Date;
    productMovementEndDate: Date;
    originalReconciliation: IntegrationReconciliationResponseItem[];
    reconciliationData$: BehaviorSubject<IntegrationReconciliationResponseItem[]> =
        new BehaviorSubject<IntegrationReconciliationResponseItem[]>([]);
    filteredReconciliationData$: BehaviorSubject<IntegrationReconciliationResponseItem[]> =
        new BehaviorSubject<IntegrationReconciliationResponseItem[]>([]);
    reconciliationSynchronizationStatus$: BehaviorSubject<ISynchronizationStatus> =
        new BehaviorSubject<ISynchronizationStatus>(null);
    private _psaDataSyncEvent$: Subject<string> = new Subject<string>();
    private _currentReportEvent$: Subject<ReportTypeEnum> = new Subject<ReportTypeEnum>();
    private _matchCustomers: IPsaItem[];
    private _matchPsaCustomers: IPsaOption[];
    private _selectedPsaCustomerOptions: IPsaOption[] = [];
    private _customerUpdateNotification$: BehaviorSubject<IPsaUpdateNotification> =
        new BehaviorSubject<IPsaUpdateNotification>(null);
    private _matchServices: IPsaOption[];
    private _matchPsaServices: IPsaItem[];
    private _selectedCmServiceOptions: IPsaOption[] = [];
    private _serviceUpdateNotification$: BehaviorSubject<IPsaUpdateNotification> =
        new BehaviorSubject<IPsaUpdateNotification>(null);
    private _productMovementData: IntegrationProductMovementResponseItem[];
    private _movementUpdateNotification$: BehaviorSubject<IPsaUpdateNotification> =
        new BehaviorSubject<IPsaUpdateNotification>(null);
    private _reconciliationData: IntegrationReconciliationResponseItem[];
    private _reconciliationUpdateNotification$: BehaviorSubject<IPsaUpdateNotification> =
        new BehaviorSubject<IPsaUpdateNotification>(null);
    private _integrationSettings: PsaIntegrationSettings = new PsaIntegrationSettings();
    private _integrationSettingsEmitter$: BehaviorSubject<PsaIntegrationSettings> =
        new BehaviorSubject<PsaIntegrationSettings>(null);
    private _updatedCustomers: IPsaItem[] = [];
    private _updatedServices: IPsaItem[] = [];

    constructor(
        private _matchSelectorPipe: MatchSelectorPipe,
        private _apiFeatureFlagService: ApiFeatureFlagsService,
        private _movementSearchFilterPipe: SearchFilterPipe<IntegrationProductMovementResponseItem>,
        private _reconciliationSearchFilterPipe: SearchFilterPipe<IntegrationReconciliationResponseItem>,
        private _authorizedHttpService: AuthorizedHttpService,
        private _translateService: TranslateService,
        private _apiService: ApiPsaService
    ) {
        this.psaDataSyncEvent$ = this._psaDataSyncEvent$.asObservable();
        this.currentReportEvent$ = this._currentReportEvent$.asObservable();
    }

    getCustomersData(): Promise<void> {
        return new Promise((resolve: () => void) => {
            this._apiService
                .getCustomers$()
                .subscribe((data: IExecutionResult<ISynchronizedData<IPsaItem[]>>) => {
                    const sortedResult: IPsaItem[] = this.cloneArray(
                        this.sortAlphabetically(data.Result.response, 'name')
                    );

                    this._setMatchCustomers = sortedResult;
                    this.matchCustomersSynchronizationStatus$.next(data.Result.synchronization);
                    this.originalMatchCustomers = this.cloneArray(sortedResult);

                    resolve();
                });
        });
    }

    refreshCustomers$(): Observable<boolean> {
        const refresh$: Observable<boolean> = this._apiService
            .refreshCustomers$()
            .pipe(map((result: IExecutionResult<void>) => result.Succeed));

        return refresh$;
    }

    refreshServices$(): Observable<boolean> {
        const refresh$: Observable<boolean> = this._apiService
            .refreshServices$()
            .pipe(map((result: IExecutionResult<void>) => result.Succeed));

        return refresh$;
    }

    refreshProductMovement$(): Observable<boolean> {
        const refresh$: Observable<boolean> = this._apiService
            .refreshProductMovement$()
            .pipe(map((result: IExecutionResult<void>) => result.Succeed));

        return refresh$;
    }

    refreshReconciliation$(): Observable<boolean> {
        const refresh$: Observable<boolean> = this._apiService
            .refreshReconciliation$()
            .pipe(map((result: IExecutionResult<void>) => result.Succeed));

        return refresh$;
    }

    refreshAzure$(): Observable<boolean> {
        const refresh$: Observable<boolean> = this._apiService
            .refreshAzure$()
            .pipe(map((result: IExecutionResult<void>) => result.Succeed));

        return refresh$;
    }

    // TODO: remove promises and use observables instead
    async getPsaCustomersData(): Promise<void> {
        const result: IExecutionResult<ISynchronizedData<IPsaOption[]>> = await this._apiService
            .getPsaCustomers$()
            .toPromise();

        if (result.Succeed && result.Result.response.length > 0) {
            this._setMatchPsaCustomers = this.sortAlphabetically(result.Result.response, 'name');

            return;
        }

        this._setMatchPsaCustomers = [];
        this._customerUpdateNotification$.next({
            message: 'Customers failed to load from PSA',
            class: 'error',
            icon: 'times',
            errors: null,
        });
    }

    async getServicesData(): Promise<void> {
        const result: IExecutionResult<ISynchronizedData<IPsaOption[]>> = await this._apiService
            .getServices$()
            .toPromise();

        this._setMatchServices = this.sortAlphabetically(result.Result.response, 'name');
    }

    async getPsaServicesData(): Promise<void> {
        const result: IExecutionResult<ISynchronizedData<IPsaItem[]>> = await this._apiService
            .getPsaServices$()
            .toPromise();

        if (result.Succeed) {
            this.matchServicesSynchronizationStatus$.next(result.Result.synchronization);
        }

        if (result.Succeed && result.Result.response.length > 0) {
            const sortedResult: IPsaItem[] = this.sortAlphabetically(
                result.Result.response,
                'name'
            );

            this._setMatchPsaServices = sortedResult;
            this.originalMatchPsaServices = this.cloneArray(sortedResult);
        } else {
            this._setMatchPsaServices = [];
            this._serviceUpdateNotification$.next({
                message: 'Services failed to load from PSA',
                class: 'error',
                icon: 'times',
                errors: null,
            });
        }
    }

    sortAlphabetically<T>(array: T[] | undefined, key: string): T[] {
        if (array) {
            return array.sort((a: T, b: T) => a[key].localeCompare(b[key]));
        }
    }

    async getProductMovementData(selectedMonth: Date): Promise<void> {
        const psaV2Flag: boolean = this._apiFeatureFlagService.isFeatureFlagEnabled('PSA-V2');

        if (!psaV2Flag) {
            return;
        }

        const result: IExecutionResult<ISynchronizedData<IntegrationProductMovementResponse>> =
            await this._apiService.getProductMovement$(selectedMonth).toPromise();

        this._setProductMovementData = result.Result.response.results;
        this.originalMovement = this.cloneArray(result.Result.response.results);
        this.productMovementStartDate = result.Result.response.reportStartDate;
        this.productMovementEndDate = result.Result.response.reportEndDate;
        this.productMovementSynchronizationStatus$.next(result.Result.synchronization);
    }

    async getBillingDateRange(monthsToSubtract: number): Promise<Date[]> {
        const psaV2Flag: boolean = this._apiFeatureFlagService.isFeatureFlagEnabled('PSA-V2');

        if (!psaV2Flag) {
            return;
        }

        const result: IExecutionResult<Date[]> = await this._apiService
            .getProductMovementDateRange$(monthsToSubtract)
            .toPromise();

        return result.Result.map((date: Date) => new Date(date));
    }

    async getReconciliationData(): Promise<void> {
        const psaV2Flag: boolean = this._apiFeatureFlagService.isFeatureFlagEnabled('PSA-V2');

        if (!psaV2Flag) {
            return;
        }

        const result: IExecutionResult<ISynchronizedData<IntegrationReconciliationResponseItem[]>> =
            await this._apiService.getReconciliationReport$().toPromise();

        if (
            result.Result.synchronization.status === SynchronizationStatus.completed ||
            result.Result.synchronization.status === SynchronizationStatus.unknown
        ) {
            this._setReconciliationData = result.Result.response;
            this.originalReconciliation = this.cloneArray(result.Result.response);
        }
        this.reconciliationSynchronizationStatus$.next(result.Result.synchronization);
    }

    getFilteredMatchCustomers$(): BehaviorSubject<IPsaItem[]> {
        return this.filteredMatchCustomers$;
    }

    getCustomerUpdateNotification$(): BehaviorSubject<IPsaUpdateNotification> {
        return this._customerUpdateNotification$;
    }

    getMatchServices$(): BehaviorSubject<IPsaOption[]> {
        return this.matchServices$;
    }

    getServiceUpdateNotification$(): BehaviorSubject<IPsaUpdateNotification> {
        return this._serviceUpdateNotification$;
    }

    getMatchPsaCustomers$(): BehaviorSubject<IPsaOption[]> {
        return this.matchPsaCustomers$;
    }

    getFilteredMatchPsaServices$(): BehaviorSubject<IPsaItem[]> {
        return this.filteredMatchPsaServices$;
    }

    getFilteredProductMovementActions$(): BehaviorSubject<
        IntegrationProductMovementResponseItem[]
    > {
        return this.filteredProductMovementData$;
    }

    getFilteredReconciliationActions$(): BehaviorSubject<IntegrationReconciliationResponseItem[]> {
        return this.filteredReconciliationData$;
    }

    getMovementUpdateNotification$(): BehaviorSubject<IPsaUpdateNotification> {
        return this._movementUpdateNotification$;
    }

    getReconUpdateNotification$(): BehaviorSubject<IPsaUpdateNotification> {
        return this._reconciliationUpdateNotification$;
    }

    hasCustomers(): boolean {
        return !!this._getMatchCustomers && !!this._getMatchPsaCustomers;
    }

    hasServices(): boolean {
        return !!this._getMatchServices && !!this._getMatchPsaServices;
    }

    hasProductMovementData(): boolean {
        return !!this._getProductMovementData;
    }

    hasReconciliationData(): boolean {
        return !!this._getReconciliationData;
    }

    removeSelectedCustomersFromPsaCustomers(): void {
        this.removeSelectedPsaOptionsFromPsaItem(
            this._getMatchCustomers,
            this._getMatchPsaCustomers,
            this._getSelectedCmServiceOptions
        );
    }

    removeSelectedPsaOptionsFromPsaItem(
        items: IPsaItem[],
        options: IPsaOption[],
        selectedOptions: IPsaOption[]
    ): void {
        const matchItemsSelectedOption: IPsaOption[] = items.map(
            (item: IPsaItem) => item.selectedOption
        );

        options.forEach((option: IPsaOption, index: number) => {
            const itemIndex: number = matchItemsSelectedOption
                .map((optionToMap: IPsaOption) =>
                    optionToMap && optionToMap.id ? optionToMap.id : -1
                )
                .indexOf(option.id);

            if (itemIndex === -1) {
                return;
            }

            const currentSelectedOptions: IPsaOption[] = selectedOptions || [];

            // eslint-disable-next-line no-param-reassign
            selectedOptions = [...currentSelectedOptions, items[itemIndex].selectedOption];
            options.splice(index, 1);
        });
    }

    updateMatchCustomers$(): Observable<IExecutionResult<boolean>> {
        return this._apiService.updateCustomers$(this._getMatchCustomers).pipe(
            tap(
                () => {
                    this.originalMatchCustomers = this.cloneArray(this._getMatchCustomers);
                    this._customerUpdateNotification$.next({
                        message: 'Customers updated successfully',
                        class: 'success',
                        icon: 'check',
                        errors: null,
                    });
                },
                (err: Error) => {
                    // Do something
                    this._customerUpdateNotification$.next({
                        message: 'Customers failed to update',
                        class: 'error',
                        icon: 'times',
                        errors: null,
                    });
                }
            )
        );
    }

    updateCustomers$(customers: IPsaItem[]): Observable<IExecutionResult<boolean>> {
        return this._apiService.updateCustomers$(customers).pipe(
            tap(
                () => {
                    this.originalMatchCustomers = this.cloneArray(customers);
                    this._setMatchCustomers = customers;

                    this._customerUpdateNotification$.next({
                        message: 'Customers updated successfully',
                        class: 'success',
                        icon: 'check',
                        errors: null,
                    });
                },
                (_err: Error) => {
                    this._customerUpdateNotification$.next({
                        message: 'Customers failed to update',
                        class: 'error',
                        icon: 'times',
                        errors: null,
                    });
                }
            )
        );
    }

    updateServices$(services: IPsaItem[]): Observable<IExecutionResult<boolean>> {
        return this._apiService.updateProducts$(services).pipe(
            tap(
                () => {
                    this.originalMatchPsaServices = this.cloneArray(services);
                    this._setMatchPsaServices = services;

                    this._serviceUpdateNotification$.next({
                        message: 'Services updated successfully',
                        class: 'success',
                        icon: 'check',
                        errors: null,
                    });
                },
                (err: Error) => {
                    // Do something
                    this._serviceUpdateNotification$.next({
                        message: 'Changes failed to save',
                        class: 'error',
                        icon: 'times',
                        errors: null,
                    });
                }
            )
        );
    }

    assignSelectedCustomerNameToCustomer(): void {
        this._getMatchCustomers?.forEach((customer: IPsaItem) => {
            if (!customer.selectedOption || customer.selectedOption.name) {
                return;
            }

            const matchedOption: IPsaOption = this._getMatchPsaCustomers.find(
                (option: IPsaOption) => option.id === customer.selectedOption.id
            );

            if (matchedOption) {
                customer.selectedOption.name = matchedOption.name;
            } else {
                customer.selectedOption = null;
            }
        });
    }

    updateMatchServices$(): Observable<IExecutionResult<boolean>> {
        return this._apiService.updateProducts$(this._getMatchPsaServices).pipe(
            tap(
                () => {
                    this.originalMatchPsaServices = this.cloneArray(this._getMatchPsaServices);
                    this._serviceUpdateNotification$.next({
                        message: 'Services updated successfully',
                        class: 'success',
                        icon: 'check',
                        errors: null,
                    });
                },
                (err: Error) => {
                    // Do something
                    this._serviceUpdateNotification$.next({
                        message: 'Changes failed to save',
                        class: 'error',
                        icon: 'times',
                        errors: null,
                    });
                }
            )
        );
    }

    cancelMatchCustomer(): void {
        this._setMatchCustomers = this.cloneArray(this.originalMatchCustomers);
    }

    cancelMatchService(): void {
        this._setMatchPsaServices = this.cloneArray(this.originalMatchPsaServices);
    }

    resetCustomers(): void {
        this._setMatchCustomers = null;
        this._setMatchPsaCustomers = null;
    }

    resetServices(): void {
        this._setMatchServices = null;
        this._setMatchPsaServices = null;
        this._setFilteredMatchPsaServices = null;
        this.matchServicesSynchronizationStatus$.next(null);
    }

    resetMovement(): void {
        this._setFilteredProductMovementData = null;
        this._setProductMovementData = null;
    }

    resetReconciliation(): void {
        this._setFilteredReconciliationData = null;
        this._setReconciliationData = null;
    }

    resetReconciliationUpdateNotifications(): void {
        this._reconciliationUpdateNotification$.next(null);
    }

    resetProductMovementUpdateNotifications(): void {
        this._movementUpdateNotification$.next(null);
    }

    resetCustomersNotifications(): void {
        this._customerUpdateNotification$.next(null);
    }

    customerHasChanged(): void {
        this.isCustomerSaveEnabled$.next(
            JSON.stringify(this._getMatchCustomers) !== JSON.stringify(this.originalMatchCustomers)
        );
        // eslint-disable-next-line rxjs/no-subject-value
        if (!this.isCustomerSaveEnabled$.value) {
            this.resetCustomersNotifications();

            return;
        }

        this._customerUpdateNotification$.next({
            message: 'You have unsaved changes',
            class: 'error',
            icon: 'times',
            errors: null,
        });
    }

    resetServicesNotifications(): void {
        this._serviceUpdateNotification$.next(null);
    }

    serviceHasChanged(): void {
        this.isServiceSaveEnabled$.next(
            JSON.stringify(this._getMatchPsaServices) !==
                JSON.stringify(this.originalMatchPsaServices)
        );
        // eslint-disable-next-line rxjs/no-subject-value
        if (!this.isServiceSaveEnabled$.value) {
            this.resetServicesNotifications();

            return;
        }

        this._serviceUpdateNotification$.next({
            message: 'You have unsaved changes',
            class: 'error',
            icon: 'times',
            errors: null,
        });
    }

    assignPsaToCMCustomers(selectedPsaOption: IPsaOption, selectedCustomer: IPsaItem): void {
        this.assignOptionToItem(
            selectedPsaOption,
            selectedCustomer,
            this._getSelectedPsaCustomerOptions,
            this._getMatchPsaCustomers,
            true
        );
    }

    unassignPsaFromCMCustomers(cmCustomer: IPsaItem): void {
        const selectedOptionIndex: number = this._getSelectedPsaCustomerOptions.indexOf(
            cmCustomer.selectedOption
        );

        this._getSelectedPsaCustomerOptions.splice(selectedOptionIndex, 1);
        // add psa to matchPsaCustomers

        const currentMatchPsaCustomers: IPsaOption[] = this._getMatchPsaCustomers;

        this._setMatchPsaCustomers = this.sortAlphabetically(
            [...currentMatchPsaCustomers, cmCustomer.selectedOption],
            'name'
        );

        // null cmCustomer.selectedOptions
        cmCustomer.selectedOption = null;
    }

    assignCMToPsaServices(selectedCmOption: IPsaOption, selectedService: IPsaItem): void {
        this.assignOptionToItem(
            selectedCmOption,
            selectedService,
            this._getSelectedCmServiceOptions,
            this._getMatchServices,
            false
        );
    }

    assignOptionToItem(
        option: IPsaOption,
        item: IPsaItem,
        selectedOptions: IPsaOption[],
        items: IPsaOption[],
        deleteOption: boolean
    ): void {
        if (option === null) {
            return;
        }

        item.selectedOption = option;
        this.addOptionToSelectedOptions(selectedOptions, option);
        if (deleteOption) {
            this.removeOptionFromAvailableOptions(option, items);
        }
    }

    addOptionToSelectedOptions(selectedOptions: IPsaOption[], option: IPsaOption): void {
        selectedOptions.push(option);
    }

    removeOptionFromAvailableOptions(option: IPsaOption, availableOptions: IPsaOption[]): void {
        const optionIndex: number = availableOptions.indexOf(option);

        availableOptions.splice(optionIndex, 1);
    }

    ignoreCustomer(item: IPsaItem): void {
        const customerIndex: number = this._getMatchCustomers.indexOf(item);

        this._getMatchCustomers[customerIndex].ignored =
            !this._getMatchCustomers[customerIndex].ignored;
    }

    resetCustomersUpdate(): void {
        this._updatedCustomers = [];
    }

    resetServicesUpdate(): void {
        this._updatedServices = [];
    }

    handleCustomerUpdate(id: string, ignored: boolean, selectedOption: IPsaOption): IPsaItem[] {
        const updatedCustomer: IPsaItem = {
            ...this._getMatchCustomers.find((customer: IPsaItem) => customer.id === id),
            selectedOption,
            ignored,
        };

        this._updatedCustomers = [updatedCustomer, ...this._updatedCustomers];

        const updatedCustomers: IPsaItem[] = this._updatedCustomers.filter(
            (customer: IPsaItem, index: number, customers: IPsaItem[]) =>
                customers.findIndex((c: IPsaItem) => c.id === customer.id) === index
        );

        return [...updatedCustomers, ...this._getMatchCustomers].filter(
            (customer: IPsaItem, index: number, customers: IPsaItem[]) =>
                customers.findIndex((c: IPsaItem) => c.id === customer.id) === index
        );
    }

    handleServiceUpdate(id: string, ignored: boolean, selectedOption: IPsaOption): IPsaItem[] {
        const updatedService: IPsaItem = {
            ...this._getMatchPsaServices.find((customer: IPsaItem) => customer.id === id),
            selectedOption,
            ignored,
        };

        this._updatedServices = [updatedService, ...this._updatedServices];

        const updatedServices: IPsaItem[] = this._updatedServices.filter(
            (service: IPsaItem, index: number, services: IPsaItem[]) =>
                services.findIndex((s: IPsaItem) => s.id === service.id) === index
        );

        return [...updatedServices, ...this._getMatchPsaServices].filter(
            (service: IPsaItem, index: number, services: IPsaItem[]) =>
                services.findIndex((s: IPsaItem) => s.id === service.id) === index
        );
    }

    unassignCmFromPsaServices(cmCustomer: IPsaItem): void {
        const selectedOptionIndex: number = this._getSelectedCmServiceOptions.indexOf(
            cmCustomer.selectedOption
        );

        this._getSelectedCmServiceOptions.splice(selectedOptionIndex, 1);

        cmCustomer.selectedOption = null;
    }

    pushSelectedProductMovementChanges$(
        data: IntegrationProductMovementResponseItem[]
    ): ConnectableObservable<IExecutionResult<void>> {
        const updateProductMovementReportRequest$: ConnectableObservable<IExecutionResult<void>> =
            this._apiService.updateProductMovement$(data).pipe(publish()) as ConnectableObservable<
                IExecutionResult<void>
            >;

        updateProductMovementReportRequest$.subscribe(
            (res: IExecutionResult<void>) => {
                if (res.Succeed) {
                    this._sendPushSelectedProductMovementChangesMessage({
                        message: this._translateService.instant(
                            'BILLING.INTEGRATION.PSA_PRODUCT_MOVEMENT_UPDATE_SUCCESS_MESSAGE'
                        ),
                        errors: res.Errors,
                        class: 'success',
                        icon: 'check',
                    });
                } else {
                    this._sendPushSelectedProductMovementChangesMessage({
                        message: res.Error
                            ? this._translateService.instant(
                                  'BILLING.INTEGRATION.PSA_PRODUCT_MOVEMENT_UPDATE_FAILURE_WITH_ERROR_MESSAGE',
                                  { error: res.Error }
                              )
                            : this._translateService.instant(
                                  'BILLING.INTEGRATION.PSA_PRODUCT_MOVEMENT_UPDATE_FAILURE_MESSAGE'
                              ),
                        errors: res.Errors,
                        class: 'error',
                        icon: 'times',
                    });
                }
            },
            (_err: Error) => {
                this._sendPushSelectedProductMovementChangesMessage({
                    message: this._translateService.instant(
                        'BILLING.INTEGRATION.PSA_PRODUCT_MOVEMENT_UPDATE_FAILURE_MESSAGE'
                    ),
                    errors: null,
                    class: 'error',
                    icon: 'times',
                });
            }
        );

        return updateProductMovementReportRequest$;
    }

    pushSelectedReconciliationChanges$(
        data: IntegrationReconciliationResponseItem[]
    ): ConnectableObservable<IExecutionResult<void>> {
        const updateReconciliationReportRequest$: ConnectableObservable<IExecutionResult<void>> =
            this._apiService.updateReconciliation$(data).pipe(publish()) as ConnectableObservable<
                IExecutionResult<void>
            >;

        updateReconciliationReportRequest$.subscribe(
            (res: IExecutionResult<void>) => {
                if (res.Succeed) {
                    this._sendPushSelectedReconciliationChangesMessage({
                        message: this._translateService.instant(
                            'BILLING.INTEGRATION.PSA_RECON_UPDATE_SUCCESS_MESSAGE'
                        ),
                        class: 'success',
                        icon: 'check',
                        errors: null,
                    });
                } else {
                    this._sendPushSelectedReconciliationChangesMessage({
                        message: res.Error
                            ? this._translateService.instant(
                                  'BILLING.INTEGRATION.PSA_RECON_UPDATE_FAILURE_WITH_ERROR_MESSAGE',
                                  { error: res.Error }
                              )
                            : this._translateService.instant(
                                  'BILLING.INTEGRATION.PSA_RECON_UPDATE_FAILURE_MESSAGE'
                              ),
                        class: 'error',
                        icon: 'times',
                        errors: null,
                    });
                }
            },
            (_err: Error) => {
                this._sendPushSelectedReconciliationChangesMessage({
                    message: this._translateService.instant(
                        'BILLING.INTEGRATION.PSA_RECON_UPDATE_FAILURE_MESSAGE'
                    ),
                    class: 'error',
                    icon: 'times',
                    errors: null,
                });
            }
        );

        return updateReconciliationReportRequest$;
    }

    pushSelectedAzureChanges$(
        data: IIntegrationAzureChargeUpdateItem[]
    ): ConnectableObservable<IExecutionResult<void>> {
        const updateReconciliationReportRequest$: ConnectableObservable<IExecutionResult<void>> =
            this._apiService.updateAzureCharges$(data).pipe(publish()) as ConnectableObservable<
                IExecutionResult<void>
            >;

        updateReconciliationReportRequest$.subscribe(
            (res: IExecutionResult<void>) => {
                if (res.Succeed) {
                    this._sendPushSelectedReconciliationChangesMessage({
                        message: this._translateService.instant(
                            'BILLING.INTEGRATION.PSA_RECON_UPDATE_SUCCESS_MESSAGE'
                        ),
                        class: 'success',
                        icon: 'check',
                        errors: null,
                    });
                } else {
                    this._sendPushSelectedReconciliationChangesMessage({
                        message: res.Error
                            ? this._translateService.instant(
                                  'BILLING.INTEGRATION.PSA_RECON_UPDATE_FAILURE_WITH_ERROR_MESSAGE',
                                  { error: res.Error }
                              )
                            : this._translateService.instant(
                                  'BILLING.INTEGRATION.PSA_RECON_UPDATE_FAILURE_MESSAGE'
                              ),
                        class: 'error',
                        icon: 'times',
                        errors: null,
                    });
                }
            },
            (_err: Error) => {
                this._sendPushSelectedReconciliationChangesMessage({
                    message: this._translateService.instant(
                        'BILLING.INTEGRATION.PSA_RECON_UPDATE_FAILURE_MESSAGE'
                    ),
                    class: 'error',
                    icon: 'times',
                    errors: null,
                });
            }
        );

        return updateReconciliationReportRequest$;
    }

    ignoreService(item: IPsaItem): void {
        const serviceIndex: number = this._getMatchPsaServices.indexOf(item);

        this._getMatchPsaServices[serviceIndex].ignored =
            !this._getMatchPsaServices[serviceIndex].ignored;
    }

    filterMatchCustomers(filterOption: string, filterString: string): void {
        this.matchCustomers$.subscribe((data: IPsaItem[]) => {
            this._setFilteredMatchCustomers = this.filterMatchItems(
                data,
                filterOption,
                filterString
            );
        });
    }

    filterMatchServices(filterOption: string, filterString: string): void {
        this.matchPsaServices$.subscribe((data: IPsaItem[]) => {
            this._setFilteredMatchPsaServices = this.filterMatchItems(
                data,
                filterOption,
                filterString
            );
        });
    }

    filterMatchItems(
        items: IPsaItem[],
        filterOption: string,
        filterString: string
    ): IPsaItem[] | null {
        let filteredResults: IPsaItem[];

        if (!items) {
            return null;
        }

        if (filterOption === FilterCustomerOption.match) {
            filteredResults = items.filter(
                (customer: IPsaItem) => customer.selectedOption && !customer.ignored
            );
        } else if (filterOption === FilterCustomerOption.notMatched) {
            filteredResults = items.filter(
                (customer: IPsaItem) => !customer.selectedOption && !customer.ignored
            );
        } else if (filterOption === FilterCustomerOption.ignored) {
            filteredResults = items.filter((customer: IPsaItem) => customer.ignored);
        } else {
            filteredResults = items;
        }

        if (filterString) {
            filteredResults = this._matchSelectorPipe.transform(filteredResults, filterString);
        }

        return filteredResults;
    }

    filterMovement(filterOption: string, filterString: string): void {
        let filteredResults: IntegrationProductMovementResponseItem[];

        this.productMovementData$.subscribe((data: IntegrationProductMovementResponseItem[]) => {
            if (!data) {
                filteredResults = null;
            } else if (filterOption === 'Selected') {
                filteredResults = data.filter(
                    (item: IntegrationProductMovementResponseItem) => item.selected
                );
            } else if (filterOption === 'Not selected') {
                filteredResults = data.filter(
                    (item: IntegrationProductMovementResponseItem) => !item.selected
                );
            } else {
                filteredResults = data;
            }

            if (!filterString && !data) {
                return;
            }

            filteredResults = this._movementSearchFilterPipe.transform(
                filteredResults,
                filterString,
                'cmCustomer.name'
            );
        });

        this._setFilteredProductMovementData = filteredResults;
    }

    filterReconciliation(filterOption: string, filterString: string): void {
        let filteredResults: IntegrationReconciliationResponseItem[];

        this.reconciliationData$.subscribe((data: IntegrationReconciliationResponseItem[]) => {
            if (!data) {
                filteredResults = null;
            } else if (filterOption === 'Selected') {
                filteredResults = data.filter(
                    (item: IntegrationReconciliationResponseItem) => item.selected
                );
            } else if (filterOption === 'Not selected') {
                filteredResults = data.filter(
                    (item: IntegrationReconciliationResponseItem) => !item.selected
                );
            } else {
                filteredResults = data;
            }

            if (!filterString || !data) {
                return;
            }

            filteredResults = this._reconciliationSearchFilterPipe.transform(
                filteredResults,
                filterString,
                'cmCustomer.name'
            );
        });

        this._setFilteredReconciliationData = filteredResults;
    }

    cloneArray<T>(array: T[]): T[] {
        return array.map((a: T) => {
            const clonedObj: T = {} as unknown as T;

            Object.keys(a).forEach(
                (propertyKey: string) => (clonedObj[propertyKey] = a[propertyKey])
            );

            return clonedObj;
        });
    }

    refreshData(): void {
        this._apiService.refreshData$().subscribe();
    }

    getAvailableIntegrations$(): Observable<IExecutionResult<PsaIntegration[]>> {
        return this._apiService.getAvailableIntegrations$();
    }

    getIntegrationSettings$(): Observable<IExecutionResult<PsaIntegrationSettings>> {
        return this._apiService.getSettings$();
    }

    updateIntegrationSettings$(data: PsaIntegrationSettings): Observable<IExecutionResult<void>> {
        return this._apiService.updateSettings$(data).pipe(
            map((res: IExecutionResult<void>) => {
                if (res.Succeed && data.enabled) {
                    // TODO: This should be moved to serverside
                    // Ideally, the serverside logic should initiate the caching upon save,
                    //  but with current implementation it will hold up the current request
                    // In future when the cache refresh is handled by a scheduler, this can be moved to server
                    this.refreshPSADataCache(PSADataSyncTriggerType.setup);
                    this._onIntegrationSettingsChange(data);
                }

                return res;
            })
        );
    }

    updateIgnoreSalesOrders$(
        model: IUpdateIgnoreSalesOrdersModel
    ): Observable<IExecutionResult<void>> {
        return this._apiService.updateIgnoreSalesOrders$(model);
    }

    getLatestPSADataCacheInfo$(): Observable<IExecutionResult<IPSADataCacheInfo>> {
        return this._authorizedHttpService.get$(API_ENDPOINTS.INTEGRATION.GET_LATEST_PSA_DATA_CACHE);
    }

    refreshPSADataCache(triggerType: string): void {
        if (!this.isPSADataCacheFeatureEnabled()) {
            return;
        }

        this._onPSADataSync(PSADataSyncStatus.inProgress);
        this._authorizedHttpService
            .post$(API_ENDPOINTS.INTEGRATION.REFRESH_PSA_DATA_CACHE, { PSADataCacheSyncTriggerType: triggerType })
            .subscribe(async (psaDataCacheInfo: IExecutionResult<IPSADataCacheInfo>) => {
                this._onPSADataSync(
                    psaDataCacheInfo && psaDataCacheInfo.Result
                        ? psaDataCacheInfo.Result.psaDataCacheSyncStatus
                        : PSADataSyncStatus.unKnown
                );
            });
    }

    isPSADataCacheFeatureEnabled(): boolean {
        return this._apiFeatureFlagService.isFeatureFlagEnabled('PSADataCaching');
    }

    emitCurrentReportChange(report: ReportTypeEnum): void {
        this._currentReportEvent$.next(report);
    }

    private _sendPushSelectedProductMovementChangesMessage(message: IPsaUpdateNotification): void {
        this._movementUpdateNotification$.next(message);
    }

    private _sendPushSelectedReconciliationChangesMessage(message: IPsaUpdateNotification): void {
        this._reconciliationUpdateNotification$.next(message);
    }

    private _onPSADataSync(status: string): void {
        this._psaDataSyncEvent$.next(status);
    }

    private _onIntegrationSettingsChange(settings: PsaIntegrationSettings): void {
        this._integrationSettingsEmitter$.next(settings);
    }
}
