import { PaymentType } from 'app/core/enums/payment-type.enum';

import { CardPaymentType } from '../../enum/card-payment-type.enum';
import {
    Office365ProductGroups,
    Service,
    ServiceJourneyMessageType,
} from '../../enum/service-type.enum';
import { StoredAuthorisationAction } from '../../enum/stored-authorisation-action.enum';
import { BaseUrl } from '../../urls/base.url';

import { PsaEndpoints } from './psa-endpoints.enum';

// TODO: move all endpoints urls here
export const API_ENDPOINTS = {
    ADD_SERVICES: {
        GET_UPDATED_USERS_LIST: `${BaseUrl.baseUrl}/api/AddServices/GetUpdatedUsersList`,
        ADD_SERVICE: (organisationId: string | number, serviceId: number) =>
            `${BaseUrl.baseUrl}/api/AddServices/${organisationId}/${serviceId}`,
        GET_ADD_SERVICE_REVIEW: (organisationId: string | number, serviceId: number) =>
            `${BaseUrl.baseUrl}/api/AddServices/${organisationId}/${serviceId}/Review`,
        GET_ADD_SERVICE_DATA_FOR_CUSTOMER: (
            organisationId: string | number,
            serviceId: number,
            mode: string
        ) => `${BaseUrl.baseUrl}/api/AddServices/${organisationId}/${serviceId}/${mode}/customer`,
        GET_ADD_SERVICE_DATA_FOR_USER: (
            organisationId: string | number,
            serviceId: number,
            mode: string,
            userId: string
        ) =>
            `${BaseUrl.baseUrl}/api/AddServices/${organisationId}/${serviceId}/${mode}/user/${userId}`,
        ADD_EXCLAIMER_FROM_OFFICE: (customerId: string) =>
            `${BaseUrl.baseUrl}/api/AddServices/${customerId}/AddExclaimerFromOffice`,
        GET_ADDITIONAL_PRODUCTS: (customerId: string) =>
            `${BaseUrl.baseUrl}/api/AddServices/${customerId}/GetAdditionalProducts`,
        PRODUCT_DEFINITIONS: (
            organisationId: string | number,
            serviceId: number,
            mode: string,
            level: string
        ) =>
            `${BaseUrl.baseUrl}/api/AddServices/${organisationId}/${serviceId}/${mode}/${level}/ProductDefinitions`,
        CHECK_PRODUCT_DEFINITIONS: (
            organisationId: string | number,
            serviceId: number,
            mode: string,
            level: string
        ) =>
            `${BaseUrl.baseUrl}/api/AddServices/${organisationId}/${serviceId}/${mode}/${level}/CheckProductDefinitions`,
    },
    ANNOUNCEMENT: {
        GET_ANNOUCEMENT: (announcementId: number) =>
            `${BaseUrl.baseUrl}/api/announcement/${announcementId}`,
        DO_NOT_SHOW_AGAIN: (announcementId: number) =>
            `${BaseUrl.baseUrl}/api/announcement/${announcementId}/DoNotShowAgain`,
        REMIND_LATER: (announcementId: number) =>
            `${BaseUrl.baseUrl}/api/announcement/${announcementId}/RemindLater`,
    },
    API_MANAGEMENT: {
        USER_V1: '/User/v1',
        USER_V2: '/User/v2',
    },
    APP_INTERACT: {
        CREATE: `${BaseUrl.baseUrl}/api/appinteract/create`,
        MODIFY: `${BaseUrl.baseUrl}/api/appinteract/modify`,
        GET_PRICES: `${BaseUrl.baseUrl}/api/appinteract/prices`,
        DELETE_APP_INTERACT_SERVICE: `${BaseUrl.baseUrl}/api/AppInteract/DeleteAppInteractService`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) => `${BaseUrl.baseUrl}/api/AppInteract/${id}`,
        GET_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/appinteract/${organisationId}/info`,
    },
    ACRONIS: {
        PORTAL_LINK: `${BaseUrl.baseUrl}/api/acronisfilescloud/portallink`,
        DELETE_ACRONIS_SERVICE_FOR_USER: `${BaseUrl.baseUrl}/api/acronisfilescloud`,
        CONSUMPTION_REPORTING: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisfilescloud/${organisationId}/ConsumptionReporting`,
        DELETE_ACRONIS_SERVICE: (organisationId: string | number, subId: string) =>
            `${BaseUrl.baseUrl}/api/acronisfilescloud/${organisationId}/Delete/${subId}/`,
        GET_USER_SERVICE_MODELS: (organisationId: string | number, subId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisfilescloud/${organisationId}/GetUserServiceModels/${subId}`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/acronisfilescloud/${id}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (organisationId: string, userId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisfilescloud/${organisationId}/${userId}/ProductsInfo`,
        GET_SETTINGS: (organisationId: string | number, userId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisfilescloud/${organisationId}/${userId}/Settings`,
        UPDATE_SETTINGS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisfilescloud/${organisationId}/Settings`,
    },
    ACRONIS_BACKUP: {
        CREATE_ACRONIS_BACKUP: `${BaseUrl.baseUrl}/api/AcronisBackup/`,
        UPDATE_ACRONIS_BACKUP: `${BaseUrl.baseUrl}/api/AcronisBackup/`,
        DELETE_ACRONIS_BACKUP_SERVICE_FOR_USER: `${BaseUrl.baseUrl}/api/acronisbackup`,
        GET_BACKUP_TYPES: `${BaseUrl.baseUrl}/api/AcronisBackup/BackupTypes/`,
        GET_REPORTING_LEVELS: `${BaseUrl.baseUrl}/api/AcronisBackup/ReportingLevels/`,
        GET_IS_BACKUP_SUFFIX_EXISTING: `${BaseUrl.baseUrl}/api/AcronisBackup/IsBackupSuffixExisting/`,
        GET_BACKUP: (logonEmail: string) => `${BaseUrl.baseUrl}/api/AcronisBackup/${logonEmail}/`,
        GET_USERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AcronisBackup/${organisationId}/Users`,
        GET_USERS_OF_PRODUCT: (organisationId: string | number, subId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisbackup/GetUsersOfProduct/${organisationId}/${subId}`,
        RESELLER_JUMP_TO_LINK: () => `${BaseUrl.baseUrl}/api/acronisbackup/resellerjumptolink`,
        RESELLER_JUMP_TO_USER_LINK: (id: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisbackup/${id}/resellerjumptouserlink`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/acronisbackup/${id}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (organisationId: string, userId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisbackup/${organisationId}/${userId}/ProductsInfo/`,
        GET_SETTING: (logonEmail: string) =>
            `${BaseUrl.baseUrl}/api/AcronisBackup/${logonEmail}/setting`,
        UPDATE_SETTING: (logonEmail: string) =>
            `${BaseUrl.baseUrl}/api/AcronisBackup/${logonEmail}/setting`,
        DELETE_ACRONIS_BACKUP_SERVICE: (organisationId: string | number, subId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisbackup/${organisationId}/Delete/${subId}/`,
        HAS_UNLIMITED_STORAGE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/acronisbackup/${organisationId}/HasServiceProduct/273`,
    },
    AZURE_AD_LOGIN_PROVIDER: {
        GET_ACCOUNT_LINKING_VALUE: `${BaseUrl.baseUrl}/api/azuread-login-provider`,
        UPDATE_ACCOUNT_LINKING_VALUE: `${BaseUrl.baseUrl}/api/azuread-login-provider`,
        UNLINK_AZURE_AD_ACCOUNT: (engineerId: string) =>
            `${BaseUrl.baseUrl}/api/azuread-login-provider/account-link/engineer/${engineerId}`,
    },
    BANNER: {
        GET_BANNER: `${BaseUrl.baseUrl}/api/banner`,
    },
    BILLING: {
        GET_ACCOUNT_TOTALS: `${BaseUrl.baseUrl}/api/billing/AccountTotals/`,
        GET_ACCOUNT_TOTALS_CSV: `${BaseUrl.baseUrl}/api/billing/AccountTotals/Csv`,
        EXPORT_INVOICE_CSV: `${BaseUrl.baseUrl}/api/billing/exportInvoiceCSV`,
        GET_PRICES: `${BaseUrl.baseUrl}/api/billing/GetPrices/`,
        GET_PRICES_CSV: '/api/billing/GetPrices/csv',
        HISTORY: `${BaseUrl.baseUrl}/api/billing/history`,
        NEXT_BILL_TOTAL: `${BaseUrl.baseUrl}/api/billing/NextBillTotal/`,
        CAN_REDEEM_CREDIT: `${BaseUrl.baseUrl}/api/billing/CanRedeemCredit`,
        REDEEM_CREDIT: `${BaseUrl.baseUrl}/api/billing/RedeemCredit/`,
        GET_SUB_INVOICE_DETAILS: `${BaseUrl.baseUrl}/api/billing/subInvoiceDetails`,
        GET_LIST_NEXT_BILL: `${BaseUrl.baseUrl}/api/billing/GetListNextBill/`,
        GET_LIST_NEXT_BILL_DETAILS_BY_CUSTOMER: `${BaseUrl.baseUrl}/api/billing/GetListNextBillDetailsByCustomer/`,
        GET_LIST_NEXT_BILL_DETAILS_BY_SERVICE: `${BaseUrl.baseUrl}/api/billing/GetListNextBillDetailsByService/`,
        EXPORT_NEXT_BILL_INVOICE_CSV: `${BaseUrl.baseUrl}/api/billing/exportNextBillInvoiceCSV/`,
        GET_SERVICES_YEARS: `${BaseUrl.baseUrl}/api/billing/getServicesYears`,
        GET_DIRECT_DEBIT_MANDATE_FORMS: `${BaseUrl.baseUrl}/api/billing/GetDirectDebitMandateforms/`,
        GET_DIRECT_DEBIT_MANDATE_FORM: (formName: string) =>
            `${BaseUrl.baseUrl}/api/billing/DownloadDirectDebitMandateform/${formName}/`,
        DOWNLOAD_INVOICE_DETAILS: (invoiceId: string | number) =>
            `${BaseUrl.baseUrl}/api/billing/invoiceDetails/${invoiceId}/download`,
        GET_MONTHLY_BILL_BREAKDOWN: (months: number) =>
            `${BaseUrl.baseUrl}/api/billing/monthlyBillBreakdown/${months}`,
        GET_AVAILABLE_REPORTS: (invoiceDateString: string) =>
            `${BaseUrl.baseUrl}/api/billing/availableReports/${invoiceDateString}`,
    },
    BITDEFENDER: {
        CHECK_RESELLER_ADMIN_USER_EXISTS: `${BaseUrl.baseUrl}/api/Bitdefender/CheckResellerAdminUserExists`,
        CREATE_BITDEFENDER_SERVICE: `${BaseUrl.baseUrl}/api/Bitdefender/CreateBitdefenderService`,
        MODIFY_BITDEFENDER_SERVICE: `${BaseUrl.baseUrl}/api/Bitdefender/ModifyBitdefender`,
        DELETE_BITDEFENDER_SERVICE: `${BaseUrl.baseUrl}/api/Bitdefender/DeleteBitdefenderService`,
        GET_BITDEFENDER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Bitdefender/${organisationId}`,
        GET_PRICE_BITDEFENDER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Bitdefender/GetPriceBitdefender/${organisationId}`,
        IS_VALID_FOR_DELETE: (id: string) =>
            `${BaseUrl.baseUrl}/api/Bitdefender/IsValidationDelete/${id}`,
        GET_CUSTOMER_PRODUCT_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Bitdefender/${organisationId}/ProductsInfo`,
        SET_ORGANISATION_ADMIN: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Bitdefender/SetOrganisationAdmin/${organisationId}`,
        GET_ENDPOINTS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/bitdefender/${organisationId}/endpoints`,
    },
    CLOUD_TALK: {
        DELETE_CUSTOMER: `${BaseUrl.baseUrl}/api/cloudtalk/DeleteCustomer`,
        DELETE_USER: `${BaseUrl.baseUrl}/api/cloudtalk/DeleteUser`,
        REMOVE_NUMBER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/RemoveNumber`,
        GET_HARDWARE_ORDER_DETAILS: (organisationId: string, orderId: number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/${orderId}/GetHardwareOrderDetails/`,
        GET_REMOVE_NUMBER_DETAILS: (organisationId: string, telephoneNumber: string) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/${telephoneNumber}/GetRemoveNumbersDetails/`,
        GET_USER: (userId: number) => `${BaseUrl.baseUrl}/api/cloudtalk/${userId}/GetUser`,
        UPDATE_USER: (userId: number) => `${BaseUrl.baseUrl}/api/cloudtalk/${userId}/UpdateUser`,
        SUBMIT: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/Submit`,
        SUBMIT_NUMBERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/SubmitNumbers`,
        SUBMIT_HARDWARE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/SubmitHardware`,
        GET_ADD_SERVICE_SETUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/GetAddServiceSetup`,
        GET_AVAILABLE_USERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/AvailableUsers`,
        UPDATE_AVAILABLE_USERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/AvailableUsers`,
        GET_HARDWARE_PRODUCTS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/GetHardwareProducts`,
        GET_AREA_CODES: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/GetAreaCodes`,
        SEARCH_NUMBERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/SearchNumbers`,
        GET_CUSTOMER_PRODUCT_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (organisationId: string | number, userId: number) =>
            `${BaseUrl.baseUrl}/api/cloudtalk/${organisationId}/${userId}/ProductsInfo/`,
    },
    CLOUD_SERVER: {
        PORTAL: `${BaseUrl.baseUrl}/api/cloud-server/portal`,
        GET_TOKEN: `${BaseUrl.baseUrl}/api/cloud-server/token`,
        DELETE_INSTANCE: `${BaseUrl.baseUrl}/api/cloud-server/delete-instance`,
        GET_CUSTOMER_PRODUCT_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/cloud-server/${organisationId}/ProductsInfo`,
        GET_INSTANCE: (organisationId: string | number, instanceId: string) =>
            `${BaseUrl.baseUrl}/api/cloud-server/${organisationId}/get-instance/${instanceId}`,
    },
    CUSTOMERS: {
        CURRENT_USER_RESTRICTED_CUSTOMERS: `${BaseUrl.baseUrl}/api/customer/user-restricted-customers`,
        CUSTOMERS_SUMMARY: `${BaseUrl.baseUrl}/api/customer/customers-summary`,
        GET_CUSTOMERS: `${BaseUrl.baseUrl}/api/customer`,
        GET_CUSTOMER_SERVICES: `${BaseUrl.baseUrl}/api/customer/GetCustomerServices`,
        GET_END_USER_ADMIN: `${BaseUrl.baseUrl}/api/customer/GetEndUserAdmin`,
        GET_SUGGESTIONS: `${BaseUrl.baseUrl}/api/customer/GetSuggestions`,
        GET_ALLOW_MIGRATION: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/AllowMigration`,
        GET_PENDING_MIGRATION: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/PendingMigration`,
        GET_BUNDLE_PRODUCTS: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/GetBundleProducts/${organisationId}`,
        GET_GROUP_SERVICES: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/GetGroupServices`,
        HAS_BUNDLED_EMAIL_SECURITY: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/HasBundledEmailSecurity`,
        HAS_BUNDLED_EMAIL_SECURITY_FOR_USER: (organisationId: string, userId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/HasBundledEmailSecurity/${userId}`,
        MIGRATION_ALLOW: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/AllowMigration`,
        MIGRATION_PENDING: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/PendingMigration`,
        EDITED_USER_RESTRICTED_CUSTOMERS: (userCloudMarketId: string) =>
            `${BaseUrl.baseUrl}/api/customer/user-restricted-customers/${userCloudMarketId}`,
        CHECK_DELETE_CUSTOMER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/checkDeleteCustomer/${organisationId}`,
        CHECK_DELETE_OFFICE365: (orgId: string, groupId: string) =>
            `${BaseUrl.baseUrl}/api/customer/CheckValidDeleteOffice365/${orgId}/${groupId}`,
        EXPORT_SERVICES: (id: string) => `${BaseUrl.baseUrl}/api/customer/exportservices/${id}/`,
        DELETE_CUSTOMER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Customer/deleteCustomer/${organisationId}`,
        GET_ACTIVITY_LOG: (organisationid: string) =>
            `${BaseUrl.baseUrl}/api/Customer/getActivityLog/${organisationid}`,
        GET_ACTIVITY_LOG_FOR_USER: (organisationid: string, userId: string) =>
            `${BaseUrl.baseUrl}/api/Customer/getActivityLog/${organisationid}/${userId}/`,
        GET_ALL_CUSTOMER_PRODUCTS: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/GetServices`,
        GET_ALL_CUSTOMER_SERVICES: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/GetAllServicesForClient`,
        GET_CUSTOMER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}`,
        GET_CUSTOMERS_OF_RESELLER: (productId: string | number) =>
            `${BaseUrl.baseUrl}/api/customer/GetCustomersOfReseller/${productId}`,
        GET_CUSTOMER_OFFICE365_PRODUCTS: (organisationId: string, officeGroupId: number) =>
            `${BaseUrl.baseUrl}/api/customer/${organisationId}/GetOffice365Products/${officeGroupId}`,
        GET_SERVICES: (id: string) => `${BaseUrl.baseUrl}/api/customer/getservices/${id}`,
        HAS_ANY_SERVICES: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/AnyServices/${organisationId}`,
        HAS_AZURE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Customer/${organisationId}/HasAzure`,
        HAS_OFFICE365_ACCOUNT: (id: string) =>
            `${BaseUrl.baseUrl}/api/customer/${id}/HasOffice365Account`,
        REACTIVATE_CUSTOMER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/reactive/${organisationId}`,
        SUSPEND_CUSTOMER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/customer/suspend/${organisationId}`,
        UPDATE_CUSTOMER_NAME: (selectedCustomerId: string) =>
            `${BaseUrl.baseUrl}/api/customer/UpdateCustomerName/${selectedCustomerId}`,
    },
    CUSTOMERS_SERVICE: {
        CREATE_CUSTOMER: `${BaseUrl.baseUrlCloudMarket}/Customer/v1/api/resellers/customers`,
        GET_CUSTOMER: (customerID: string) =>
            `${BaseUrl.baseUrlCloudMarket}/Customer/v1/api/Customer/customers/${customerID}`,
        UPDATE_CUSTOMER: (customerID: string) =>
            `${BaseUrl.baseUrlCloudMarket}/Customer/v1/api/resellers/customers/${customerID}`,
    },
    DASHBOARD: {
        GET_NEW_FEATURES: `${BaseUrl.baseUrl}/api/dashboard/newFeatures`,
        GET_MANAGE_SERVICE_BUTTONS: `${BaseUrl.baseUrl}/api/dashboard/getManageServiceButtons/`,
        GET_ADD_SERVICE_BUTTONS: `${BaseUrl.baseUrl}/api/dashboard/getAddServiceButtons/`,
        GET_RECENT_CUSTOMERS: `${BaseUrl.baseUrl}/api/dashboard/recentCustomers`,
        GET_RECENT_SERVICES: `${BaseUrl.baseUrl}/api/dashboard/recentServices`,
    },
    DIRECT_DEBIT: {
        SAVE_PAYER_AUTHORISATION: (payerAuthorisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/direct-debit/setup/${payerAuthorisationId}`,
    },
    DYNAMIC_FORM: {
        GET_FORM: (formId: number) => `${BaseUrl.baseUrl}/api/dynamicForm/${formId}`,
    },
    EMAIL_ARCHIVE: {
        REQUEST_TEMPORARY_PASSWORD: `${BaseUrl.baseUrl}/api/EmailArchive/RequestTemporaryPassword/`,
        SUBMIT_TEMPORARY_PASSWORD: `${BaseUrl.baseUrl}/api/EmailArchive/SubmitTemporaryPassword/`,
        IS_EXPRESS: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/EmailArchive/IsExpress/${emailAddress}/`,
        GET_EMAIL: (archiveEmailAddress: string) =>
            `${BaseUrl.baseUrl}/api/EmailArchive/DownloadEmail/${archiveEmailAddress}/`,
        REDELIVER_EMAIL: (archiveEmailAddress: string) =>
            `${BaseUrl.baseUrl}/api/EmailArchive/ReDeliverEmail/${archiveEmailAddress}/`,
        DOWNLOAD_EMAIL_FILE: (archiveEmailAddress: string, emailId: string, fileName: string) =>
            `${BaseUrl.baseUrl}/api/EmailArchive/DownloadEmailFile/${archiveEmailAddress}/${emailId}/${fileName}/`,
        LOAD_EMAIL_ARCHIVE: (archiveEmailAddress: string) =>
            `${BaseUrl.baseUrl}/api/EmailArchive/LoadEmailArchive/${archiveEmailAddress}/`,
        GET_FILTER_MIN_DATE: (archiveEmailAddress: string) =>
            `${BaseUrl.baseUrl}/api/EmailArchive/GetFilterMinDate/${archiveEmailAddress}/`,
    },
    END_USER: {
        GET_BLACK_LISTS: `${BaseUrl.baseUrl}/api/endUser/getBlackLists`,
        GET_WHITE_LISTS: `${BaseUrl.baseUrl}/api/endUser/getWhiteLists`,
        GET_CONDITION_SHOW_HIDE_TAP: `${BaseUrl.baseUrl}/api/enduser/conditionShowHideTap`,
        GET_PUBLIC_FOLDER: `${BaseUrl.baseUrl}/api/endUser/publicFolder`,
        DELETE_BLACK_RULE: (userName: string, ruleId: string) =>
            `${BaseUrl.baseUrl}/api/endUser/deleteBlackRule/${userName}/${ruleId}/`,
        DELETE_WHITE_RULE: (userName: string, ruleId: string): string =>
            `${BaseUrl.baseUrl}/api/endUser/deleteWhiteRule/${userName}/${ruleId}/`,
        GET_REPORTING: (mailbox) => `${BaseUrl.baseUrl}/api/endUser/getReporting/${mailbox}/`,
        GET_MANAGED_ARCHIVE: (username: string) =>
            `${BaseUrl.baseUrl}/api/endUser/GetManagedArchive/${username}/`,
        SERVICE: (selectedCustomerId: string, selectedEndUserId: string) =>
            `${BaseUrl.baseUrl}/api/User/${selectedCustomerId}/${selectedEndUserId}/SelectList`,
        GET_SPAM_LEVEL: (mailbox: string | number) =>
            `${BaseUrl.baseUrl}/api/endUser/getSpamLevel/${mailbox}/`,
        UPDATE_SPAM_LEVEL: (mailbox: string) =>
            `${BaseUrl.baseUrl}/api/endUser/updateSpamLevel/${mailbox}/`,
        ADD_MAIL_RULE: (userEmail: string) =>
            `${BaseUrl.baseUrl}/api/endUser/addSingleWhiteOrBlackRule/${userEmail}/`,
        IMPORT_FILE: (endUrl: string, userEmail: string) =>
            `${BaseUrl.baseUrl}/api/endUser/importFile${endUrl}/${userEmail}/`,
        UPDATE_ENDUSER_REPORTING: (primaryMailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/endUser/updateReportting/${primaryMailboxAddress}/`,
    },
    FAILURE: {
        ADD_CREDIT: `${BaseUrl.baseUrl}/api/Failure/AddCredit`,
        PAY_INVOICE: `${BaseUrl.baseUrl}/api/Failure/PayInvoice`,
    },
    FEATURE_FLAGS: {
        GET_REQUIRED_RATING: `${BaseUrl.baseUrl}/api/feature-flags/required-rating`,
        SAVE_RATING: `${BaseUrl.baseUrl}/api/feature-flags/save-rating`,
    },
    FOOTER: {
        GET_OPT_OUTS: `${BaseUrl.baseUrl}/api/footer/optOuts`,
        UPDATE_OPT_OUTS: `${BaseUrl.baseUrl}/api/footer/optOuts`,
        GET_FOOTER_BUTTONS: `${BaseUrl.baseUrl}/api/footer/buttons`,
        GET_POLICY: (policyId: number) => `${BaseUrl.baseUrl}/api/footer/policy/${policyId}`,
    },
    FORUM: {
        GET_TOKEN: `${BaseUrl.baseUrl}/api/forum/get-token`,
    },
    SERVICE_TYPES: {
        GET_SERVICE_TYPES: `${BaseUrl.baseUrl}/api/servicetypes`,
    },
    SERVICES: {
        GET_ACTIVATE_TEXT: `${BaseUrl.baseUrl}/api/services/activate-text`,
        ACTIVATE_SERVICE: `${BaseUrl.baseUrl}/api/services/activate-service/`,
        SUGGEST_SERVICE: `${BaseUrl.baseUrl}/api/services/suggestservice`,
        GET_INLINE_AGREEMENTS: `${BaseUrl.baseUrl}/api/services/GetInlineAgreements`,
        GET_SERVICES: `${BaseUrl.baseUrl}/api/services`,
        GET_SERVICES_BY_END_USER_ADMIN: `${BaseUrl.baseUrl}/api/services/GetServicesByEndUserAdmin`,
        GET_SERVICES_BY_END_USER_ADMIN_FOR_TYPE: (typeId: number) =>
            `${BaseUrl.baseUrl}/api/services/GetServicesByEndUserAdmin/${typeId}`,
        GET_SERVICES_FOR_TYPE: (typeId: number) =>
            `${BaseUrl.baseUrl}/api/services/servicetypes/${typeId}`,
        GET_UPGRADE_INFO: (serviceId: number, productId: number) =>
            `${BaseUrl.baseUrl}/api/services/${serviceId}/Upgrade/${productId}`,
        SKIP_UPSELL: (serviceId: number) =>
            `${BaseUrl.baseUrl}/api/services/${serviceId}/SkipUpsell`,
        GET_SERVICES_OF_END_USER_ADMIN: (selectedCustomerId: string) =>
            `${BaseUrl.baseUrl}/api/Services/GetServicesOfEndUserAdmin/${selectedCustomerId}`,
        GET_ALL_SERVICES_OF_RESELLER: (selectedCustomerId: string) =>
            `${BaseUrl.baseUrl}/api/Services/GetAllServicesOfReseller/${selectedCustomerId}`,
        SAVE_AZURE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/services/${organisationId}/SaveAzure`,
        GET_SERVICES_OF_RESELLER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/services/GetServicesOfReseller/${organisationId}`,
        GET_SERVICES_OF_RESELLER_TO_DELETE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Services/GetServicesOfResellerToDelete/${organisationId}`,
        GET_PRICING: (serviceId: string | number) =>
            `${BaseUrl.baseUrl}/api/services/pricing/${serviceId}`,
        GET_DONE_MESSAGE: (serviceId: string | number) =>
            `${BaseUrl.baseUrl}/api/services/GetDoneMessage/${serviceId}`,
        GET_DONE_MESSAGE_FOR_VERSION: (serviceId: string | number, versionId: string | number) =>
            `${BaseUrl.baseUrl}/api/services/GetDoneMessage/${serviceId}/${versionId}`,
        GET_LICENSES: (organisationId: string | number, serviceId: string | number) =>
            `${BaseUrl.baseUrl}/api/Services/${organisationId}/Licenses/${serviceId}`,
        GET_SERVICE_LISTING_ID: (organisationServiceId: number, officeGroupId: number) =>
            `${BaseUrl.baseUrl}/api/services/get-service-listing-id/${organisationServiceId}/${officeGroupId}`,
        SET_SERVICE_JOURNEY_MESSAGE: (serviceId: string | number, groupId: number) =>
            `${BaseUrl.baseUrl}/api/services/${serviceId}/${groupId}/Message/${ServiceJourneyMessageType.Delete}`,
        GET_INLINE_AGREEMENT: (serviceId: string | number) =>
            `${BaseUrl.baseUrl}/api/services/GetInlineAgreement/${serviceId}`,
        GET_INLINE_AGREEMENT_FOR_GROUP: (serviceId: string | number, groupId: number) =>
            `${BaseUrl.baseUrl}/api/services/GetInlineAgreement/${serviceId}/${groupId}`,
    },
    HOSTED_EXCHANGE: {
        IS_USER_MIGRATING: (userId: number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${userId}/GetIsUserMigrating`,
        CAN_ADD_EXCHANGE: (organisationId: string | number, emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/${emailAddress}/CanAddExchange`,
        CREATE_HOSTED_EXCHANGE: `${BaseUrl.baseUrl}/api/HostedExchange/CreateHostedExchange`,
        DELETE_HOSTED_EXCHANGE_FOR_USER: `${BaseUrl.baseUrl}/api/HostedExchange`,
        DELETE_HOSTED_EXCHANGE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/Delete`,
        DELETE_HOSTED_EXCHANGE_WITH_OPTION_TYPE: (organisationId: string, optionType: string) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/Delete/${optionType}`,
        GET_PRODUCTS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}`,
        GET_PRODUCTS_WITH_UPGRADES: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/true`,
        GET_USERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/GetUsers/${organisationId}`,
        GET_HOSTED_EXCHANGE_USER: `${BaseUrl.baseUrl}/api/HostedExchange/GetHostedExchangeUser`,
        GET_HOSTED_EXCHANGE_USERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/GetHostedExchangeUsers/${organisationId}`,
        GET_USERS_OF_PRODUCT: (organisationId: string | number, upgradeProductId: number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/GetUsersOfProduct/${organisationId}/${upgradeProductId}`,
        GET_USER_SERVICE_MODELS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/GetUserServiceModels`,
        GET_USER_SERVICE_MODELS_WITH_SUBID: (
            organisationId: string | number,
            subId: string | number
        ) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/GetUserServiceModels/${subId}`,
        REASSIGN_ARCHIVE_MANAGER: `${BaseUrl.baseUrl}/api/HostedExchange/ReassignArchiveManager`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${id}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (organisationId: string, userId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/${userId}/ProductsInfo/`,
        MODIFY_HOSTED_EXCHANGE: `${BaseUrl.baseUrl}/api/HostedExchange/ModifyHostedExchange`,
        SECURE_AGREED: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/SecureAgreed`,
        GET_SERVICE_INFO: (organisationId: string | number, emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/HostedExchange/${organisationId}/${emailAddress}/true`,
    },
    EXCHANGE_DISTRIBUTION_GROUP: {
        UPDATE_DISTRIBUTION_GROUP: `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup`,
        ADD_DISTRIBUTION_GROUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup/${organisationId}`,
        MANAGE_DISTRIBUTION_GROUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup/${organisationId}/`,
        DELETE_DISTRIBUTION_GROUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup/${organisationId}/`,
        GET_EXCHANGE_DISTRIBUTION_GROUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup/${organisationId}`,
        GET_ADDING_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup/${organisationId}/AddingInfo`,
        GET_UPDATING_INFO: (address: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup/${address}/UpdatingInfo`,
        GET_MEMBER_INFO: (organisationId: string | number, address: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeDistributionGroup/${organisationId}/${address}/MemberInfo`,
    },
    DROPSUITE: {
        GET_PRODUCTS: `${BaseUrl.baseUrl}/api/dropsuite/getproducts/`,
        GET_REMOVE_MAILBOX_DETAILS: (organisationId: string, accountId: string | number) =>
            `${BaseUrl.baseUrl}/api/dropsuite/GetRemoveMailboxDetails/${organisationId}/${accountId}`,
        DELETE_SERVICE_FOR_CUSTOMER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/dropsuite/DeleteServiceForCustomer/${organisationId}`,
        DELETE_SERVICE_FOR_USER: (organisationId: string, accountId: string | number) =>
            `${BaseUrl.baseUrl}/api/dropsuite/DeleteServiceForUser/${accountId}/${organisationId}`,
        DELETE_USER: (organisationId: string, accountId: string | number) =>
            `${BaseUrl.baseUrl}/api/dropsuite/DeleteUser/${accountId}/${organisationId}`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/dropsuite/${id}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (id: string, parentId: string | number) =>
            `${BaseUrl.baseUrl}/api/dropsuite/${id}/${parentId}/ProductsInfo`,
        GET_ADD_SERVICE_SETUP: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/dropsuite/GetAddServiceSetup/${organisationId}`,
        SUBMIT: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/dropsuite/Submit/${organisationId}`,
    },
    EMAIL_SECURITY: {
        REGISTER_EMAIL_SECURITY: `${BaseUrl.baseUrl}/api/emailsecurity/registeremailsecurity`,
        MODIFY_EMAIL_SECURITY: `${BaseUrl.baseUrl}/api/emailsecurity/ModifyEmailSecurity`,
        GET_ARCHIVE_LEVELS: `${BaseUrl.baseUrl}/api/emailsecurity/GetArchiveLevels`,
        GET_EMAIL_TYPES: `${BaseUrl.baseUrl}/api/emailsecurity/getemailtypes`,
        GET_SERVICE_INFO: `${BaseUrl.baseUrl}/api/emailsecurity/GetServiceInfo`,
        GET_SPAM_LEVELS: `${BaseUrl.baseUrl}/api/emailsecurity/GetSpamLevels`,
        DELETE_USER_SERVICE: (organisationId: string | number, userName: string) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/${organisationId}/${userName}/`,
        DELETE_CUSTOMER_SERVICE: (organisationId: string | number, subId: string) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/${organisationId}/${subId}`,
        GET_ALL_USERS_DETAILS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/GetUserDetails/${organisationId}`,
        GET_AVAILABLE_MANAGERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/${organisationId}/GetAvailableManagers`,
        GET_MANAGER_GROUPS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/GetManagerGroups/${organisationId}`,
        GET_USERS_FOR_REGISTER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/getusersforregister/${organisationId}`,
        GET_RULES: (organisationId: string | number, userName: string) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/GetRules/${organisationId}/${userName}/`,
        GET_USERS_WITH_EMAIL_SECURITY: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/EmailSecurity/GetUsersWithEmailecurity/${organisationId}`,
        PREPARE_EXCHANGE_DELETE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/${organisationId}/prepareexchangedelete`,
        GET_USER_SERVICE_MODELS: (organisationId: string | number, subId: string | number) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/${organisationId}/GetUserServiceModels/${subId}`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/${id}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (organisationId: string, userId: string | number) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/${organisationId}/${userId}/ProductsInfo/`,
        GET_USERS_OF_PRODUCT: (organisationId: string | number, upgradeProductId: number) =>
            `${BaseUrl.baseUrl}/api/emailsecurity/GetUsersOfProduct/${organisationId}/${upgradeProductId}`,
    },
    ES_DISASTER_RECOVERY: {
        SET_RECOVERY_CONFIRM_PAGE: `${BaseUrl.baseUrl}/api/es-DisasterRecovery/SetConfirmPage`,
        START_RECOVERY: `${BaseUrl.baseUrl}/api/es-DisasterRecovery/StartRecovery`,
        GET_DISASTER_RECOVERY_INFO: `${BaseUrl.baseUrl}/api/es-DisasterRecovery/GetDisasterRecoveryInfo`,
        ENABLE_DISASTER_RECOVERY_FOR_USER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/es-DisasterRecovery/EnableDisasterRecoveryForUser/${organisationId}`,
    },
    ES_DISTRIBUTION_GROUP: {
        UPLOAD_WHITELIST_RULES_FILE: `${BaseUrl.baseUrl}/api/es-distribution-group/AddWhitelistRules`,
        UPLOAD_BLACKLIST_RULES_FILE: `${BaseUrl.baseUrl}/api/es-distribution-group/AddBlacklistRules`,
        GET_DISTRIBUTION_GROUPS: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${organisationId}`,
        GET_ACCESS_INFO: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${organisationId}/AccessInfo`,
        GET_ADD_INFO: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${organisationId}/AddInfo`,
        GET_UPDATE_INFO: (organisationId: string, emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${organisationId}/UpdateInfo/${emailAddress}/`,
        ADD_DISTRIBUTION_GROUP: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${organisationId}`,
        DELETE_DISTRIBUTION_GROUP: (organisationId: string, emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${organisationId}/${emailAddress}/`,
        UPDATE_SPAM_LEVEL: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/SpamLevel/${emailAddress}/`,
        UPDATE_ARCHIVE_LEVEL: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/ArchiveLevel/${emailAddress}/`,
        GET_WHITELIST_RULES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/WhitelistRules`,
        ADD_WHITELIST_RULES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/WhitelistRules`,
        REMOVE_WHITELIST_RULE: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/WhitelistRule`,
        GET_BLACKLIST_RULES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/BlacklistRules`,
        ADD_BLACKLIST_RULES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/BlacklistRules`,
        REMOVE_BLACKLIST_RULE: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/BlacklistRule`,
        GET_ARCHIVE_RULES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/ArchiveRules`,
        ADD_ARCHIVE_RULES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/ArchiveRules`,
        REMOVE_ARCHIVE_RULE: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${emailAddress}/ArchiveRule`,
        UPDATE_REPORTING: (organisationId: string, emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/${organisationId}/Reporting/${emailAddress}/`,
        UPDATE_GROUP_MANAGER: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/GroupManager/${emailAddress}/`,
        UPDATE_PERMISSION: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/es-distribution-group/Permission/${emailAddress}/`,
    },
    ES_DOMAIN: {
        ADD_EXTENSION: `${BaseUrl.baseUrl}/api/es-domain/AddExtension`,
        UPDATE_BANNED_EXTENSIONS: `${BaseUrl.baseUrl}/api/es-domain/BannedExtensions`,
        UPLOAD_WHITELIST_RULES_FILE: `${BaseUrl.baseUrl}/api/es-domain/AddWhitelistRules`,
        UPLOAD_BLACKLIST_RULES_FILE: `${BaseUrl.baseUrl}/api/es-domain/AddBlacklistRules`,
        GET_DOMAINS: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${organisationId}`,
        GET_ACCESS_INFO: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${organisationId}/AccessInfo`,
        GET_UPDATE_INFO: (organisationId: string, domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${organisationId}/UpdateInfo/${domainRef}/`,
        ADD_DOMAIN: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${organisationId}`,
        UPDATE_SPAM_LEVEL: (domainRef: string) => `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/`,
        UPDATE_ARCHIVE_LEVEL: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/ArchiveLevel`,
        UPDATE_VIRUS_NOTIFICATION: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/VirusNotification`,
        GET_WHITELIST_RULES: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/WhitelistRules`,
        ADD_WHITELIST_RULES: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/WhitelistRules`,
        REMOVE_WHITELIST_RULE: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/WhitelistRule`,
        GET_BLACKLIST_RULES: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/BlacklistRules`,
        ADD_BLACKLIST_RULES: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/BlacklistRules`,
        REMOVE_BLACKLIST_RULE: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/BlacklistRule`,
        GET_BANNED_EXTENSIONS: (organisationId: string, domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${organisationId}/BannedExtensions/${domainRef}/`,
        UPDATE_INBOUND_DELIVERY: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/InboundDelivery`,
        UPDATE_OUTBOUND_SMTP: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/OutboundSMTP`,
        UPDATE_EXCHANGE_JOURNALING_FEED: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/ExchangeJournalingFeed`,
        UPDATE_BACKSCATTER_FILTERING_SYSTEM: (domainRef: string) =>
            `${BaseUrl.baseUrl}/api/es-domain/${domainRef}/BackscatterFilteringSystem`,
    },
    ES_SETTING: {
        ADD_ARCHIVE_RULES: `${BaseUrl.baseUrl}/api/es-Setting/ArchiveRulesClick`,
        GET_SETTING_ARCHIVE_RULE_INFO: `${BaseUrl.baseUrl}/api/es-Setting/GetSettingArchiveRuleInfo`,
        REMOVE_ARCHIVE_RULES: `${BaseUrl.baseUrl}/api/es-Setting/ArchiveRulesRemove`,
    },
    HOSTED_VOICE: {
        CREATE_HOSTED_VOICE: `${BaseUrl.baseUrl}/api/HostedVoice/CreateHostedVoice`,
        CREATE_HOSTED_VOICE_V2: `${BaseUrl.baseUrl}/api/hostedvoice/CreateHostedVoiceV2`,
        MODIFY_HOSTED_VOICE: `${BaseUrl.baseUrl}/api/HostedVoice/ModifyHostedVoice`,
        MODIFY_HOSTED_VOICE_V2: `${BaseUrl.baseUrl}/api/hostedvoice/ModifyHostedVoiceOrderV2`,
        GET_PACKAGES: `${BaseUrl.baseUrl}/api/HostedVoice/GetPackages`,
        GET_PACKAGE_DEFINITIONS: `${BaseUrl.baseUrl}/api/HostedVoice/PackageDefinitions`,
        GET_V2_PACKAGES: `${BaseUrl.baseUrl}/api/hostedvoice/GetV2Packages`,
        GET_V2_SITES: `${BaseUrl.baseUrl}/api/hostedvoice/GetV2Sites`,
        DELETE_HOSTED_VOICE: `${BaseUrl.baseUrl}/api/HostedVoice/DeleteHostedVoiceService`,
        GET_ORDERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/GetOrders`,
        GET_ORDER: (organisationId: string | number, orderId: number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/get-order/${orderId}/`,
        DELETE_SERVICE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/DeleteHostedVoiceServiceV2`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${id}/ProductsInfo`,
        GET_CUSTOMER_PRODUCT_INFO_v2: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/V2ProductsInfo/`,
        GET_CUSTOMER_PRODUCT_INFO_FOR_ORDER: (organisationId: string | number, orderId: number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/V2ProductsInfo/${orderId}`,
        GET_VERSION: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/version`,
        GET_CUSTOMER_PRODUCTS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/GetCustomerProducts`,
        GET_USAGE_DATA: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/GetUsageData`,
        CREATE_NUMBER_PORTING_REQUEST_ORDER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/CreateNumberPortingRequestOrder`,
        NUMBER_PORTING_REQUEST_DOWNLOAD: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoice/${organisationId}/NumberPortingRequestDownload`,
    },
    HOSTED_VOICE_HARDWARE: {
        GET_DELIVERY_OPTIONS: `${BaseUrl.baseUrl}/api/hostedvoicehardware/getDeliveryOptions/`,
        GET_ADD_SERVICE_SETUP: `${BaseUrl.baseUrl}/api/hostedvoicehardware/GetAddServiceSetup/`,
        CREATE_HOSTED_VOICE_HARDWARE: `${BaseUrl.baseUrl}/api/hostedvoicehardware/create`,
        MODIFY_HOSTED_VOICE_HARDWARE: `${BaseUrl.baseUrl}/api/hostedvoicehardware/modify`,
        GET_ORDERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/HostedVoiceHardware/${organisationId}/GetOrders`,
        CANCEL_ORDER: (organisationId: string | number, orderId: number) =>
            `${BaseUrl.baseUrl}/api/HostedVoiceHardware/${organisationId}/CancelOrder/${orderId}`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/HostedVoiceHardware/${id}/ProductsInfo`,
        GET_EXISTING_ORDER: (organisationId: string | number, orderId: number) =>
            `${BaseUrl.baseUrl}/api/hostedvoicehardware/GetExistingOrder/${organisationId}/${orderId}/`,
    },
    EXCHANGE_APP_IMPERSONATION: {
        GET_APP_IMPERSONATION: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}`,
        GET_MAILBOXES: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}/Mailboxes`,
        GET_MAILBOX_PERMISSION: (organisationId: string | number, mailboxId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}/${mailboxId}`,
        SAVE_APP_IMPERSONATION: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}`,
        UPDATE_APP_IMPERSONATION: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}`,
        DELETE_APP_IMPERSONATION: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}`,
        DELETE_APP_IMPERSONATION_BY_ADDRESS: (
            organisationId: string | number,
            emailAddress: string
        ) => `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}/${emailAddress}/`,
        GET_INITIALISE_ADD: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${organisationId}/InitialiseAdd`,
        GET_INITIALISE_EDIT: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeAppImpersonation/${emailAddress}/InitialiseEdit`,
    },
    EXCHANGE_DOMAIN: {
        ADD_EXTENSION: `${BaseUrl.baseUrl}/api/exchangedomain/AddExtension/`,
        ADD_NEW_DOMAIN: `${BaseUrl.baseUrl}/api/exchangedomain/addnewdomain/`,
        BANNED_EXTENSIONS: `${BaseUrl.baseUrl}/api/exchangedomain/BannedExtensions`,
        NOTIFICATION: `${BaseUrl.baseUrl}/api/exchangedomain/Notification`,
        SET_DKIM_ACTIVATION: `${BaseUrl.baseUrl}/api/exchangedomain/SetDKIMActivation`,
        SET_SPF_ACTIVATION: `${BaseUrl.baseUrl}/api/exchangedomain/SetSPFActivation`,
        SET_SPOOF_FILTERING_ACTIVATION: `${BaseUrl.baseUrl}/api/exchangedomain/SetSpoofFilteringActivation`,
        DELETE_DOMAIN: (organizationId: string | number, domainName: string) =>
            `${BaseUrl.baseUrl}/api/exchangedomain/deletedomain/${organizationId}/${domainName}/`,
        GET_DOMAINS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/exchangedomain/${organisationId}`,
        GET_DOMAIN_INFO_FOR_EDITING: (organisationId: string | number, domain: string) =>
            `${BaseUrl.baseUrl}/api/exchangedomain/${organisationId}/${domain}/`,
        ORGANISATION_DOMAIN: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/exchangedomain/getOrganisationDomain/${organisationId}`,
        ACTIVATE_ACCEPTED: (organisationId: string, domainName: string) =>
            `${BaseUrl.baseUrl}/api/exchangedomain/${organisationId}/${domainName}/ActivateAccepted`,
    },
    EXCHANGE_MAIL_ENABLED_CONTACT: {
        GET_MAIL_ENABLED_CONTACTS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeMailEnabledContact/${organisationId}`,
        ADD_SERVICE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeMailEnabledContact/${organisationId}`,
        UPDATE_SERVICE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeMailEnabledContact/${organisationId}`,
        DELETE_SERVICE: (organisationId: string | number, mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeMailEnabledContact/${organisationId}/${mailboxAddress}/`,
    },
    EXCHANGE_PERMISSION: {
        UPDATE_PERMISSION: `${BaseUrl.baseUrl}/api/ExchangePermission`,
        GET_PERMISSION_INFO: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/ExchangePermission/${mailboxAddress}/`,
    },
    EXCHANGE_PLATFORM_MIGRATION: {
        GET_MIGRATION_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangePlatformMigration/MigrationInfo/${organisationId}`,
        GET_INVALID_DATES: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangePlatformMigration/InvalidDates/${organisationId}`,
        SCHEDULE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangePlatformMigration/Schedule/${organisationId}`,
    },
    EXCHANGE_ROOM_MAILBOX: {
        GET_ROOM_MAILBOXES: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeRoomMailbox/${organisationId}`,
        GET_PREPARE_ADD: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeRoomMailbox/${organisationId}/PrepareAdd`,
        GET_PREPARE_EDIT: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeRoomMailbox/${mailboxAddress}/PrepareEdit`,
        ADD_ROOM_MAILBOX: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeRoomMailbox/${organisationId}`,
        GET_ROOM_MAILBOX_SETTINGS: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeRoomMailbox/${mailboxAddress}/PrepareEdit/`,
        UPDATE_ROOM_MAILBOX: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeRoomMailbox/${organisationId}`,
        DELETE_ROOM_MAILBOX: (organisationId: string | number, mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeRoomMailbox/${organisationId}/${mailboxAddress}/`,
    },
    EXCHANGE_SETTING: {
        ACTIVATE_SHARED_MAILBOX: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SharedMailbox`,
        CLIENT_MOBILE_ACCESS_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/ClientMobileAccessInfo`,
        DELETE_PUBLIC_FOLDER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/PublicFolder`,
        DELETE_SHARED_MAILBIOX: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SharedMailbox`,
        DELETE_DISCLAIMER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/DeleteDisclaimer`,
        GET_SETTING_MAILBOX_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}`,
        GET_SETTING_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SettingInfo`,
        GET_EMAIL_ROUTE_SETTING: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/GetEmailRouteSetting`,
        GET_PUBLIC_FOLDERS_ENABLED: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/PublicFoldersEnabled`,
        GET_PUBLIC_FOLDERS_PERMISSION: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${mailboxAddress}/PublicFoldersPermission`,
        GET_SHARED_MAILBOX_ENABLED: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SharedMailboxEnabled`,
        GET_SHARED_MAILBOX_PERMISSION: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${mailboxAddress}/SharedMailboxPermission`,
        GET_SIGNATURE_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SignatureInfo`,
        GET_SIGNATURE_OF_DOMAIN: (domain: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/SignatureOfDomain/${domain}/`,
        GET_SYNCHRONISE_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SynchroniseInfo`,
        GET_SYNCHRONISATION_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SynchronisationInfo`,
        GET_DISCLAIMER_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/DisclaimerInfo`,
        GET_DISCLAIMER_INFO_FOR_ACTION: (organisationId: string | number, action: string) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/DisclaimerInfo/${action}`,
        UPDATE_SETTING_MAILBOX_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}`,
        UPDATE_SIGNATURE_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SignatureInfo`,
        UPDATE_DISCLAIMER_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/DisclaimerInfo`,
        UPDATE_PUBLIC_FOLDER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/PublicFolder`,
        UPDATE_SHARED_MAILBOX: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SharedMailbox`,
        UPDATE_EMAIL_DELIVERY_ROUTE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/ExchangeSetting/${organisationId}/SetEmailDeliveryRoute`,
    },
    INTEGRATION: {
        // Appending the URL parameter 'all' in the URL because of an known issue with the API
        GET_LATEST_PSA_DATA_CACHE: `${BaseUrl.baseUrl}/api/integration/psadatacache?all`,
        REFRESH_PSA_DATA_CACHE: `${BaseUrl.baseUrl}/api/integration/psadatacache`,
    },
    KNOWLEDGEBASE: {
        GET_ARTICLES: `${BaseUrl.baseUrl}/api/knowledgebase/articles`,
        GET_POPULAR_ARTICLES: `${BaseUrl.baseUrl}/api/knowledgebase/popularArticles`,
        GET_HELP_JUICE_TOKEN: `${BaseUrl.baseUrl}/api/knowledgebase/helpjuice/token`,
        GET_ARTICLE: (id: number) => `${BaseUrl.baseUrl}/api/knowledgebase/article/${id}`,
        GET_ARTICLE_BY_PATH: (pathEncoded: string) =>
            `${BaseUrl.baseUrl}/api/knowledgebase/articleByPath/${pathEncoded}`,
        GET_ARTICLES_FOR_CATEGORY: (categoryId: number) =>
            `${BaseUrl.baseUrl}/api/knowledgebase/getArticlesForCategory/${categoryId}`,
        GET_CATEGORY: (categoryId: number) =>
            `${BaseUrl.baseUrl}/api/knowledgebase/getCategory/${categoryId}`,
        SEARCH_ARTICLES: (searchTerms: string) =>
            `${BaseUrl.baseUrl}/api/knowledgebase/searchArticles/${encodeURIComponent(
                searchTerms
            )}`,
    },
    LANDING_PAGE: {
        GET_SECTIONS: (pageId: string) => `${BaseUrl.baseUrl}/api/landingpage/${pageId}`,
    },
    NEW_FEATURE_COMMUNICATION: {
        GET_COMMUNICATIONS: `${BaseUrl.baseUrl}/api/NewFeatureCommunication/communications`,
        CHECK_SHOW_POPUP: `${BaseUrl.baseUrl}/api/NewFeatureCommunication/checkShowPopup`,
        SET_NEW_FEATURE_SEEN: (featureFlag: string) =>
            `${BaseUrl.baseUrl}/api/NewFeatureCommunication/setnewfeatureseen/${featureFlag}`,
    },
    NOTIFICATIONS: {
        GET_NOTIFICATIONS: `${BaseUrl.baseUrl}/api/notification`,
        UPDATE_NOTIFICATION: `${BaseUrl.baseUrl}/api/notification`,
        GET_CONFIG_RELOAD_NOTIFICATION_TIME: `${BaseUrl.baseUrl}/api/Notification/GetConfigReloadNotificationTime`,
        GET_DROPZONE_NOTIFICATIONS: `${BaseUrl.baseUrl}/api/Notification/GetDropZoneNotifications`,
        CLOSE_NOTIFICATION: `${BaseUrl.baseUrl}/api/Notification/CloseNotification`,
    },
    MAILBOX: {
        ADD_ALIAS: `${BaseUrl.baseUrl}/api/Mailbox/AddAlias`,
        ACTIVATE_ALL_MAILBOXES: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/ActivateAllMailboxes/${organisationId}`,
        CHECK_EXCHANGE_VERSION: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Mailbox/CheckExchangeVersion/${organisationId}`,
        DELETE_ALIAS: (emailAddress: string, alias: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/DeleteAlias/${emailAddress}/${alias}/`,
        GET_MAILBOX: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${mailboxAddress}/`,
        GET_ADRESS_BOOK: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/GetAddressBook/${mailboxAddress}/`,
        GET_FORWARDING_INFO: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${mailboxAddress}/ForwardingInfo`,
        GET_GENERAL_INFO: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${mailboxAddress}/`,
        GET_MAILBOX_ALIASES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/GetMailboxAliases/${emailAddress}/`,
        GET_ORGANISATION_DOMAINS: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/GetOrganisationDomains/${emailAddress}/`,
        GET_DISTRIBUTION_GROUPS: (mailboxAddress: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${mailboxAddress}/DistributionGroups`,
        GET_DISTRIBUTION_GROUPS_FOR_CUSTOMER: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${organisationId}/DistributionGroupsForCustomer`,
        UPDATE_ADDRESS_BOOK_ENTRY: (userEmail: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/UpdateAddressBook/${userEmail}/`,
        UPDATE_MAILBOX: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${organisationId}`,
        UPDATE_ADDRESS_BOOK: (userEmail: string) =>
            `${BaseUrl.baseUrl}/api/Mailbox/UpdateAddressBook/${userEmail}/`,
        UPDATE_DISTRIBUTION_GROUPS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${organisationId}/DistributionGroups`,
        UPDATE_FORWARDING: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${organisationId}/Forwarding`,
        UPDATE_PUBLIC_FOLDER_PERMISSIONS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${organisationId}/UpdatePublicFolderPermissions`,
        UPDATE_SHARED_MAILBOX_PERMISSIONS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/Mailbox/${organisationId}/UpdateSharedMailboxPermissions`,
    },
    ON_PREMISE_ALIAS: {
        ADD_ALIAS: `${BaseUrl.baseUrl}/api/OnPremiseAlias/AddAlias`,
        GET_MAILBOX_ALIASES: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/OnPremiseAlias/GetMailboxAliases/${emailAddress}/`,
        GET_ORGANISATION_DOMAINS: (emailAddress: string) =>
            `${BaseUrl.baseUrl}/api/OnPremiseAlias/GetOrganisationDomains/${emailAddress}/`,
        DELETE_ALIAS: (emailAddress: string, alias: string) =>
            `${BaseUrl.baseUrl}/api/OnPremiseAlias/DeleteAlias/${emailAddress}/${alias}/`,
    },
    MAILBOX_USER: {
        GET_HAS_MAILBOX_PRODUCT: `${BaseUrl.baseUrl}/api/MailboxUser/HasMailBoxProduct`,
        GET_MAILBOX_INFO: `${BaseUrl.baseUrl}/api/MailboxUser/GetMailboxInfo`,
        GET_MAILBOX_AGREEMENT_CONTENT: (agreementType: string) =>
            `${BaseUrl.baseUrl}/api/MailboxUser/GetMailboxAgreementContent/${agreementType}`,
        ACCEPT_MAILBOX_AGREEMENT: (agreementType: string) =>
            `${BaseUrl.baseUrl}/api/MailboxUser/AcceptMailboxAgreement/${agreementType}`,
    },
    MESSAGE_LABS: {
        CREATE: `${BaseUrl.baseUrl}/api/messagelabs/create`,
        CHECK_OFFICE_CHANGES: `${BaseUrl.baseUrl}/api/messagelabs/CheckOfficeChanges`,
        DELETE_MESSAGE_LABS_SERVICE: `${BaseUrl.baseUrl}/api/messagelabs/DeleteMessageLabsService`,
        GET_ADD_SERVICE_SETUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/messagelabs/${organisationId}/GetAddServiceSetup/`,
        GET_CUSTOMER_PRODUCT_INFO: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/messagelabs/${organisationId}/ProductsInfo`,
    },
    MFA: {
        RESELLER_IS_ENABLED: `${BaseUrl.authUrl}/api/mfa/reseller/is-enabled`,
        USER_SETUP: `${BaseUrl.authUrl}/api/mfa/user/setup`,
        CONFIGURE_USER: (mfaKey: string) => `${BaseUrl.authUrl}/api/mfa/user/configure/${mfaKey}/`,
        DISABLE_FOR_USER: (userName: string) =>
            `${BaseUrl.authUrl}/api/mfa/user/disable/${userName}/`,
        USER_IS_ENABLED: (userName: string) =>
            `${BaseUrl.authUrl}/api/mfa/user/is-enabled/${userName}/`,
        VERIFY_TOKEN: (mfaKey: string, mfaToken: string) =>
            `${BaseUrl.authUrl}/api/mfa/verify-token/${mfaKey}/${mfaToken}/`,
        VERIFY_USER: (mfaToken: string) => `${BaseUrl.authUrl}/api/mfa/user/verify/${mfaToken}/`,
    },
    MSFT: {
        // Microsoft
        UPDATE_OVERAGE: `${BaseUrl.baseUrl}/api/microsoft/overage`,
        MSFT_SERVICE_REFERENCES: 'MicrosoftServiceReferences',
        GET_MODIFY_TENANT: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/tenant`,
        GET_OVERAGE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/overage/${organisationId}`,
        GET_SUBSCRIPTION: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}`,
        GET_SUBSCRIPTION_ADDONS: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}/addons`,
        GET_SUBSCRIPTION_UPGRADE_OPTIONS: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}/upgrade`,
        GET_SUBSCRIPTION_COTERMINATE_OPTIONS: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}/coterminate`,
        COTERMINATE_EXISTING_SUBSCRIPTION: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}/coterminate`,
        DELETE_SUBSCRIPTION: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}`,
        DELETE_SUBSCRIPTION_WITH_SECURE: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}/secure`,
        MIGRATE_TO_NCE: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}/ncemigrate`,
        ADD_NEXT_TERM_INSTRUCTIONS: (organisationId: string, subscriptionId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions/${subscriptionId}/nextterminstructions`,
        GET_SUBSCRIPTIONS: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/microsoft/${organisationId}/subscriptions`,
        DOWNLOAD_SUBSCRIPTION_MANAGMENT_REPORT: (includeAssignedLicences: boolean) =>
            `${BaseUrl.baseUrl}/api/microsoft/SubscriptionManagementReport/?includeAssignedLicences=${includeAssignedLicences}`,
        VALIDATE_MIGRATION_TO_NCE: `${BaseUrl.baseUrlCloudMarket}/partnercenter/v2/api/Migrations/validate`,
        GET_PARTNER_ASSOCIATION_LINK: `${BaseUrl.baseUrlCloudMarket}/partnercenter/v2/api/partner/getassociationlink`,
    },
    MY_SERVICES: {
        GET_ADD_MODIFY_BUTTONS: (
            productCloudMarketId: string,
            customerId: string,
            listSchemaId: number
        ) =>
            `${BaseUrl.baseUrl}/api/myServices/GetAddButton/${customerId}/${productCloudMarketId}/${listSchemaId}`,
        GET_SERVICE_CUSTOMER_DETAILS_ACTION_BUTTONS: (
            productCloudMarketId: string,
            customerId: string,
            listSchemaId: number
        ) =>
            `${BaseUrl.baseUrl}/api/myServices/GetCustomerActionButtons/${productCloudMarketId}/${customerId}/${listSchemaId}`,
        GET_SERVICE_CUSTOMER_LIST_SCHEMA: (productCloudMarketId: string, listSchemaId: number) =>
            `${BaseUrl.baseUrl}/api/myServices/GetListSchema/${productCloudMarketId}/${listSchemaId}`,
        GET_SERVICE_CUSTOMER_SCHEMA: (productCloudMarketId: string) =>
            `${BaseUrl.baseUrl}/api/myServices/GetOrganisationSchemaForService/${productCloudMarketId}`,
        GET_SERVICE_CUSTOMERS: (serviceId: number) =>
            `${BaseUrl.baseUrl}/api/myServices/${serviceId}`,
        GET_SERVICE_SETTINGS: (productCloudMarketId: string) =>
            `${BaseUrl.baseUrl}/api/myServices/${productCloudMarketId}/settings`,
        GET_SERVICES: () => `${BaseUrl.baseUrl}/api/myServices`,
        GET_SERVICE_CUSTOMER_DETAIL_LIST: (
            productCloudMarketId: string,
            customerId: string,
            listSchemaId: number,
            includeAssignedLicences: boolean
        ) => {
            let result: string = `${BaseUrl.baseUrl}/api/myServices/GetList/${customerId}/${productCloudMarketId}/${listSchemaId}`;
            if (includeAssignedLicences) {
                result += '?includeAssignedLicences=true';
            }

            return result;
        },
    },
    NETWORK: {
        GET_NETWORK_STATUS: `${BaseUrl.baseUrl}/api/Network/GetNetworkStatus`,
        GET_NETWORK_STATUS_LINK: `${BaseUrl.baseUrl}/api/Network/GetNetworkStatusLink`,
        GET_STATUSES: `${BaseUrl.baseUrl}/api/Network/NetworkStatus`,
        GET_USER_SUBSCRIPTION_STATUS: `${BaseUrl.baseUrl}/api/Network/IsUserSubscribed`,
        SUBSCRIBE_USER: `${BaseUrl.baseUrl}/api/Network/SubscribeUser`,
    },
    RESET_PASSWORD: {
        RESET_URL: `${BaseUrl.authUrl}/api/resetpassword/`,
        RESET_ANOTHER_USER_URL: `${BaseUrl.authUrl}/api/resetpassword/another-user`,
        RESET_ANOTHER_USER_SSO_PASSWORD_URL: (userCloudMarketId: string) =>
            `${BaseUrl.baseUrlCloudMarket}${API_ENDPOINTS.API_MANAGEMENT.USER_V1}/api/users/reset-sso-password/${userCloudMarketId}`,
    },
    OFFICE365: {
        HAS_PARTNER_ID: `${BaseUrl.baseUrl}/api/Office365/HasPartnerId`,
        PARTNER_ID: `${BaseUrl.baseUrl}/api/Office365/partnerId`,
        SUBMIT_AGREEMENT: `${BaseUrl.baseUrl}/api/Office365/SubmitMicrosoftAgreement`,
        BUNDLED_PRODUCTS: `${BaseUrl.baseUrl}/api/Office365/bundled-products`,
        DELETE_OFFICE365_SERVICE: `${BaseUrl.baseUrl}/api/Office365/DeletedOffice365Service`,
        UPDATE_LICENCING_TYPE: `${BaseUrl.baseUrl}/api/Office365/UpdateLicensingType`,
        VALIDATE_MICROSOFT_ACCOUNT: `${BaseUrl.baseUrl}/api/Office365/ValidateMicrosoftAccount/`,
        GET_ORDER: (orderId: string) => `${BaseUrl.baseUrl}/api/Office365/GetOrder/${orderId}`,
        GET_SECURE_UPGRADES: (organisationId: string, productId?: number) =>
            `${BaseUrl.baseUrl}/api/office365/${organisationId}/secure-upgrades${
                productId ? `/${productId}` : ''
            }`,
        GET_MSFT_SERVICE_REFERENCES: (id: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${id}/MicrosoftServiceReferences`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string, officeGroupId: Office365ProductGroups) =>
            `${BaseUrl.baseUrl}/api/customer/${id}/GetOffice365Products/${officeGroupId}`,
        GET_USER_PRODUCT_INFO: (id: string, officeGroupId: Office365ProductGroups) =>
            `${BaseUrl.baseUrl}/api/user/${id}/GetOffice365Products/${officeGroupId}`,
        GET_MICROSOFT_AGREEMENT: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/GetMicrosoftAgreement`,
        GET_HAS_MICROSOFT_AGREEMENT: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/GetHasMicrosoftAgreement`,
        GET_MICROSOFT_AGREEMENT_SETUP: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/GetMicrosoftAgreementSetup`,
        GET_LATEST_MICROSOFT_AGREEMENT_URL: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/GetLatestMicrosoftAgreementUrl`,
        GET_ORGANISATION_LICENCING: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/GetOrganisationLicensing`,
        GET_ORGANISATION_LICENCE_TYPE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/GetOrganisationLicenseType`,
        GET_PRODUCTS: (organisationId: string, subId: string, type: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/Products/${subId}/${type}`,
        GET_MICROSOFT_ID: (customerId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${customerId}/GetMicrosoftId`,
        GET_ASSIGNED_USERS_OF_PRODUCT: (organisationId: string, productId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/AssignedUsersOfProduct/${productId}`,
        GET_PRODUCTS_FOR_USER: (organisationId: string, subId: string, userId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/Products/${subId}/${userId}`,
        ASSIGN_LICENCES: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/AssignLicenses/`,
        GET_ASSIGNED_LICENCES: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/AssignedLicenses/`,
        HAS_MULTI_CHANNEL_MCA: (primaryDomain: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${primaryDomain}/GetHasMultiChannelMCA`,
        UPGRADE_TO_SECURE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/UpgradeOffice365ToSecure/`,
        UPGRADE_SERVICE: (organisationId: string, subId: number, typeId: number) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/UpgradeOffice365Service/${subId}/${typeId}`,
        SAVE_SERVICE: (organisationId: string, subId: number, typeId: number) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/SaveOffice365Service/${subId}/${typeId}`,
        SAVE_SERVICE_FOR_USER: (
            organisationId: string,
            organisationUserId: number,
            subId: number,
            typeId: number
        ) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/users/${organisationUserId}/SaveOffice365Service/${subId}/${typeId}`,
        GET_UNASSIGNED_PRODUCTS: (organisationId: string, subId: number) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/UnassignedProducts/${subId}/`,
        GET_UNASSIGNED_PRODUCTS_FOR_PRODUCT: (
            organisationId: string,
            subId: number,
            office365ProductId: number
        ) =>
            `${BaseUrl.baseUrl}/api/Office365/${organisationId}/UnassignedProducts/${subId}/${office365ProductId}`,
        GET_SECURE_UPGRADE_AVAILABLE: (organisationId: string, productId?: number) =>
            `${BaseUrl.baseUrl}/api/office365/${organisationId}/secure-upgrade-available${
                productId ? `/${productId}` : ''
            }`,
    },
    ONE_TIME_PURCHASE: {
        CANCEL_PENDING_ORDER: `${BaseUrl.baseUrl}/api/one-time-purchase/CancelPendingOrder`,
        CANCEL_SKU: `${BaseUrl.baseUrl}/api/one-time-purchase/CancelSku`,
        GET_SKUS: `${BaseUrl.baseUrl}/api/one-time-purchase/Skus/`,
        GET_HAS_AZURE_ACCOUNT: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/one-time-purchase/GetHasAzureAccount/${organisationId}`,
        GET_PRODUCTS: (serviceId: number) =>
            `${BaseUrl.baseUrl}/api/one-time-purchase/${serviceId}/Products`,
        GET_PROPERTIES_FOR_PRODUCT: (serviceId: number, productId: number) =>
            `${BaseUrl.baseUrl}/api/one-time-purchase/${serviceId}/Products/${productId}`,
        MEETS_MINIMUM_REQUIREMENTS: (organisationId: string, serviceId: number) =>
            `${BaseUrl.baseUrl}/api/one-time-purchase/${organisationId}/${serviceId}/MeetsMinimumRequirements`,
        SUBMIT: (organisationId: string, serviceId: number) =>
            `${BaseUrl.baseUrl}/api/one-time-purchase/${organisationId}/${serviceId}/Submit`,
    },
    ORGANISATIONS: {
        GET_SERVICES: (organisationId: string, serviceId: number) =>
            `${BaseUrl.baseUrl}/api/organisations/${organisationId}/products/${serviceId}/services`,
        GET_SERVICE_BY_INSTANCE: (organisationId: string, serviceId: number, instanceId: string) =>
            `${
                BaseUrl.baseUrl
            }/api/organisations/${organisationId}/products/${serviceId}/services/${
                instanceId || ''
            }`,
        DELETE_SERVICE: (organisationId: string, serviceId: number) =>
            `${BaseUrl.baseUrl}/api/organisations/${organisationId}/products/${serviceId}`,
        DELETE_SERVICE_INSTANCE: (organisationId: string, serviceId: number, instanceId: string) =>
            `${BaseUrl.baseUrl}/api/organisations/${organisationId}/products/${serviceId}/services/${instanceId}`,
    },
    PRODUCT_BUNDLE: {
        GET_BUNDLES: `${BaseUrl.baseUrl}/api/product-bundle`,
        VIEW_STATUS: `${BaseUrl.baseUrl}/api/product-bundle/view-status`,
        UPDATE_VIEW_STATUS: `${BaseUrl.baseUrl}/api/product-bundle/update-view-status`,
    },
    PSA: {
        MONOLITH: new Map<PsaEndpoints, string>([
            [PsaEndpoints.cmCustomers, ''], // Not Available
            [PsaEndpoints.psaCustomers, `${BaseUrl.baseUrl}/api/integration/psaCustomers`],
            [PsaEndpoints.cmProducts, `${BaseUrl.baseUrl}/api/integration/products`],
            [PsaEndpoints.psaProducts, ''], // Not Available
            [PsaEndpoints.customerMappings, `${BaseUrl.baseUrl}/api/integration/customers`],
            [PsaEndpoints.serviceMappings, `${BaseUrl.baseUrl}/api/integration/psaProducts`],
            [
                PsaEndpoints.productMovementReport,
                `${BaseUrl.baseUrl}/api/integration/ProductMovementReportActions/{date}`,
            ],
            [
                PsaEndpoints.productMovementDateRange,
                `${BaseUrl.baseUrl}/api/integration/GetProductMovementDateRange`,
            ],
            [
                PsaEndpoints.reconciliationReport,
                `${BaseUrl.baseUrl}/api/integration/ReconciliationReportActions`,
            ],
            [
                PsaEndpoints.updateCustomerMappings,
                `${BaseUrl.baseUrl}/api/integration/updateCustomers`,
            ],
            [
                PsaEndpoints.updateProductMappings,
                `${BaseUrl.baseUrl}/api/integration/updateProducts`,
            ],
            [
                PsaEndpoints.updateProductMovementReport,
                `${BaseUrl.baseUrl}/api/integration/UpdateProductMovementReport`,
            ],
            [
                PsaEndpoints.updateReconciliationReport,
                `${BaseUrl.baseUrl}/api/integration/UpdateReconciliationReport`,
            ],
            [
                PsaEndpoints.availableIntegrations,
                `${BaseUrl.baseUrl}/api/integration/availableIntegrations`,
            ],
            [PsaEndpoints.getSettings, `${BaseUrl.baseUrl}/api/integration/settings`],
            [PsaEndpoints.setSettings, `${BaseUrl.baseUrl}/api/integration/settings`],
            [
                PsaEndpoints.productMovementAsCSV,
                `${BaseUrl.baseUrl}/api/integration/ProductMovementReport/Csv/{date}`,
            ],
            [
                PsaEndpoints.reconcoliationReportAsCSV,
                `${BaseUrl.baseUrl}/api/integration/ReconciliationReport/Csv`,
            ],
            [PsaEndpoints.overview, `${BaseUrl.baseUrl}/api/integration/psaOverview`],
            [PsaEndpoints.refreshAll, ''], // Not Available
            [PsaEndpoints.psaContracts, ''], // Not Available
            [PsaEndpoints.refreshPsaContracts, ''], // Not Available
            [
                PsaEndpoints.updateIgnoreSalesOrders,
                `${BaseUrl.baseUrl}/api/integration/ignoreSalesOrders`,
            ],
        ]) as ReadonlyMap<PsaEndpoints, string>,
        SERVICE: new Map<PsaEndpoints, string>([
            [PsaEndpoints.customerMappings, '/api/mapping/customers'],
            [PsaEndpoints.serviceMappings, '/api/mapping/services'],
            [PsaEndpoints.cmCustomers, '/api/cloud-market/customers'],
            [PsaEndpoints.psaCustomers, '/api/psa/customers'],
            [PsaEndpoints.cmProducts, '/api/cloud-market/services'],
            [PsaEndpoints.psaProducts, '/api/psa/services'],
            [PsaEndpoints.productMovementReport, '/api/product-movement-report/{date}'],
            [PsaEndpoints.productMovementDateRange, '/api/product-movement-report/date-range'],
            [PsaEndpoints.reconciliationReport, '/api/reconciliation-report'],
            [PsaEndpoints.updateCustomerMappings, '/api/mapping/customers'],
            [PsaEndpoints.updateProductMappings, '/api/mapping/services'],
            [PsaEndpoints.updateProductMovementReport, '/api/product-movement-report'],
            [PsaEndpoints.updateReconciliationReport, '/api/reconciliation-report'],
            [PsaEndpoints.availableIntegrations, '/api/integration'],
            [PsaEndpoints.getSettings, '/api/settings'],
            [PsaEndpoints.setSettings, '/api/settings'],
            [PsaEndpoints.productMovementAsCSV, '/api/product-movement-report/{date}/csv'],
            [PsaEndpoints.reconcoliationReportAsCSV, '/api/reconciliation-report/csv'],
            [PsaEndpoints.overview, '/api/integration/overview'],
            [PsaEndpoints.refreshAll, '/api/integration/refresh/all'],
            [PsaEndpoints.refreshCustomers, '/api/integration/refresh/customers'],
            [PsaEndpoints.refreshServices, '/api/integration/refresh/services'],
            [PsaEndpoints.refreshProductMovementReport, '/api/product-movement-report/refresh'],
            [PsaEndpoints.refreshReconciliationReport, '/api/reconciliation-report/refresh'],
            [PsaEndpoints.refreshAzureReport, '/api/azure-charges/refresh'],
            [PsaEndpoints.psaContracts, '/api/psa/contracts'],
            [PsaEndpoints.refreshPsaContracts, '/api/psa/contracts/refresh'],
            [PsaEndpoints.synchronizationHub, '/notifications/synchronization'],
            [PsaEndpoints.updateIgnoreSalesOrders, '/api/settings/ignore-sales-orders'],
        ]) as ReadonlyMap<PsaEndpoints, string>,
        AZURE_CHARGES: {
            BASE: '/api/azure-charges',
            DATE_RANGES: (numberOfMonthsBack: number) =>
                `${API_ENDPOINTS.PSA.AZURE_CHARGES.BASE}/date-range/${numberOfMonthsBack}`,
            GET_FOR_DATE: (date: string) => `${API_ENDPOINTS.PSA.AZURE_CHARGES.BASE}/${date}`,
            REFRESH_FOR_DATE: (date: string) =>
                `${API_ENDPOINTS.PSA.AZURE_CHARGES.BASE}/${date}/refresh`,
            REFRESH: () => `${API_ENDPOINTS.PSA.AZURE_CHARGES.BASE}/refresh`,
            UPDATE: () => API_ENDPOINTS.PSA.AZURE_CHARGES.BASE,
        },
    },
    RELATED_LINKS: {
        GET_RELATED_LINKS: (serviceId: string | number) =>
            `${BaseUrl.baseUrl}/api/relatedlinks/${serviceId}`,
    },
    SECURE_EMAIL_MESSAGING: {
        GET_PRICE_CIRIUS: `${BaseUrl.baseUrl}/api/SecureEmailMessaging/GetPriceCirius`,
        CREATE_CIRIUS_SERVICE: `${BaseUrl.baseUrl}/api/SecureEmailMessaging/CreateCiriusService`,
        DELETE_CUSTOMER_SERVICE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/${organisationId}`,
        DELETE_USER_SERVICE: (organisationId: string | number, userName: string) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/${organisationId}/${userName}/`,
        GET_USER_SERVICE_MODELS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/${organisationId}/GetUserServiceModels`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/${id}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (organisationId: string, userId: string | number) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/${organisationId}/${userId}/ProductsInfo/`,
        USE_DUMMY_USERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/usedummyusers/${organisationId}`,
        GET_USERS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/GetUsers/${organisationId}`,
        GET_EXISTING_USER_COUNT: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SecureEmailMessaging/GetExistingUserCount/${organisationId}`,
    },
    SKYKICK: {
        VALID_O365_CREDENTIALS: `${BaseUrl.baseUrl}/api/SkyKick/ValidO365Credentials`,
        DELETE_SERVICE: `${BaseUrl.baseUrl}/api/skykick/DeleteService`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) =>
            `${BaseUrl.baseUrl}/api/SkyKick/${id}/ProductInfo`,
        EXECUTE_SETUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SkyKick/ExecuteSetup/${organisationId}/`,
        INITIALIZE_SETUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SkyKick/InitialiseSetup/${organisationId}/`,
        SETUP_PROGRESS: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SkyKick/SetupProgress/${organisationId}`,
        GET_DISCOVERED_SERVICES: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/SkyKick/GetDiscoveredServices/${organisationId}`,
        GET_ADD_SERVICE_SETUP: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/skykick/${organisationId}/GetAddServiceSetup`,
        SETUP_COMPLETE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/skykick/SetupComplete/${organisationId}`,
    },
    SPAM_RELEASE: {
        GET_SPAM_RELEASE: (messageGuid: string, uId: string) =>
            `${BaseUrl.baseUrl}/api/spamrelease/${messageGuid}/${uId}`,
    },
    SUCCESS: {
        ADD_CREDIT: `${BaseUrl.baseUrl}/api/Success/AddCredit`,
        PAY_INVOICE: `${BaseUrl.baseUrl}/api/Success/PayInvoice`,
    },
    SUPPORT: {
        IS_OUT_OF_HOURS: `${BaseUrl.baseUrl}/api/support/IsOutOfHours`,
        SELECT_SUPPORT_ALL_RECORDS_BY_RESELLER: `${BaseUrl.baseUrl}/api/support/SelectSupportAllRecordsByReseller`,
        SELECT_SUPPORT_ACTIVE_RECORDS_BY_RESELLER: `${BaseUrl.baseUrl}/api/support/SelectSupportActiveRecordsByReseller`,
        UPDATE_SUPPORT_TICKET_BY_RESELLER: `${BaseUrl.baseUrl}/api/support/UpdateSupportTicketByReseller`,
        UPLOAD_FILES_SUPPORT_TICKET: `${BaseUrl.baseUrl}/api/support/UploadFilesSupportTicket`,
        GET_ORGS_SUPPORT: `${BaseUrl.baseUrl}/api/support/GetOrgsSupport`,
        GET_AD_USERS_SUPPORT: `${BaseUrl.baseUrl}/api/support/GetAdUsersSupport`,
        GET_PRODUCTS_SUPPORT: `${BaseUrl.baseUrl}/api/support/GetProductsSupport`,
        SUBMIT_TICKET: `${BaseUrl.baseUrl}/api/support/SubmitTicket`,
        GET_DEFAULT_OUT_OF_HOURS_DETAILS: `${BaseUrl.baseUrl}/api/support/GetDefaultOutOfHoursDetails`,
        SUBMIT_OUT_OF_HOURS_TICKET: `${BaseUrl.baseUrl}/api/support/SubmitOutOfHoursTicket`,
        GET_DETAIL_TICKET: (supportId: number | string) =>
            `${BaseUrl.baseUrl}/api/support/GetDetailTicket/${supportId}`,
        GET_DETAIL_HISTORY_SUPPORT_TICKET: (supportId: number | string) =>
            `${BaseUrl.baseUrl}/api/support/GetDetailHistorySupportTicket/${supportId}`,
        CLOSE_TICKET_BY_RESELLER: (supportId: number | string) =>
            `${BaseUrl.baseUrl}/api/support/CloseTicketByReseller/${supportId}`,
        GET_SUPPORT_ATTACHMENTS: (supportId: number | string) =>
            `${BaseUrl.baseUrl}/api/support/GetSupportAttachments/${supportId}`,
        DOWNLOAD_ATTACHMENT: (fileName: string) =>
            `${BaseUrl.baseUrl}/api/support/DownloadAttachment/${fileName}/`,
    },
    SYMANTEC_CLOUD: {
        ARTICLES: `${BaseUrl.baseUrl}/api/messagelabs/articles`,
        GET_ORDER: (organisationId: string, orderId: string) =>
            `${BaseUrl.baseUrl}/api/messagelabs/${organisationId}/orders/${orderId}`,
        SEARCH_ARTICLES: (terms: string) =>
            `${BaseUrl.baseUrl}/api/messagelabs/searchArticles/${encodeURIComponent(terms)}`,
    },
    USER: {
        GET_CURRENT_ID: `${BaseUrl.baseUrl}/api/user/currentId`,
        DOWNLOAD_TEMPLATE: `${BaseUrl.baseUrl}/api/user/DownloadTemplate`,
        SERVICE_BASE: '/api/users',
        CHECK_SWITCH_USER: '/checkswitchuser',
        CREATE_DEPRECATED: '/create',
        CREATE_ENGINEER_DEPRECATED: `${BaseUrl.baseUrl}/api/user/engineer/new`,
        CREATE_ENGINEER: `${BaseUrl.baseUrl}/api/user/engineer`,
        CUSTOMER: '/customer',
        GET_COMPANY_PROFILE: `${BaseUrl.baseUrl}/api/User/GetCompanyProfile/`,
        GET_MY_OUTLOOK: `${BaseUrl.baseUrl}/api/User/MyOutLook/`,
        ENGINEER_INITAL: `${BaseUrl.baseUrl}/api/user/engineer/inital`,
        GET_SUFFIX: `${BaseUrl.baseUrl}/api/user/suffix/get`,
        UPDATE_SUFFIX: `${BaseUrl.baseUrl}/api/user/suffix/update`,
        CUSTOMER_USER_INITAL: `${BaseUrl.baseUrl}/api/user/customerUser/inital`,
        LOGIN_USER: `${BaseUrl.baseUrl}/api/user/loginuser`,
        GET_ADVANCED_ADMINISTRATORS: `${BaseUrl.baseUrl}/api/user/GetAdvancedAdministrators`,
        MY_PROFILE: `${BaseUrl.baseUrl}/api/user/MyProfile`,
        CHECKPERMISSIONIDFORENGINEER: '/CheckPermissionIdForEngineer',
        UPDATE_USER_PROFILE: `${BaseUrl.baseUrl}/api/user/UpdateProfile`,
        CUSTOMER_ADMIN_NEW: `${BaseUrl.baseUrl}/api/user/customer/new`,
        VALIDATE_USER_PASSWORD: '/validate-user-password',
        GENERATE_PASSWORD: '/api/PasswordSecurity/password',
        VALIDATION_RULES: '/api/passwordsecurity/validationrules',
        GET_OWN_PRODUCTS: `${BaseUrl.baseUrl}/api/user/GetProducts`,
        RESET_OWN_PRODUCT_PASSWORD: `${BaseUrl.baseUrl}/api/user/ResetPassword`,
        DELETE_USER: (userId: string | number) => `${BaseUrl.baseUrl}/api/user/${userId}`,
        IMPORT: (organisationId: string) => `${BaseUrl.baseUrl}/api/user/import/${organisationId}`,
        GET_CUSTOMER_USER_COUNT: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/user/CountAllUsers/${organisationId}`,
        DELETE_OFFICE365_SERVICE: (office365ModelId: number) =>
            `${BaseUrl.baseUrl}/api/user/deleteOffice365Service/${office365ModelId}`,
        GET_PAGED_CUSTOMER_USERS: (organisationId: string) => `/customer/paged/${organisationId}`,
        GET_SUGGESTIONS: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/User/GetSuggestions/${organisationId}`,
        GET_USER_PROFILE: (userId: number) =>
            `${BaseUrl.baseUrl}/api/user/GetUserProfile/${userId}`,
        GET_BUNDLE_PRODUCTS: (userId: string) =>
            `${BaseUrl.baseUrl}/api/user/GetBundleProducts/${userId}`,
        GET_GROUP_SERVICES: (userId: string) =>
            `${BaseUrl.baseUrl}/api/user/${userId}/GetGroupServices`,
        GET_OFFICE365_PRODUCTS: (userId: string, officeGroupId: number) =>
            `${BaseUrl.baseUrl}/api/user/${userId}/GetOffice365Products/${officeGroupId}`,
        MIGRATION_ALLOW: (userId: string) => `${BaseUrl.baseUrl}/api/user/${userId}/AllowMigration`,
        MIGRATION_PENDING: (userId: string) =>
            `${BaseUrl.baseUrl}/api/user/${userId}/PendingMigration`,
        GET_DETAILS_ENDUSER_OR_ENGINEER: (userId: string) =>
            `${BaseUrl.baseUrl}/api/user/GetDetailInfoEndUserOrEngineer/${userId}/`,
        GET_DETAIL_INFO_OF_ENGINEER_BY_CLOUDMARKET_ID: (cloudMarketId: string) =>
            `${BaseUrl.baseUrl}/api/user/GetDetailInfoOfEngineer/${cloudMarketId}/`,
        UPDATE_ENGINEER_USER_PROFILE: (id: string) =>
            `${BaseUrl.baseUrl}/api/user/engineer/${id}/UpdateProfile`,
        DELETE_ENGINEER: (engineerId: string | number) =>
            `${BaseUrl.baseUrl}/api/user/engineer/${engineerId}/delete`,
        UPDATE_END_USER_PROFILE: (email: string) =>
            `${BaseUrl.baseUrl}/api/user/enduser/${email}/updateprofile`,
        CUSTOMER_ADMIN_REVERT: (engineerId: string | number) =>
            `${BaseUrl.baseUrl}/api/user/customerAdmin/${engineerId}/revert`,
        UPDATE_PASSWORD: (userType: string, userName: string) =>
            `/api/users/${userType}/${userName}/update-password`,
        CHANGE_PASSWORD: (userType: string, userName: string) =>
            `/api/users/${userType}/${userName}/change-password`,
        RESET_PASSWORD: (userType: string, userName: string) =>
            `/api/users/${userType}/${userName}/reset-password`,
        RESET_USER_PRODUCT_PASSWORD: (userId: number) =>
            `${BaseUrl.baseUrl}/api/user/${userId}/ResetPassword`,
        GET_USER_PRODUCTS: (userId: number) => `${BaseUrl.baseUrl}/api/user/${userId}/GetProducts`,
        GET_ALL_USER_PRODUCTS: (userId: number) =>
            `${BaseUrl.baseUrl}/api/user/${userId}/GetServices`,
        LOGIN_FLOW: (userName: string) => `/api/sso/${userName}/login-flow`,
        GET_USER: (organisationId: string, userId: number | string) =>
            `${BaseUrl.baseUrl}/api/user/${organisationId}/${userId}`,
        GET_AVAILABLE_SERVICES: (organisationId: string, userId: number | string) =>
            `${BaseUrl.baseUrl}/api/user/${organisationId}/${userId}/SelectList`,
        GET_ALLOW_MIGRATION: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/user/${organisationId}/AllowMigration`,
        GET_PENDING_MIGRATION: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/user/${organisationId}/PendingMigration`,
        GET_LOGGED_IN_USER_DATA: `${BaseUrl.baseUrl}/api/user/logged-in-user`,
    },
    AZURE_MANAGEMENT: {
        GET_RESELLER_BUDGET: `${BaseUrl.baseUrl}/api/AzureManagement/ResellerBudget`,
        GET_RESELLER_MARGIN: (primaryAccountId: string) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/reseller/${primaryAccountId}/margin`,
        PUT_RESELLER_MARGIN: (primaryAccountId: string) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/reseller/${primaryAccountId}/margin`,
        GET_ORGANISATION_MARGIN: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/organisation/${organisationId}/margin`,
        PUT_ORGANISATION_MARGIN: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/organisation/${organisationId}/margin`,
        GET_ADMIN_LOGIN: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetAdminLogin/${organisationId}`,
        UPDATE_ADMIN_LOGIN: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/UpdateAdminLogin/${organisationId}`,
        GET_SUMMARY_DETAILS_BILL: (organisationId: string, year: number, month: number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetSummaryDetailsBill/${organisationId}/${year}/${month}`,
        GET_RESOURCE_LIST: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetAzureResourceList/${organisationId}`,
        AZURE_ORG_SUSPEND: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/AzureOrgSuspend/${organisationId}`,
        AZURE_ORG_ENABLE: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/AzureOrgEnable/${organisationId}`,
        CHECK_STATUS_AZURE_ORG: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/CheckStatusAzureOrg/${organisationId}`,
        GET_AZURE_BILLING_LIST: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetAzureBillingList/${organisationId}`,
        GET_AZURE_ESTIMATED_BILLING_LIST: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetAzureEstimatedBillingList/${organisationId}`,
        EXPORT_AZURE_BILLING: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/ExportAzureBilling/${organisationId}`,
        SET_SUBSCRIPTION_CHART_DROPDOWN: (organisationId: string | number) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/SetSubscriptionChartDropDown/${organisationId}`,
        SET_RESOURCE_CHART_DROPDOWN: (
            organisationId: string | number,
            searchSubscriptionValue: string
        ) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/SetResourceChartDropDown/${organisationId}/${searchSubscriptionValue}`,
        GET_TOTAL_SPEND_JSON_DATA: (
            organisationId: string | number,
            searchSubscriptionValue: string,
            searchDateValue: string
        ) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetTotalSpendJsonData/${organisationId}/${searchSubscriptionValue}/${searchDateValue}`,
        GET_SPEND_BY_GROUP_CHART_DATA: (
            organisationId: string | number,
            searchSubscriptionValue: string,
            dateValue: string
        ) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetSpendByGroupChartData/${organisationId}/${searchSubscriptionValue}/${dateValue}`,
        GET_SPEND_BY_RESOURCE_CHART_DATA: (
            orgId: string | number,
            subscriptionValue: string,
            groupValue: string,
            dateValue: string
        ) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetSpendByResourceChartData/${orgId}/${subscriptionValue}/${groupValue}/${dateValue}`,
        GET_SPEND_BY_METER_CHART_DATA: (
            organisationId: string | number,
            subscriptionValue: string,
            dateValue: string
        ) =>
            `${BaseUrl.baseUrl}/api/AzureManagement/GetSpendByMeterChartData/${organisationId}/${subscriptionValue}/${dateValue}`,
    },
    REPORTING: {
        V1_BASE: '/reporting/v1',
        BILLING_INVOICES: '/billing/invoices',
        LEGACY_INVOICES: `${BaseUrl.baseUrl}/api/billing/invoices`,
        BILLING_HISTORY: '/billing/history',
        BILLING_AZURE_RESERVED_INSTANCES_PRICE_LIST: `${BaseUrl.baseUrl}/api/billing/GetPrices/azurericsv`,
        BILLING_CREDIT_MEMOS: '/billing/creditmemos',
        LATEST_INVOICE_SUMMARY: '/billing/invoices/latest/summary',
        BILLING_INVOICES_EXPORT: (invoiceId: string | number) =>
            `/billing/invoices/export/${invoiceId}`,
        BILLING_INVOICES_EXPORT_V2: (invoiceId: string | number) =>
            `/billing/invoices/exportv2/${invoiceId}`,
        BILLING_INVOICES_BY_ID: (
            invoiceId: string,
            externalInvoiceId: string = '',
            isFilterByCustomer: string = 'false'
        ) =>
            `/billing/invoices/${invoiceId}?IsFilterByCustomer=${isFilterByCustomer}&externalInvoiceId=${externalInvoiceId}`,
        LEGACY_INVOICE_DETAILS: (invoiceId: string, isFilteredByCustomer: string) =>
            `${BaseUrl.baseUrl}/api/billing/invoiceDetails?id=${invoiceId}&IsFilterByCustomer=${isFilteredByCustomer}`,
        BILLING_INVOICES_BY_ID_DOWNLOAD: (invoiceId: string) =>
            `/billing/invoices/${invoiceId}/download`,
        LEGACY_CUSTOMER_INVOICE_DETAILS: (customerId: string, isFilterByCustomer: boolean) =>
            `${BaseUrl.baseUrl}/api/billing/${customerId}/billingInvoice?IsFilterByCustomer=${isFilterByCustomer}`,
        CUSTOMER_INVOICE_DETAILS: (customerId: string, isFilterByCustomer: boolean) =>
            `/billing/invoices/latest/${customerId}?IsFilterByCustomer=${isFilterByCustomer}`,
        BILLING_PRODUCTMOVEMENT_DOWNLOAD: (invoiceDate: string) =>
            `/billing/productmovementreport/${invoiceDate}/download`,
        BILLING_CREDIT_MEMO: (creditMemoReferenceId: string) =>
            `/billing/creditmemos/${creditMemoReferenceId}`,
        BILLING_CREDIT_MEMO_BY_ID_DOWNLOAD: (creditMemoReferenceId: string) =>
            `/billing/creditmemos/${creditMemoReferenceId}/download`,
        BILLING_PREBILL_DOWNLOAD: (invoiceId: string, invoiceDate: string) =>
            `/billing/invoices/${invoiceId}/pre-bill?invoiceDate=${invoiceDate}`,
        BILLING_POSTBILL_DOWNLOAD: (invoiceId: string, invoiceDate: string) =>
            `/billing/invoices/${invoiceId}/post-bill?invoiceDate=${invoiceDate}`,
        LATEST_INVOICE: () => '/billing/invoices/latest',
        LEGACY_LATEST_INVOICE: () => `${BaseUrl.baseUrl}/api/dashboard/getLatestInvoice`,
    },
    USER_PERMISSION: {
        ROLES: `${BaseUrl.authUrl}/api/userpermissions/roles`,
        PREDEFINEDTYPES: (userCloudMarketId: string) =>
            `${BaseUrl.authUrl}/api/userpermissions/user/${userCloudMarketId}/predefinedTypes`,
        AVAILABLEROLES: (userCloudMarketId: string) =>
            `${BaseUrl.authUrl}/api/userpermissions/user/${userCloudMarketId}/availableRoles`,
        USERROLES: (userCloudMarketId: string) =>
            `${BaseUrl.authUrl}/api/userpermissions/user/${userCloudMarketId}/roles`,
    },
    PERMISSION: {
        ADD_AVAILABLE_CUSTOMER: `${BaseUrl.baseUrl}/api/permissions/add-available-customer`,
        GET_PERMISSIONS: `${BaseUrl.baseUrl}/api/permissions`,
        UPDATE_PERMISSIONS: (userCloudMarketId: string) =>
            `${BaseUrl.baseUrl}/api/permissions/${userCloudMarketId}`,
    },
    PRODUCT: {
        PRODUCTS_SUMMARY: `${BaseUrl.baseUrl}/api/products-summary`,
        CURRENT_USER_RESTRICTED_PRODUCTS: `${BaseUrl.baseUrl}/api/user-restricted-products`,
        RESTRICTED_PRODUCTS: (userCloudMarketId: string) =>
            `${BaseUrl.baseUrl}/api/user-restricted-products/${userCloudMarketId}`,
    },
    BILLING_SERVICE: {
        V1_BASE: '/billing/v1',
        DIRECT_DEBIT_CANCEL: '/api/DirectDebit/Cancel',
        DIRECT_DEBIT_STATUS: '/api/DirectDebit/Status',
        AUTH_HOSTED_PAGE: {
            [PaymentType.directDebit]: '/api/DirectDebit/AuthorizeHostedPage',
            [PaymentType.card]: '/api/Card/AuthorizeHostedPage',
        },
        CARD_LIST_CARDS: '/billing/v1/api/card/ListCards',
        CARD_CREATE_PAYMENT: '/billing/v1/api/card/CreatePayment',
        CARD_CANCEL_PAYMENT_METHODS: '/billing/v1/api/card/CancelPaymentMethods',
        PAYMENT_UPDATE_PAYMENT_METHODS: '/billing/v1/api/card/AccountPaymentMethod',
    },
    ROLEBASEDACCESS: {
        MFA: `${BaseUrl.baseUrl}/api/rolebasedaccess/mfa`,
        SETTINGS: `${BaseUrl.baseUrl}/api/rolebasedaccess/settings`,
        SET_SETTINGS_MFA: `${BaseUrl.baseUrl}/api/rolebasedaccess/settings/mfa`,
    },
    SETTINGS_API: {
        INITIALISE: `${BaseUrl.baseUrl}/api/manage-api/initialise`,
        REGISTER: `${BaseUrl.baseUrl}/api/manage-api/register`,
        REGENERATE: `${BaseUrl.baseUrl}/api/manage-api/regenerate`,
    },
    SETTINGS_WHITELABELING: {
        GET_THEMES: `${BaseUrl.baseUrl}/api/whiteLabel/GetWhiteLabelThemes`,
        GET_WHITE_LABEL: `${BaseUrl.baseUrl}/api/whiteLabel/GetWhiteLabelForSetup`,
        GET_LOGIN_USER_WHITE_LABEL: `${BaseUrl.baseUrl}/api/whiteLabel/getLoginUserWhiteLabel`,
        GET_PRIVACY_POLICY: `${BaseUrl.baseUrl}/api/whiteLabel/hasCustomPrivacyPolicy`,
        SAVE_THEME: `${BaseUrl.baseUrl}/api/whiteLabel/SaveTheme`,
        PREVIEW: `${BaseUrl.baseUrl}/api/whiteLabel/getpreviewtheme`,
        GET_LOGO_LINK: (whiteLabelId: number) =>
            `${BaseUrl.baseUrl}/api/whiteLabel/getLoginUserLogo/${whiteLabelId}`,
        GET_USER_LOGO: (whiteLabelId: string | number) =>
            `${BaseUrl.baseUrl}/api/whiteLabel/getUserLogo/${whiteLabelId}`,
    },
    ACCOUNT_SETTINGS: {
        DISPLAY: '/api/accountSettings/displaySettings',
        GET_DISPLAY_SETTINGS: `${BaseUrl.baseUrl}/api/accountSettings/displaySettings`,
        UPDATE_DISPLAY_SETTINGS: `${BaseUrl.baseUrl}/api/accountSettings/displaySettings`,
    },
    EXCLAIMER: {
        TIERS: `${BaseUrl.baseUrl}/api/exclaimer/tiers`,
        RESSELER_STATUS: `${BaseUrl.baseUrl}/api/exclaimer/reseller-status`,
        SUBMIT: `${BaseUrl.baseUrl}/api/exclaimer/submit`,
        DELETE_SERVICE: `${BaseUrl.baseUrl}/api/exclaimer/DeleteService`,
        CURRENT_TIER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/exclaimer/${organisationId}/current-tier`,
        GET_CUSTOMER_PRODUCT_INFO: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/exclaimer/${organisationId}/ProductsInfo`,
        GET_OFFICE_SETUP: (customerId: string) =>
            `${BaseUrl.baseUrl}/api/Exclaimer/${customerId}/GetOfficeSetup`,
    },
    AGREEMENT: {
        CAN_AGREE: `${BaseUrl.baseUrl}/api/agreement/canagree`,
        LIST_AGREEMENTS: `${BaseUrl.baseUrl}/api/agreement`,
        RESELLER_AGREEMENT_VALIDITY: `${BaseUrl.baseUrl}/api/agreement/ResellerAgreement/Validity`,
        GET_SIGNATURE_IMAGE_STRING: `${BaseUrl.baseUrl}/api/Agreement/GetSignatureImageString`,
        SIGN: `${BaseUrl.baseUrl}/api/Agreement/Sign`,
        GET_AGREEMENTS: (onlyUnsigned: boolean) =>
            `${BaseUrl.baseUrl}/api/agreement/GetAgreements/${onlyUnsigned}`,
        GET_AGREEMENTS_INFO: (agreementId: number) =>
            `${BaseUrl.baseUrl}/api/agreement/GetAgreementInfo/${agreementId}`,
        GET_LINKED_AGREEMENTS_NON_OFFICE: (serviceId: number) =>
            `${BaseUrl.baseUrl}/api/agreement/GetLinkedAgreementsNonOffice/${serviceId}`,
        GET_LINKED_AGREEMENTS: (serviceId: number, groupId: number) =>
            `${BaseUrl.baseUrl}/api/agreement/GetLinkedAgreements/${serviceId}/${groupId}`,
        DOWNLOAD: (id: number) => `${BaseUrl.baseUrl}/api/agreement/download/${id}`,
        CHECK_SIGNED_SERVICE_ID: (serviceId: string | number) =>
            `${BaseUrl.baseUrl}/api/agreement/CheckSignedServiceId/${serviceId}`,
        VIEW_AGREEMENT: (agreeId: number, isResellerAgreement: string) =>
            `${BaseUrl.baseUrl}/api/Agreement/ViewAgreement/${agreeId}/${isResellerAgreement}`,
        BEGIN_SIGN: (tncId: string) => `${BaseUrl.baseUrl}/api/Agreement/BeginSign/${tncId}`,
        AGREE: (tncId: string) => `${BaseUrl.baseUrl}/api/Agreement/Agree/${tncId}`,
        GET_AGREEMENT: (agreementId: string) => `${BaseUrl.baseUrl}/api/agreement/${agreementId}`,
        GET_AGREEMENT_FOR_SERVICE: (serviceId: Service) =>
            `${BaseUrl.baseUrl}/api/Agreement/GetAgreement/${serviceId}`,
    },
    PAYMENT: {
        ADD_CREDIT: `${BaseUrl.baseUrl}/api/payment/addCredit`,
        GET_CREDITS: `${BaseUrl.baseUrl}/api/payment/getCredits`,
        GET_PAY_INVOICE_INFO: (invoiceNumber: number) =>
            `${BaseUrl.baseUrl}/api/payment/GetPayInvoiceInfo/${invoiceNumber}`,
        GET_PAYMENT_FROM_UPG: (
            storeAuthAction: StoredAuthorisationAction,
            creditValue: number,
            cardPaymentType: CardPaymentType
        ) =>
            `${BaseUrl.baseUrl}/api/payment/credit/UPGPayment/${storeAuthAction}/${creditValue}/${cardPaymentType}`,
        GET_UPG_PAYMENT: (
            storeAuthAction: StoredAuthorisationAction,
            netAmount: number,
            invoiceNumber: number
        ) =>
            `${BaseUrl.baseUrl}/api/payment/UPGPayment/${storeAuthAction}/${netAmount}/${invoiceNumber}`,
    },
    PROXY: {
        SYSTEMS: `${BaseUrl.baseUrl}/api/proxy/ams/systems`,
    },
    DOWNLOAD: {
        GENERATE_GUID: `${BaseUrl.baseUrl}/api/download/GenerateGuid`,
        DOWNLOAD: `${BaseUrl.baseUrl}/api/Download`,
        FROM_GUID: (guid: string, userName: string) =>
            `${
                BaseUrl.baseUrl
            }/api/download/DownloadFromGuid/${guid}/?userName=${encodeURIComponent(userName)}`,
    },
    VADE: {
        DELETE_CUSTOMER: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/vade/${organisationId}/DeleteCustomer`,
        GET_CUSTOMER_PRODUCT_INFO: (id: string) => `${BaseUrl.baseUrl}/api/vade/${id}/ProductsInfo`,
        GET_USER_PRODUCT_INFO: (organisationId: string, userId: number) =>
            `${BaseUrl.baseUrl}/api/vade/${organisationId}/${userId}/ProductsInfo`,
        HAS_SERVICE: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/vade/${organisationId}/HasService`,
        SUBMIT: (organisationId: string) => `${BaseUrl.baseUrl}/api/vade/${organisationId}/Submit`,
        GET_ADD_SERVICE_SETUP: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/vade/${organisationId}/GetAddServiceSetup`,
    },
    WEBROOT: {
        DELETE_INSTANCE: `${BaseUrl.baseUrl}/api/webroot/delete-instance`,
        REQUEST_ACCESS: (primaryAccountId: string) =>
            `${BaseUrl.baseUrl}/api/webroot/${primaryAccountId}/request-access`,
        GET_CUSTOMER_PRODUCT_INFO: (organisationId: string) =>
            `${BaseUrl.baseUrl}/api/webroot/${organisationId}/ProductsInfo`,
        GET_INSTANCE: (organisationId: string, instanceId: string) =>
            `${BaseUrl.baseUrl}/api/webroot/${organisationId}/get-instance/${instanceId}`,
    },
    RESELLER_SETTINGS: {
        ACRONIS_RESELLER_SETTINGS: `${BaseUrl.baseUrl}/api/ResellerSettings/AcronisResellerSettings`,
        ACRONIS_WHITELABEL_SCHEMES: `${BaseUrl.baseUrl}/api/ResellerSettings/AcronisWhiteLabelSchemes/`,
        ACRONIS_REPORTING_LEVELS: `${BaseUrl.baseUrl}/api/ResellerSettings/AcronisReportingLevels/`,
        BITDEFENDER_RESELLER_ADMIN: `${BaseUrl.baseUrl}/api/ResellerSettings/BitdefenderResellerAdmin/`,
        SET_BITDEFENDER_RESELLER_ADMIN: `${BaseUrl.baseUrl}/api/ResellerSettings/BitdefenderResellerAdmin`,
        BLOCKED_EMAIL_NOTIFICATIONS: `${BaseUrl.baseUrl}/api/ResellerSettings/BlockedEmailNotifications/`,
        ACRONIS_WHITELABEL_SCHEME: (schemeId: number) =>
            `${BaseUrl.baseUrl}/api/ResellerSettings/AcronisWhiteLabelScheme/${schemeId}`,
    },
    LOGIN: {
        OAUTH2_TOKEN: `${BaseUrl.authUrl}/oauth2/token`,
        LOGIN_USER: `${BaseUrl.baseUrl}/api/login/user`,
    },
    LOGOUT: {
        COMMS_LOGOUT: `${BaseUrl.baseUrl}/api/session/logout-comms`,
    },
    COMPANY_INFO: {
        MY_BUSINESS_DATA: `${BaseUrl.baseUrl}/api/companyinfo`,
        MY_NEW_BUSINESS_DATA: `${BaseUrl.baseUrl}/api/companyinfo/information`,
        PROGRESS: `${BaseUrl.baseUrl}/api/companyinfo/progress`,
        DEFINED_PRODUCTS: `${BaseUrl.baseUrl}/api/companyinfo/definedProducts`,
        AREAS_TYPE: `${BaseUrl.baseUrl}/api/companyinfo/areasType`,
    },
    IP_LOCKDOWN: {
        SEARCH_COUNTRY: `${BaseUrl.baseUrl}/api/IpLockdown/SearchCountry`,
        SEARCH_CITY: `${BaseUrl.baseUrl}/api/IpLockdown/SearchCity`,
        SEARCH_MOBILE_NETWORK: `${BaseUrl.baseUrl}/api/IpLockdown/SearchMobileNetwork`,
        VALIDATE_TEMP_DISABLE: `${BaseUrl.baseUrl}/api/IpLockdown/validateTempDisable/`,
        WHO_IS: (userRef: string, ruleType: number) =>
            `${BaseUrl.baseUrl}/api/IpLockdown/who/${userRef}/${ruleType}`,
        VALIDATE_INPUT_RULE: (userRef: string, ruleType: number) =>
            `${BaseUrl.baseUrl}/api/IpLockdown/validateInputRule/${userRef}/${ruleType}`,
        GET_IP_ACCESS_SETTINGS: (userRef: string) =>
            `${BaseUrl.baseUrl}/api/IpLockdown/GetIpAccessSettings/${userRef}/`,
        SAVE_IP_ACCESS_SETTINGS: (userRef: string) =>
            `${BaseUrl.baseUrl}/api/IpLockdown/SaveIpLockdownSetting/${userRef}/`,
    },
    VERIFY_EMAIL: {
        EMAIL_ARCHIVE_ACCESS: (accessType: string, token: string) =>
            `${BaseUrl.baseUrl}/api/verifyemailaccess/${accessType}/${token}`,
    },
    WIZARD: {
        GET_WIZARD: (wizardId: number) =>
            `${BaseUrl.baseUrl}/api/wizard/GetWizardOptions/${wizardId}`,
        GET_WIZARD_OPTIONS: (wizardId: number, filterId: number) =>
            `${BaseUrl.baseUrl}/api/wizard/GetWizardFilterOptions/${wizardId}/${filterId}`,
        GET_SETTINGS_TO_EDIT: (wizardId: number, modeId: number) =>
            `${BaseUrl.baseUrl}/api/wizard/GetSettingsToEdit/${wizardId}/${modeId}`,
        GET_SPAM_FILTER_LEVEL: (wizardId: number, modeId: number) =>
            `${BaseUrl.baseUrl}/api/wizard/GetSpamFilterLevel/${wizardId}/${modeId}`,
        GET_ARCHIVE_LEVEL: (wizardId: number, modeId: number) =>
            `${BaseUrl.baseUrl}/api/wizard/GetArchiveLevel/${wizardId}/${modeId}`,
        GET_SELECTABLE_ITEMS: (wizardId: number, filterId: number, modeId) =>
            `${BaseUrl.baseUrl}/api/wizard/GetSelectableItems/${wizardId}/${filterId}/${modeId}`,
        GET_SELECTABLE_ITEMS_BY_FILTER_VALUE: (
            wizardId: number,
            filterId: number,
            modeId,
            filterValue: string
        ) =>
            `${BaseUrl.baseUrl}/api/wizard/GetSelectableItems/${wizardId}/${filterId}/${modeId}/${filterValue}`,
        SUBMIT_MANAGEMENT_MODEL: (wizardId: number) =>
            `${BaseUrl.baseUrl}/api/wizard/SubmitManagementModel/${wizardId}`,
    },
};
