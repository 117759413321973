import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { WINDOW } from '../../../core/injection-tokens/window.injection-token';
import { IWindow } from '../../../core/interfaces/window.interface';

import { GADataMethods } from './ga-data-methods';
import { GAPageLoad } from './ga-page-load';
import { GAVisitorInteraction, InteractionType } from './ga-visitor-interaction';
import { AbstractPageLoadBase, ResellerData } from './shared-classes';

@Injectable({
    providedIn: 'root',
})
export class GAService {
    private readonly _resellerData: ResellerData;
    private readonly _rootPageView: string = '/ga-virtual';

    constructor(@Inject(WINDOW) private _window: IWindow, private _router: Router) {
        this._resellerData = new ResellerData();
    }

    pageLoad(data: AbstractPageLoadBase, additionalData?: any): void {
        if (additionalData) {
            GADataMethods.processData(additionalData);
        }
        const pageLoadObject: GAPageLoad = new GAPageLoad(
            data,
            this._resellerData,
            this._rootPageView,
            additionalData
        );
        const objToSend: GAPageLoad = JSON.parse(JSON.stringify(pageLoadObject));

        this._window.dataLayer.push({
            ...objToSend,
            url: this._router.url,
        });
    }

    visitorInteraction(interactionType: InteractionType, data: any): void {
        const gaVisitorInteraction: GAVisitorInteraction = new GAVisitorInteraction(
            interactionType,
            data
        );
        const objToSend: GAVisitorInteraction = JSON.parse(JSON.stringify(gaVisitorInteraction));

        this._window.dataLayer.push(objToSend);
    }

    setResellerData(mex: string, userReference: string): void {
        this._resellerData.visitorId = mex;
        this._resellerData.userReference = userReference;
    }
}
